//角2、圆角
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
//输入数字返回区域字母
function changeArea(num){
  var areaPoint;
  switch(num){
 case "1": areaPoint = "A";break;
 case "2": areaPoint = "B";break;
 case "3": areaPoint = "C";break;
 case "4": areaPoint = "D";break;
 case "5": areaPoint = "E";break;
 case "6": areaPoint = "F";break;
 case "7": areaPoint = "G";break;
 case "8": areaPoint = "H";break;
 case "9": areaPoint = "I";break;
}
return areaPoint;
};
function showArea(data,outParam){ 
  console.log("data|||ObliqueAngle$$$$$$$$$$||||showArea====",data);
  var x=outParam.glassW*1,y=outParam.glassH*1;
  console.log("data==data.area===",data.area);
  console.log("data==typeof(data)===",typeof(data));
  console.log("data==data.length===",data.length);
  console.log("data[0]==data.length===",data[0]);
  console.log("data[1]==data.length===",data[1]);
//console.log("myObj=showArea=",obj);  
    var properNum=objHasOwnProperty(data);
    console.log("properNum===",properNum);
    var arrData=[];
    var arrArea=[];
    var arrAreaNum=[];
    for (let key in data) {
      console.log("测试key0=", key);
            if (data.hasOwnProperty(key)&& key!=="0") {              
              console.log("测试key0=", key);
              if (key !== "Rectangle") {
                console.log("myData=", data);
              console.log("测试key2=", key);
              console.log("data[key].shape=", data[key].shape);
              console.log("data[key].area=", data[key].area);            
              arrData.push(data[key]);
              arrArea.push(changeArea(data[key].area));
              arrAreaNum.push(data[key].area);
              }         
            }
          }    
          console.log("arrData=",arrData);     
         var xa = 0, ya = y,xc = x , yc = y ,xg = 0, yg = 0,xi = x , yi = 0,Ra=0,Rc=0,Rg=0,Ri=0,
         xawx=0,xawy=0,xahx=0,xahy=0,
         xcwx=0,xcwy=0,xchx=0,xchy=0,
         xgwx=0,xgwy=0,xghx=0,xghy=0,
         xiwx=0,xiwy=0,xihx=0,xihy=0;
         console.log("xawx",xawx);        
    for(var i = 0; i < arrAreaNum.length; i++){
      var areaNums=arrAreaNum[i];
      var R=arrData[i].R*1;
      switch(areaNums){
         case "1":   
         console.log("switch objA.xawx",xawx);         
         Ra=R,
         xawx = xa+R, xawy = ya,//A位置角W坐标
         xahx = xa, xahy = ya - R;//A位置角H坐标
          break;
         case "3":        
         Rc=R,
         xcwx = xc - R, xcwy = yc,//C位置角W坐标
         xchx = xc, xchy = yc - R;//C位置角H坐标
         break;
         case "7":                  
         Rg=R,
         xgwx = xg + R, xgwy = yg,//G位置角W坐标
         xghx = xg, xghy = yg + R;//G位置角H坐标
         break;
         case "9":                 
         Ri=R, 
         xiwx = xi - R, xiwy = yi,//I位置角W坐标
         xihx = xi, xihy = yi + R;//I位置角H坐标
         break;
       }
    }  
          arrArea=arrArea.sort();
          arrArea=arrArea.join("");
        var whMark={Ra,Rc,Rg,Ri};
         var paramCoordinate = { xa, ya, xawx, xawy, xahx, xahy, xc, yc, xcwx, xcwy, xchx, xchy, xg, yg, xgwx, xgwy, xghx, xghy, xi, yi, xiwx, xiwy, xihx, xihy,Ra,Rc,Rg,Ri }
          console.log("showArea===arrData=",arrData);
          console.log("showArea===arrArea=",arrArea);
          console.log("paramCoordinate===arrArea=",paramCoordinate);
          var objData=[arrArea,paramCoordinate,whMark]; 
 
  
  return objData;
      };
function objHasOwnProperty(obj) {
        var count = 0;
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            count++;
          }
        }
        return count;
      };
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
    console.log("是否调用此函数getStrs（）")
    var arrA = [];
    var TA = strs[0].slice(8);
    TA=TA+area;
  arrA=Array.from(TA);
    console.log("是否调用此函数getStrs()arrA==",arrA)
     arrA = arrA.sort();
     arrA=arrA.join("");
    console.log("是否调用此函数getStrs()areaPoint arrA==",arrA)
    return arrA;
  };
  // 矩形等去角 连线函数
  function creatFillet(area, param) {
    console.log("是否调用此函数creatOneCorner（）");
    console.log("是否调用此函数creatOneCorner（）param=",param);
    var xa = param.xa, ya = param.ya, xahx = param.xahx, xahy = param.xahy, xawx = param.xawx, xawy = param.xawy,
      xc = param.xc, yc = param.yc, xcwx = param.xcwx, xcwy = param.xcwy, xchx = param.xchx, xchy = param.xchy,
      xg = param.xg, yg = param.yg, xgwx = param.xgwx, xgwy = param.xgwy, xghx = param.xghx, xghy = param.xghy,
      xi = param.xi, yi = param.yi, xiwx = param.xiwx, xiwy = param.xiwy, xihx = param.xihx, xihy = param.xihy,
      Ra = param.Ra,Rc = param.Rc,Rg = param.Rg,Ri = param.Ri;
    if (area == "A") {
        var myFillet={
            paths: {
                "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
                "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
                "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
                "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
                "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
                }
                };
        
    //   var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
    } else if (area == "C") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
            "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
            "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
    } else if (area == "G") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
            "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
            "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
            "BottomLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
    } else if (area == "I") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
            "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
            "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "AC") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line( [xchx, xchy], [xi, yi]),
            "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
    } else if (area == "AG") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
            "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
            "BottomLine": new makerjs.paths.Line([xi, yi],[xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
    } else if (area == "AI") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
            "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "CG") {
      var myFillet={
        paths: {
           "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
           "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
            "BottomLine": new makerjs.paths.Line([xi, yi],[xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };

      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
    } else if (area == "CI") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
            "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
            }
            };

      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "GI") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
            "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
            "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    } else if (area == "ACG") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line( [xchx, xchy], [xi, yi]),
            "BottomLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
    } else if (area == "ACI") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line( [xchx, xchy], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
            }
            };

      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "AGI") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
            "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy],[xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };

      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    } else if (area == "CGI") {
      var myFillet={
        paths: {
           "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
           "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy],[xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    } else if (area == "ACGI") {
      var myFillet={
        paths: {
            "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
            "TopLeftArc": new makerjs.paths.Arc([xahx, xahy], [xawx, xawy],Ra,false,true),
            "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
            "TopRightArc": new makerjs.paths.Arc([xcwx, xcwy], [xchx, xchy],Rc,false,true),
            "RightLine": new makerjs.paths.Line( [xchx, xchy], [xihx, xihy]),
            "BottomRightArc": new makerjs.paths.Arc([xihx, xihy],[xiwx, xiwy],Ri,false,true),
            "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
            "BottomLeftArc": new makerjs.paths.Arc([xgwx, xgwy], [xghx, xghy],Rg,false,true),
            }
            };
      // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    }
    console.log("是否调用此函数creatOneCorner（）param=",myFillet);
    return myFillet;
  };
  // 矩形等去角标记 连线函数
  function creatFilletMark(outParam,area, param,inputParam,fixedParam,OffsetParam,zoom) {
    console.log("是否调用此函数creatCornerMark()");
    console.log("是否调用此函数param",param);
    console.log("是否调用此函数OffsetParam",OffsetParam);
    var RA=inputParam.Ra,
    RC=inputParam.Rc,
    RG=inputParam.Rg,
    RI=inputParam.Ri,
    HH=inputParam.H,WW=inputParam.W,H = outParam.H, H1 = outParam.H1, H2 = outParam.H2, W = outParam.W, W1 = outParam.W1, W2 = outParam.W2,
    markShiftTwo = fixedParam.markShiftTwo*1,myZoom = zoom * 1,
    rd=OffsetParam.rMark*1,
    //   ahd=OffsetParam.ahd*1,chd=OffsetParam.chd*1,cwd=OffsetParam.cwd*1,cwdd=OffsetParam.cwdd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
      xa = param.xa*1, ya = param.ya*1, xahx = param.xahx*1, xahy = param.xahy*1, xawx = param.xawx*1, xawy = param.xawy*1,
      xc = param.xc*1, yc = param.yc*1, xcwx = param.xcwx*1, xcwy = param.xcwy*1, xchx = param.xchx*1, xchy = param.xchy*1,
      xg = param.xg*1, yg = param.yg*1, xgwx = param.xgwx*1, xgwy = param.xgwy*1, xghx = param.xghx*1, xghy = param.xghy*1,
      xi = param.xi*1, yi = param.yi*1, xiwx = param.xiwx*1, xiwy = param.xiwy*1, xihx = param.xihx*1, xihy = param.xihy*1,
      Ra = param.Ra,Rc = param.Rc,Rg = param.Rg,Ri = param.Ri,rda=Ra-(Ra/Math.sqrt(2)),rdc=Rc-(Rc/Math.sqrt(2)),
      rdg=Rg-(Rg/Math.sqrt(2)),rdi=Ri-(Ri/Math.sqrt(2)),
      h = H * 1 * myZoom,
          h1 = H1 * 1 * myZoom,
          h2 = H2 * 1 * myZoom,
          w = W * 1 * myZoom,
          w1 = W1 * 1 * myZoom,
          w2 = W2 * 1 * myZoom,

          rra = RA * 1 * myZoom,
          rrc = RC * 1 * myZoom,
          rrg = RG * 1 * myZoom,
          rri = RI * 1 * myZoom,
          
      hh = HH * 1 * myZoom,
      ww = WW * 1 * myZoom;
    if (rra !== 0) {
      var TxtRa="R="+RA.toString();
  } 
  if (rrc !== 0) {
    var TxtRc="R="+RC.toString();
} 
if (rrg !== 0) {
  var TxtRg="R="+RG.toString();
} 
if (rri !== 0) {
  var TxtRi="R="+RI.toString();
} 
  
    if (area == "A") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字  
  var markers = {
    myFilletMarkA,
  }
     
    } else if (area == "C") {    
      var myFilletMarkC = {
        paths: {
          "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
        }
    };
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字  
  var markers = {
    myFilletMarkC,
  }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
    } else if (area == "G") {
      var myFilletMarkG = {
        paths: {
          "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
        }
    };
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字  
  var markers = {
    myFilletMarkG,
  }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
    } else if (area == "I") {
      var myFilletMarkI = {
        paths: {
          "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
        }
    };
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字  
  var markers = {
    myFilletMarkI,
  }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "AC") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
    var myFilletMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字  
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
  var markers = {
    myFilletMarkA,
    myFilletMarkC,
  }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
    } else if (area == "AG") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
    var myFilletMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([rda, rda], [Ra, Ra]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字
  var markers = {
    myFilletMarkA,
    myFilletMarkG,
  } 
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
    } else if (area == "AI") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
  var markers = {
    myFilletMarkA,
    myFilletMarkI,
  } 
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "CG") {

    var myFilletMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
      }
  };
  var myFilletMarkG = {
    paths: {
      "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
    }
};
makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字
  var markers = {
    myFilletMarkC,
    myFilletMarkG,
  }
     
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
    } else if (area == "CI") {
      
      var myFilletMarkC = {
        paths: {
          "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
        }
    };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
    var markers = {
      myFilletMarkC,
      myFilletMarkI,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "GI") {
     
    var myFilletMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
      }
  };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字   
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
    var markers = {
      myFilletMarkG,
      myFilletMarkI,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    } else if (area == "ACG") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
      var myFilletMarkC = {
        paths: {
          "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
        }
    };
    var myFilletMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字
    var markers = {
      myFilletMarkA,
      myFilletMarkC,
      myFilletMarkG,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
    } else if (area == "ACI") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
      var myFilletMarkC = {
        paths: {
          "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
        }
    };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
    var markers = {
      myFilletMarkA,
      myFilletMarkC,
      myFilletMarkI,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
    } else if (area == "AGI") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
    var myFilletMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
      }
  };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
    var markers = {
      myFilletMarkA,
      myFilletMarkG,
      myFilletMarkI,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    } else if (area == "CGI") {
     
      var myFilletMarkC = {
        paths: {
          "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
        }
    };
    var myFilletMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
      }
  };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
    var markers = {
      myFilletMarkC,
      myFilletMarkG,
      myFilletMarkI,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    } else if (area == "ACGI") {
      var myFilletMarkA = {
        paths: {
          "Line": new makerjs.paths.Line([rda, ya-rda], [Ra, ya-Ra]),//左上角
        }
    };
      var myFilletMarkC = {
        paths: {
          "Line": new makerjs.paths.Line([xc-rdc, yc-rdc], [xc-Rc, yc-Rc]),//左上角
        }
    };
    var myFilletMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([rdg, rdg], [Rg, Rg]),//左上角
      }
  };
    var myFilletMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-rdi, rdi], [xi-Ri, Ri]),//左上角
      }
  };
  makerjs.model.addCaption(myFilletMarkA, TxtRa, [Ra+markShiftTwo, ya-Ra-markShiftTwo], [Ra+markShiftTwo*2, ya-Ra-markShiftTwo]);//半径文字
  makerjs.model.addCaption(myFilletMarkC, TxtRc,[xc-Rc-2*markShiftTwo, ya-Rc-markShiftTwo],[xc-Rc-markShiftTwo, yc-Rc-markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkG, TxtRg,[Rg+markShiftTwo, Rg+markShiftTwo], [Rg+2*markShiftTwo, Rg+markShiftTwo] );//半径文字
  makerjs.model.addCaption(myFilletMarkI, TxtRi, [xi-Ri-2*markShiftTwo, Ri+markShiftTwo], [xi-Ri-markShiftTwo, Ri+markShiftTwo]);//半径文字
    var markers = {
      myFilletMarkA,
      myFilletMarkC,
      myFilletMarkG,
      myFilletMarkI,
    }
      // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
    }
    return {markers};
  };
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Fillet(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
    var frame = outParam.shapeName;
    console.log("outParam==", outParam)
    console.log("model==", model)
    console.log("inputParam==", inputParam)
    console.log("outParam.shapeName==////////", outParam.shapeName)
    if (typeof (frame) == "undefined") {
        var R=inputParam.R,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,frame = "Rectangle",
        glassW=outParam.glassW,glassH=outParam.glassH,
           markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
           markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
  
           R = R*1*myZoom,
              w0= glassW * 1,
              h0= glassH * 1,
          rd=R/Math.sqrt(2),
          rMark=R-rd,
                x0 = X*1*myZoom,
                y0 = Y*1*myZoom;
                if(plate.models===undefined||plate.models===""){
                  console.log("1111111111111111");
                  plate.models={};
                          }        
                          if(cutPlate.models===undefined||cutPlate.models===""){
                              console.log("22222222222");
                              //cutPlate.models={};
                              cutPlate.models={};
                          }
                          if(markers.models===undefined||markers.models===""){            
                              console.log("333333333333");
                              markers.models={};
                          }
                /*
                R = R*1,
                   w0= glassW * 1,
                   h0= glassH * 1, 
               rd=R/Math.sqrt(2),
                     x0 = X*1,
                     y0 = Y*1;
                     */
                if(R!==0){
                 var TxtR="R="+R;
                }
                if(x0!==0){          
                var TxtX=X;
                }
                if(y0!==0){          
                 var TxtY=Y;
                }
    } else {
      var H = inputParam.H, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
        glassW = outParam.W, glassH = outParam.H, glassW1 = outParam.W1, glassW2 = outParam.W2, glassH1 = outParam.H1, glassH2 = outParam.H2,
        markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        h = H * 1 * myZoom,
        w = W * 1 * myZoom,
        x0 = X * 1 * myZoom,
        y0 = Y * 1 * myZoom;
        if(plate.models===undefined||plate.models===""){
          console.log("1111111111111111");
          plate.models={};
                  }        
                  if(cutPlate.models===undefined||cutPlate.models===""){
                      console.log("22222222222");
                      //cutPlate.models={};
                      cutPlate.models={};
                  }
                  if(markers.models===undefined||markers.models===""){            
                      console.log("333333333333");
                      markers.models={};
                  }
      /*
      h = H*1,
      w= W*1,
        x0 = X*1,
        y0 = Y*1;
        */
      if (h !== 0) {
        var TxtH = H;
      }
      if (w !== 0) {
        var TxtW = W;
      }
      if (x0 !== 0) {
        var TxtX = X;
      }
      if (y0 !== 0) {
        var TxtY = Y;
      }
    }  

 //
  /*
  四个角  
  一个角： 有四种情况  1、3、7、9 
  两个角： 有六种情况  [1、3] [1、3] [1、7][3、7] [3、9] [7、9]
  三个角： 有四种情况  [1、3、7] [1、3、9] [3、7、9][1、7、9] 
  四个解： 只有一种情况 [1、3、7、9]
  */

  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****")
    console.log("glassW||||", glassW)
    console.log("glassH||||", glassH)
    console.log("glassH||||----R", R)

//在A区域 计算孔的位置       
var xa=x0,ya=glassH * 1-y0,
awd=0,ahd=0,cwd=0,cwdd=0,chd=0,iwd=0,iwdd=0,ihd=0,
xawx = xa+R, xawy = ya,//A位置角W坐标
xahx = xa, xahy = ya - R,//A位置角H坐标  
//C区域
xc=glassW * 1-x0,yc=glassH * 1-y0,
xcwx = xc - R, xcwy = yc,//C位置角W坐标
xchx = xc, xchy = yc - R,//C位置角H坐标

//在G区域 计算孔的位置
xg=x0,yg=y0,
xgwx = xg + R, xgwy = yg,//G位置角W坐标
xghx = xg, xghy = yg + R,//G位置角H坐标

//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0,
xiwx = xi - R, xiwy = yi,//I位置角W坐标
xihx = xi, xihy = yi + R;//I位置角H坐标

  var OffsetParam={rMark};
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),            
      cwd=awd,
      xawx = xa+w, xawy = ya,//A位置角W坐标
      xahx = xa-awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc+awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg+awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi-awd, xihy = yi + h;//I位置角H坐标
      var OffsetParam={awd,ahd:0,cwd,cwdd:0,chd:0,iwd:awd,iwdd:0,ihd:0};
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa+w, xawy = ya+ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc-ahd,//C位置角W坐标
      xchx = xc+iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi-iwd, xihy = yi + h;//I位置角H坐标
      var OffsetParam={awd:0,ahd,cwd:iwd,cwdd:0,chd:0,iwd,iwdd:0,ihd:0};
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      

      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa+w, xawy = ya-ahd,//A位置角W坐标
      xahx = xa-awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc+ahd,//C位置角W坐标
      xchx = xc+iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg+gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi-iwd, xihy = yi + h;//I位置角H坐标
      var OffsetParam={awd:0,ahd:0,cwd:iwd,cwdd:0,chd:ahd,iwd,iwdd:0,ihd:0};
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa+w, xawy = ya,//A位置角W坐标
      xahx = xa-awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc+cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg+awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi-cwd, xihy = yi + h;//I位置角H坐标
      var OffsetParam={awd:0,ahd:0,cwd,cwdd:0,chd:0,iwd:cwd,iwdd:0,ihd:0};
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,      
      xawx = xa+w, xawy = ya,//A位置角W坐标
      xahx = xa-awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc-awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg+awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi+awd, xihy = yi + h;//I位置角H坐标

      var OffsetParam={awd:0,ahd:0,cwd:0,cwdd:awd,chd:0,iwd:0,iwdd:awd,ihd:0};

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa+w, xawy = ya,//A位置角W坐标
      xahx = xa-awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc-cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg+awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi+cwd, xihy = yi + h;//I位置角H坐标
      var OffsetParam={awd:0,ahd:0,cwd:0,cwdd:awd,chd:0,iwd:0,iwdd:cwd,ihd:0};
    
  }



  console.log("inputParam坐标参数坐标参数坐标参数坐标参数=",inputParam)
  if(inputParam.area===undefined){
    var ObliqueAngleData=showArea(inputParam,outParam);
    var paramCoordinate =ObliqueAngleData[1];
    var areaPoint =ObliqueAngleData[0];
    var whMarksParam=ObliqueAngleData[2];
  }else{
 // 坐标参数
 var paramCoordinate = {R,xa, ya, xawx, xawy, xahx, xahy, xc, yc, xcwx, xcwy, xchx, xchy, xg, yg, xgwx, xgwy, xghx, xghy, xi, yi, xiwx, xiwy, xihx, xihy };
 const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
 var areaPoint = "";
 console.log("//画1、斜角 ------", allKeys)
 if(allKeys[0]==frame){
   allKeys.shift();//去掉数组第一个元素
 }        
 console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
 if (allKeys.length == 0) {
   switch(area){
     case "1": areaPoint = "A";break;
     case "3": areaPoint = "C";break;
     case "7": areaPoint = "G";break;
     case "9": areaPoint = "I";break;
   }
 } else {
   switch(area){
     case "1": areaPoint = getStrs("A", allKeys);break;
     case "3": areaPoint = getStrs("C", allKeys);break;
     case "7": areaPoint = getStrs("G", allKeys);break;
     case "9": areaPoint = getStrs("I", allKeys);break;
   }
 }
  }


  console.log("whMarksParam==",whMarksParam);
/**
* 画图
* 
*/
   var ObjName = "myFillet" + areaPoint;
   var ObjNameM = "myFillet" + areaPoint + "Mark";//外框标记
   var ObjNameMCorner = "myFillet" + areaPoint + "MarkCorner";//角标记
   var ObjNameO = "myFillet" + areaPoint + "Obj";
   ObjName = creatFillet(areaPoint, paramCoordinate);//图形
   console.log("ObjName===|||||||||", ObjName);
   ObjNameMCorner = creatFilletMark(outParam,areaPoint, paramCoordinate,whMarksParam,fixedParam,OffsetParam,zoom);//图形
   console.log("ObjNameMCorner===|||||||||角标注", ObjNameMCorner);
   ObjNameO = { ObjName };
   ObjNameM = RectangleMark(outParam, model, fixedParam, zoom,frame);//外框标记
   cutPlate.models= {};
   plate.models = {};
   markers.models= {};
   cutPlate.models["myFillet" + areaPoint] = ObjName;
   plate.models["myFillet" + areaPoint] = ObjName;

   console.log("ObjNameM.markers.models===|||||||||是否有所有标注",ObjNameM.markers.models);
   console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMCorner.markers);


   markers.models = { ...ObjNameM.markers.models, ...ObjNameO,...ObjNameMCorner.markers};
   
   console.log("markers===|||||||||是否有所有标注", markers);
/**
* 画图
* 
*/

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default Fillet;