//角6、地弹簧2
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
function changeArea(num){
  var areaPoint;
  switch(num){
 case "1": areaPoint = "A";break;
 case "2": areaPoint = "B";break;
 case "3": areaPoint = "C";break;
 case "4": areaPoint = "D";break;
 case "5": areaPoint = "E";break;
 case "6": areaPoint = "F";break;
 case "7": areaPoint = "G";break;
 case "8": areaPoint = "H";break;
 case "9": areaPoint = "I";break;
}
return areaPoint;
};
function showArea(data,outParam){ 
  console.log("data|||ObliqueAngle$$$$$$$$$$||||showArea====",data);
  var x=outParam.glassW*1,y=outParam.glassH*1;
  console.log("data==data.area===",data.area);
  console.log("data==typeof(data)===",typeof(data));
  console.log("data==data.length===",data.length);
  console.log("data[0]==data.length===",data[0]);
  console.log("data[1]==data.length===",data[1]);
//console.log("myObj=showArea=",obj);  
    var properNum=objHasOwnProperty(data);
    console.log("properNum===",properNum);
    var arrData=[];
    var arrArea=[];
    var arrAreaNum=[];
    for (let key in data) {
      console.log("测试key0=", key);
            if (data.hasOwnProperty(key)&& key!=="0") {              
              console.log("测试key0=", key);
              if (key !== "Rectangle") {
                console.log("myData=", data);
              console.log("测试key2=", key);
              console.log("data[key].shape=", data[key].shape);
              console.log("data[key].area=", data[key].area);            
              arrData.push(data[key]);
              arrArea.push(changeArea(data[key].area));
              arrAreaNum.push(data[key].area);
              }         
            }
          }    
          console.log("arrData=",arrData);     
         var xa = 0, ya = y,xc = x , yc = y ,xg = 0, yg = 0,xi = x , yi = 0,
         da=0,wa=0,w1a=0,w2a=0,ha=0,h1a=0,ra=0,r1a=0,
         dc=0,wc=0,w1c=0,w2c=0,hc=0,h1c=0,rc=0,r1c=0,
         dg=0,wg=0,w1g=0,w2g=0,hg=0,h1g=0,rg=0,r1g=0,
         di=0,wi=0,w1i=0,w2i=0,hi=0,h1i=0,ri=0,r1i=0,
         xawx1=0,xawy1=0,xawx2=0,xawy2=0,xawx3=0,xawy3=0,
         xcwx1=0,xcwy1=0,xcwx2=0,xcwy2=0,xcwx3=0,xcwy3=0,
         xgwx1=0,xgwy1=0,xgwx2=0,xgwy2=0,xgwx3=0,xgwy3=0,
         xiwx1=0,xiwy1=0,xiwx2=0,xiwy2=0,xiwx3=0,xiwy3=0;       
    for(var i = 0; i < arrAreaNum.length; i++){
      var areaNums=arrAreaNum[i];
      var r1=arrData[i].R*1,d=arrData[i].D*1,r=d/2,w=arrData[i].W*1,w1=arrData[i].W1*1,w2=arrData[i].W2*1,h=arrData[i].H*1,h1=arrData[i].H1*1;
      switch(areaNums){
         case "1":   
        // console.log("switch objA.xawx",xawx);
        da=d,wa=w,w1a=w1,w2a=w2,ha=h,h1a=h1,ra=r,r1a=r1,         
        xawx1 = xa, xawy1 = ya - h,//A位置角1坐标
        xawx2 = xa + w, xawy2 = ya - h,//A位置角2坐标
        xawx3 = xa + w2, xawy3 = ya;//A位置角3坐标
          break;
         case "3":  
         dc=d,wc=w,w1c=w1,w2c=w2,hc=h,h1c=h1,rc=r,r1c=r1,      
         xcwx1 = xc, xcwy1 = yc - h,//C位置角1坐标
      xcwx2 = xc - w, xcwy2 = yc - h,//C位置角2坐标
      xcwx3 = xc - w2, xcwy3 = yc;//C位置角3坐标
         break;
         case "7":        
         dg=d,wg=w,w1g=w1,w2g=w2,hg=h,h1g=h1,rg=r,r1g=r1,          
         xgwx1 = xg, xgwy1 = yg + h,//G位置角1坐标
         xgwx2 = xg + w, xgwy2 = yg + h,//G位置角2坐标
         xgwx3 = xg + w2, xgwy3 = yg;//G位置角3坐标
         break;
         case "9":  
         di=d,wi=w,w1i=w1,w2i=w2,hi=h,h1i=h1,ri=r,r1i=r1,          
         xiwx1 = xi, xiwy1 = yi + h,//I位置角1坐标
         xiwx2 = xi - w, xiwy2 = yi + h,//I位置角2坐标
         xiwx3 = xi - w2, xiwy3 = yi;//I位置角3坐标
         break;
       }
    }  
          arrArea=arrArea.sort();
          arrArea=arrArea.join("");
        var whMark={};
         var paramCoordinate = {da,wa,w1a,w2a,ha,h1a,ra,r1a,dc,wc,w1c,w2c,hc,h1c,rc,r1c,dg,wg,w1g,w2g,hg,h1g,rg,r1g,di,wi,w1i,w2i,hi,h1i,ri,r1i,xa, ya, xawx1, xawy1,xawx2, xawy2,xawx3, xawy3,xc, yc, xcwx1, xcwy1, xcwx2,xcwy2,xcwx3,xcwy3,xg, yg, xgwx1, xgwy1, xgwx2, xgwy2,xgwx3, xgwy3,xi, yi, xiwx1, xiwy1, xiwx2, xiwy2, xiwx3, xiwy3}
          console.log("showArea===arrData=",arrData);
          console.log("showArea===arrArea=",arrArea);
          console.log("paramCoordinate===arrArea=",paramCoordinate);
          console.log("whMark===whMark=",whMark);
          var objData=[arrArea,paramCoordinate,whMark]; 
  
  return objData;
      };
function objHasOwnProperty(obj) {
        var count = 0;
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            count++;
          }
        }
        return count;
      };
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(14);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatFloorSpring2(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1, xawx2 = param.xawx2, xawy2 = param.xawy2, xawx3 = param.xawx3, xawy3 = param.xawy3,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1, xcwx2 = param.xcwx2, xcwy2 = param.xcwy2, xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2, xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2, xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,
    d = param.d, r = param.r, r1 = param.r1, w = param.w, w1 = param.w1, w2 = param.w2, h = param.h, h1 = param.h1,
    da = param.da,ra = param.ra,r1a = param.r1a,wa = param.wa,w1a = param.w1a,w2a = param.w2a,ha = param.ha,h1a = param.h1a,
    dc = param.dc,rc = param.rc,r1c = param.r1c,wc = param.wc,w1c = param.w1c,w2c = param.w2c,hc = param.hc,h1c = param.h1c,
    dg = param.dg,rg = param.rg,r1g = param.r1g,wg = param.wg,w1g = param.w1g,w2g = param.w2g,hg = param.hg,h1g = param.h1g,
    di = param.di,ri = param.ri,r1i = param.r1i,wi= param.wi,w1i = param.w1i,w2i = param.w2i,hi = param.hi,h1i = param.h1i;
  if (area == "A") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
      }
    };

    //   var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myFloorSpring2 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        // "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineI": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineI": new makerjs.paths.Line([xa, ya], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myFloorSpring2 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myFloorSpring2 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };

    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myFloorSpring2 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myFloorSpring2 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    var myFloorSpring2Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1a, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1c, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1i, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1g, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1a, ya - h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc - w1c, yc - h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg + w1g, yg + h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi - w1i, yi + h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return [myFloorSpring2,myFloorSpring2Cut];
};
// 矩形等去角标记 连线函数
function creatFloorSpring2Mark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatFloorSpring2Mark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var HH = outParam.H, WW = outParam.W, D = inputParam.D, H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, W2 = inputParam.W2, W2 = inputParam.W2, R = inputParam.R, R1 = inputParam.R1, R2 = inputParam.R2,
    markShiftTwo = fixedParam.markShiftTwo * 1, myZoom = zoom * 1,
    // // rd = OffsetParam.rMark * 1,
    //   Aa=OffsetParam.Aa*1,Aaa=OffsetParam.Aaa*1,Ab=OffsetParam.Ab*1,Ahd=OffsetParam.Ahd*1,
    //   Ca=OffsetParam.a*1,Caa=OffsetParam.Caa*1,Cb=OffsetParam.Cb*1,Chd=OffsetParam.Chd*1,
    //   Ga=OffsetParam.Ga*1,Gaa=OffsetParam.Gaa*1,Gb=OffsetParam.Gb*1,Ghd=OffsetParam.Ghd*1,
    //   Ia=OffsetParam.Ia*1,Iaa=OffsetParam.Iaa*1,Ib=OffsetParam.Ib*1,Ihd=OffsetParam.Ihd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1, xawx2 = param.xawx2, xawy2 = param.xawy2, xawx3 = param.xawx3, xawy3 = param.xawy3,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1, xcwx2 = param.xcwx2, xcwy2 = param.xcwy2, xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2, xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2, xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,
    r = param.r, r1 = param.r1, r2 = param.r2, w = param.w, w1 = param.w1, w2 = param.w2, h = param.h, h1 = param.h1,
    da = param.da,ra = param.ra,r1a = param.r1a,wa = param.wa,w1a = param.w1a,w2a = param.w2a,ha = param.ha,h1a = param.h1a,
    dc = param.dc,rc = param.rc,r1c = param.r1c,wc = param.wc,w1c = param.w1c,w2c = param.w2c,hc = param.hc,h1c = param.h1c,
    dg = param.dg,rg = param.rg,r1g = param.r1g,wg = param.wg,w1g = param.w1g,w2g = param.w2g,hg = param.hg,h1g = param.h1g,
    di = param.di,ri = param.ri,r1i = param.r1i,wi= param.wi,w1i = param.w1i,w2i = param.w2i,hi = param.hi,h1i = param.h1i,   
    markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
    markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

    w = W * 1 * myZoom,
    w1 = W1 * 1 * myZoom,
    w2 = W2 * 1 * myZoom,
    h = H * 1 * myZoom,
    h1 = H1 * 1 * myZoom,
    r1 = R * 1 * myZoom,
    d = D * 1 * myZoom,
    r = d / 2,
    a = 0,//圆心坐标[a,b]
    b = 0;//圆心坐标[a,b]  
  // x0 = X*1*myZoom,
  //y0 = Y*1*myZoom;   


  if (d !== 0) {
    var TxtD = "∮" + D;
  }
  if (r1 !== 0) {
    var TxtR1 = "R=" + R;
  }
  if (w !== 0) {
    var TxtW = W;
  }
  if (w1 !== 0) {
    var TxtW1 = W1;
  }
  if (w2 !== 0) {
    var TxtW2 = W2;
  }
  if (h !== 0) {
    var TxtH = H;
  }
  if (h1 !== 0) {
    var TxtH1 = H1;
  }
  ///////////////////////////
  if (da !== 0) {
    var TxtDa = "∮" + da.toString();
  }
  if (r1a !== 0) {
    var TxtR1a = "R=" + r1a.toString();
  }
  if (wa !== 0) {
    var TxtWa = wa.toString();
  }
  if (w1a !== 0) {
    var TxtW1a = w1a.toString();
  }
  if (w2a !== 0) {
    var TxtW2a = w2a.toString();
  }
  if (ha !== 0) {
    var TxtHa = ha.toString();
  }
  if (h1a !== 0) {
    var TxtH1a = h1a.toString();
  }
  //////////////////////////
  if (dc !== 0) {
    var TxtDc = "∮" + dc.toString();
  }
  if (r1c !== 0) {
    var TxtR1c = "R=" + r1c.toString();
  }
  if (wc !== 0) {
    var TxtWc = wc.toString();
  }
  if (w1c !== 0) {
    var TxtW1c = w1c.toString();
  }
  if (w2c !== 0) {
    var TxtW2c = w2c.toString();
  }
  if (hc !== 0) {
    var TxtHc = hc.toString();
  }
  if (h1c !== 0) {
    var TxtH1c = h1c.toString();
  }
  /////////////////////
  if (dg !== 0) {
    var TxtDg = "∮" + dg.toString();
  }
  if (r1g !== 0) {
    var TxtR1g = "R=" + r1g.toString();
  }
  if (wg !== 0) {
    var TxtWg = wg.toString();
  }
  if (w1g !== 0) {
    var TxtW1g = w1g.toString();
  }
  if (w2g !== 0) {
    var TxtW2g = w2g.toString();
  }
  if (hg !== 0) {
    var TxtHg = hg.toString();
  }
  if (h1g !== 0) {
    var TxtH1g = h1g.toString();
  }
  /////////////////////////
  if (di !== 0) {
    var TxtDi = "∮" + di.toString();
  }
  if (r1i !== 0) {
    var TxtR1i = "R=" + r1i.toString();
  }
  if (wi !== 0) {
    var TxtWi = wi.toString();
  }
  if (w1i !== 0) {
    var TxtW1i = w1i.toString();
  }
  if (w2i !== 0) {
    var TxtW2i = w2i.toString();
  }
  if (hi !== 0) {
    var TxtHi = hi.toString();
  }
  if (h1i !== 0) {
    var TxtH1i = h1i.toString();
  }


  console.log("console.log TxtDg,TxtR1g,TxtR2g,TxtWg,TxtHg,TxtH1g,TxtDg",TxtDg,TxtR1g,TxtWg,TxtHg,TxtH1g,TxtDg)
  console.log("console.log TxtRg,TxtR1g,TxtR2g,TxtWg,TxtHg,TxtH1g,TxtDg",rg,r1g,wg,hg,h1g,dg)


  if (area == "A") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
    }

  } else if (area == "C") {
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };

    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
    ////////////****************************** */
    var markers = {
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
    }
  } else if (area == "G") {
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  
  
  /////////////////////******************************************** */
    var markers = {
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
    }

  } else if (area == "I") {
 
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
   
    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  
/************************************************************************************** */
    var markers = {
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,
    }

  } else if (area == "AC") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/

    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
   
    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      /**I */

    }

  } else if (area == "AG") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
   
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
   
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
  
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

 
    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */

    }
  } else if (area == "AI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
  
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
   
    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  

    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      /**G */
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "CG") {
 
    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
  
    /********************************************** */
  
    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

   
  
    /******************************************************************** */
    var markers = {
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */

    }
  } else if (area == "CI") {

    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
   
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/

    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
    
    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  

    /******************************************************************** */
    var markers = {
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "GI") {
 
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  

    /******************************************************************** */
    var markers = {
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "ACG") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
   
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/

    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

   
    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

   
    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */

    }
  } else if (area == "ACI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/

    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
    
    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  
    
    
    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "AGI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
   
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
 
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  
    
    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "CGI") {
 
    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
   /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/

   makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
   makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
   makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
   makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
   makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
   makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
   makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
  
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  
    /******************************************************************** */
    var markers = {
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "ACGI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa, ya - ha - markShift], [xa + wa, ya - ha - markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa, ya - ha - da - markShift], [xa + w1a, ya - ha - da - markShift]),
        "W1ALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a, ya - h1a - da - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa + w2a, ya], [xa + w2a, ya - ra - h1a - h1a - markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa, ya - ha - ha - markShiftThree], [xa + w2a, ya - ha - ha - markShiftThree]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa, ya - ha], [xa - markShiftFour, ya - ha]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - ha]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1a - ra, ya - h1a], [xa + w2a + ra + markShiftFour, ya - h1a]),
        "H1ALine1": new makerjs.paths.Line([xa + w2a + markShift, ya], [xa + w2a + markShift, ya - h1a]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine1": new makerjs.paths.Line([xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa + w1a, ya - h1a], [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc, yc - hc], [xc, yc - hc - markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftThree]),//左上角
        "WCLine2": new makerjs.paths.Line([xc, yc - hc - markShift], [xc - wc, yc - hc - markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc, yc - hc - dc - markShift], [xc - w1c, yc - hc - dc - markShift]),
        "W1CLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c, yc - h1c - dc - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc, yc - hc - hc - markShiftThree], [xc - w2c, yc - hc - hc - markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc - w2c, yc], [xc - w2c, yc - hc - hc - dc - markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - hc], [xc + markShiftFour, yc - hc]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - hc]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - dc / 2 - markShiftFour, yc - h1c]),
        "H1CLine1": new makerjs.paths.Line([xc - w2c - markShift, yc], [xc - w2c - markShift, yc - h1c]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
        "R1CLine1": new makerjs.paths.Line([xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc - w1c, yc - h1c], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg, yg + hg], [xg, yg + hg + markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftThree]),//左上角
        "WGLine2": new makerjs.paths.Line([xg, yg + hg + markShift], [xg + wg, yg + hg + markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg, yg + hg + dg + markShift], [xg + w1g, yg + hg + dg + markShift]),
        "W1GLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g, yg + h1g + dg + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg, yg + hg + hg + markShiftThree], [xg + w2g, yg + hg + hg + markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg + w2g, yg], [xg + w2g, yg + hg + hg + dg + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + hg], [xg - markShiftFour, yg + hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + hg]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + dg / 2 + markShiftFour, yg + h1g]),
        "H1GLine1": new makerjs.paths.Line([xg + w2g + markShift, yg], [xg + w2g + markShift, yg + h1g]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg + wg + (wg / 2), yg + hg], [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg + w1g, yg + h1g], [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi, yi + hi], [xi, yi + hi + markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi + markShiftThree]),//左上角
        "WILine2": new makerjs.paths.Line([xi, yi + hi + markShift], [xi - wi, yi + hi + markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi, yi + hi + di + markShift], [xi - w1i, yi + hi + di + markShift]),
        "W1ILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i, yi + h1i + di + markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi, yi + hi + hi + markShiftThree], [xi - w2i, yi + hi + hi + markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi - w2i, yi], [xi - w2i, yi + hi + hi + di + markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + hi], [xi + markShiftFour, yi + hi]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + hi]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - di / 2 - markShiftFour, yi + h1i]),
        "H1ILine1": new makerjs.paths.Line([xi - w2i - markShift, yi], [xi - w2i - markShift, yi + h1i]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine1": new makerjs.paths.Line([xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi - w1i, yi + h1i], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]),//左上角
      }
    };
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa, ya - ha - markShiftThree], [xa + wa, ya - ha - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa, ya - ha - da - markShiftThree], [xa + w1a, ya - ha - da - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa, ya - ha - ha - ra - markShiftFour], [xa + w2a, ya - ha - ha - da - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa - markShiftFour, ya - ha], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa + w2a + markShiftThree, ya - h1a], [xa + w2a + markShiftThree, ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa + wa + (wa / 2), ya - ha], [xa + wa + markShiftFour, ya - ha + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa + w1a + markShift + markHolesOneD, ya - h1a - da - markShiftFour - markHolesOneD], [xa + w1a + markHolesOneD + markShiftFour, ya - h1a - da - markShiftFour - markHolesOneD]);//D文字标注  
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/

    makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc - wc, yc - hc - markShiftThree], [xc, yc - hc - markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc - w1c, yc - hc - dc - markShiftThree], [xc, yc - hc - dc - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc - w2c, yc - hc - hc - dc - markShiftFour], [xc, yc - hc - hc - dc - markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc + markShiftFour, yc - hc], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc - w2c - markShiftThree, yc - h1c], [xc - w2c - markShiftThree, yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc - wc - (wc / 2), yc - hc], [xc - wc - markShiftFour, yc - hc + markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtDc, [xc - w1c - markHolesOneD - markShiftFour, yc - h1c - dc - markShiftFour - markHolesOneD], [xc - w1c - markShift - markHolesOneD, yc - h1c - dc - markShiftFour - markHolesOneD]);//D文字标注  
   
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg, yg + hg + markShiftThree], [xg + wg, yg + hg + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg, yg + hg + dg + markShiftThree], [xg + w1g, yg + hg + dg + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg, yg + hg + hg + dg + markShiftFour], [xg + w2g, yg + hg + hg + dg + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg - markShiftFour, yg + hg], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg + w2g + markShiftThree, yg + h1g], [xg + w2g + markShiftThree, yg]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg + wg + (wg / 2) - markShiftFour, yg + hg - markShiftThree], [xg + wg + (wg / 2), yg + hg]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg + w1g + markShift + markHolesOneD, yg + h1g + dg + markShiftFour + markHolesOneD], [xg + w1g + markHolesOneD + markShiftFour, yg + h1g + dg + markShiftFour + markHolesOneD]);//D文字标注  

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/

    makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi - wi, yi + hi + markShiftThree], [xi, yi + hi + markShiftThree]);//半径文字  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi - w1i, yi + hi + di + markShiftThree], [xi, yi + hi + di + markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi - w2i, yi + hi + hi + di + markShiftFour], [xi, yi + hi + hi + di + markShiftFour]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi + markShiftFour, yi + hi], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi - w2i - markShiftThree, yi + h1i], [xi - w2i - markShiftThree, yi]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi - wi - (wi / 2), yi + hi], [xi - wi - markShiftFour, yi + hi - markShiftTwo]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtDi, [xi - w1i - markHolesOneD - markShiftFour, yi + h1i + di + markShiftFour + markHolesOneD], [xi - w1i - markShift - markHolesOneD, yi + h1i + di + markShiftFour + markHolesOneD]);//D文字标注  
    /******************************************************************** */
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
      /**C */
      myFsAMakerWC,
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerWG,
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerWI,
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }


    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return { markers };
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function FloorSpring2(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var D = inputParam.D, R = inputParam.R, W = inputParam.W, W1 = inputParam.W1, W2 = inputParam.W2, H = inputParam.H, H1 = inputParam.H1, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,


      w = W * 1 * myZoom,
      w1 = W1 * 1 * myZoom,
      w2 = W2 * 1 * myZoom,
      h = H * 1 * myZoom,
      h1 = H1 * 1 * myZoom,
      r1 = R * 1 * myZoom,
      d = D * 1 * myZoom,
      r = d / 2,
      a = 0,//圆心坐标[a,b]
      b = 0;//圆心坐标[a,b]  
    x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }

    if (d !== 0) {
      var TxtD = "∮" + D;
    }
    if (r1 !== 0) {
      var TxtR1 = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (w1 !== 0) {
      var TxtW1 = W1;
    }
    if (w2 !== 0) {
      var TxtW2 = W2;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (h1 !== 0) {
      var TxtH1 = H1;
    }
  } else {
    var D = inputParam.D, R = inputParam.R, W = inputParam.W, W1 = inputParam.W1, W2 = inputParam.W2, H = inputParam.H, H1 = inputParam.H1, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      w = W * 1 * myZoom,
      w1 = W1 * 1 * myZoom,
      w2 = W2 * 1 * myZoom,
      h = H * 1 * myZoom,
      h1 = H1 * 1 * myZoom,
      r1 = R * 1 * myZoom,
      d = D * 1 * myZoom,
      r = d / 2,
      a = 0,//圆心坐标[a,b]
      b = 0,//圆心坐标[a,b]  
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }

    if (d !== 0) {
      var TxtD = "∮" + D;
    }
    if (r1 !== 0) {
      var TxtR1 = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (w1 !== 0) {
      var TxtW1 = W1;
    }
    if (w2 !== 0) {
      var TxtW2 = W2;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (h1 !== 0) {
      var TxtH1 = H1;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }
  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****")

    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
      //C区域
      xc = glassW * 1 - x0, yc = glassH * 1 - y0,
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0,

      // "TopLine": new makerjs.paths.Line([xa-5, ya+5], [xa+w1, ya+5]),
      // "RightLine": new makerjs.paths.Line([xa+w1, ya+5], [xa+w1, ya]), 
      // "BottomRightArc": new makerjs.paths.Arc([xa+w1, ya], [xa+w, ya-h],r1,false,true),//是否大弧,是否顺时针
      // "BottomLine": new makerjs.paths.Line([xa+w, ya-h], [xa-5, ya-h]),
      // "LeftLine": new makerjs.paths.Line([xa-5, ya-h], [xa-5, ya+5]),
      // "RightHoles": new makerjs.paths.Circle([xa+w2, ya-h1], r),

      xawx1 = xa, xawy1 = ya - h,//A位置角1坐标
      xawx2 = xa + w, xawy2 = ya - h,//A位置角2坐标
      xawx3 = xa + w2, xawy3 = ya,//A位置角3坐标
      //  "TopLine": new makerjs.paths.Line([xc-w1, yc+5],[xc+5, yc+5]),
      // "RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
      // "BottomLine": new makerjs.paths.Line( [xc+5, yc-h],[xc-w, yc-h]),
      // "BottomRightArc": new makerjs.paths.Arc([xc-w, yc-h], [xc-w1, yc],r1,false,true),//是否大弧,是否顺时针
      // "LeftLine": new makerjs.paths.Line([xc-w1, yc], [xc-w1, yc+5]),
      // "RightHoles": new makerjs.paths.Circle([xc-w2, yc-h1], r),
      xcwx1 = xc, xcwy1 = yc - h,//C位置角1坐标
      xcwx2 = xc - w, xcwy2 = yc - h,//C位置角2坐标
      xcwx3 = xc - w2, xcwy3 = yc,//C位置角3坐标
      // var myFloorSpring2G = {
      //   paths: {  
      //   "LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
      //   "TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg+w, yg+h]),
      //   "TopLeftArc": new makerjs.paths.Arc([xg+w, yg+h], [xg+w1, yg],r1,false,true),//是否大弧,是否顺时针
      //   "RightLine": new makerjs.paths.Line([xg+w1, yg], [xg+w1, yg-5]),
      //   "BottomLine": new makerjs.paths.Line([xg+w1, yg-5], [xg-5, yg-5]),
      //   "RightHoles": new makerjs.paths.Circle([xg+w2, yg+h1], r),
      //   }
      //   };

      xgwx1 = xg, xgwy1 = yg + h,//G位置角1坐标
      xgwx2 = xg + w, xgwy2 = yg + h,//G位置角2坐标
      xgwx3 = xg + w2, xgwy3 = yg,//G位置角3坐标

      // var myFloorSpring2I = {
      //   paths: {
      //   "BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w1, yi-5]),
      //   "LeftLine": new makerjs.paths.Line([xi-w1, yi-5], [xi-w1, yi]), 
      //   "TopLeftArc": new makerjs.paths.Arc([xi-w1, yi], [xi-w, yi+h],r1,false,true),//是否大弧,是否顺时针
      //   "TopLine": new makerjs.paths.Line([xi-w, yi+h], [xi+5, yi+h]),
      //   "RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5]),
      //   "RightHoles": new makerjs.paths.Circle([xi-w2, yi+h1], r),
      //   }
      //   };

      xiwx1 = xi, xiwy1 = yi + h,//I位置角1坐标
      xiwx2 = xi - w, xiwy2 = yi + h,//I位置角2坐标
      xiwx3 = xi - w2, xiwy3 = yi;//I位置角3坐标
    // var OffsetParam={Aa,Aaa,Ahd,Ca,Caa,Chd,Ga,Gaa,Ghd,Ia,Iaa,Ihd};
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),
      cwd = awd,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - awd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: awd, iwdd: 0, ihd: 0 };
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa + w, xawy = ya + ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc - ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd, cwd: iwd, cwdd: 0, chd: 0, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移


      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa + w, xawy = ya - ahd,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc + ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: iwd, cwdd: 0, chd: ahd, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: cwd, iwdd: 0, ihd: 0 };
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + awd, xihy = yi + h;//I位置角H坐标

    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: awd, ihd: 0 };

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: cwd, ihd: 0 };

  }

  if(inputParam.area===undefined){
    var ObliqueAngleData=showArea(inputParam,outParam);
    var paramCoordinate =ObliqueAngleData[1];
    var areaPoint =ObliqueAngleData[0];
    var whMarksParam=ObliqueAngleData[2];
    console.log("whMarksParam=",whMarksParam);
  }else{
// 坐标参数
var paramCoordinate = { d, w, w1, w2, h, h1, r, r1, xa, ya, xawx1, xawy1, xawx2, xawy2, xawx3, xawy3, xc, yc, xcwx1, xcwy1, xcwx2, xcwy2, xcwx3, xcwy3, xg, yg, xgwx1, xgwy1, xgwx2, xgwy2, xgwx3, xgwy3, xi, yi, xiwx1, xiwy1, xiwx2, xiwy2, xiwx3, xiwy3 };
const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
var areaPoint = "";
console.log("//画1、斜角 ------", allKeys)
if (allKeys[0] == frame) {
  allKeys.shift();//去掉数组第一个元素
}
console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
if (allKeys.length == 0) {
  switch (area) {
    case "1": areaPoint = "A"; break;
    case "3": areaPoint = "C"; break;
    case "7": areaPoint = "G"; break;
    case "9": areaPoint = "I"; break;
  }
} else {
  switch (area) {
    case "1": areaPoint = getStrs("A", allKeys); break;
    case "3": areaPoint = getStrs("C", allKeys); break;
    case "7": areaPoint = getStrs("G", allKeys); break;
    case "9": areaPoint = getStrs("I", allKeys); break;
  }
}
  }

  
  /**
  * 画图
  * 
  */
  var ObjName = "myFloorSpring2" + areaPoint;
  var ObjNameM = "myFloorSpring2" + areaPoint + "Mark";//外框标记
  var ObjNameMFloorSpring2 = "myFloorSpring2" + areaPoint + "Mark";//角标记
  var ObjNameO = "myFloorSpring2" + areaPoint + "Obj";
  ObjName = creatFloorSpring2(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMFloorSpring2 = creatFloorSpring2Mark(outParam, areaPoint, paramCoordinate, whMarksParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMFloorSpring2===|||||||||角标注", ObjNameMFloorSpring2);
  ObjNameO = { ObjName:ObjName[0] };
  ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
  cutPlate.models = {};
  plate.models = {};
  markers.models = {};
  cutPlate.models["myFloorSpring2" + areaPoint] = ObjName[1];
  plate.models["myFloorSpring2" + areaPoint] = ObjName[0];
  console.log("ObjNameM.markers.models===|||||||||是否有所有标注", ObjNameM.markers.models);
  console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMFloorSpring2.markers);
  markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMFloorSpring2.markers };
  console.log("markers===|||||||||是否有所有标注", markers);

  myHolesNum++;
  return { plate, cutPlate, markers, myHolesNum }
};
export default FloorSpring2;