import makerjs from "makerjs";
function getPairs(arr) {
  let pairs = [];
  for (let i = 0; i < arr.length - 1; i++) {
      for (let j = i + 1; j < arr.length; j++) {
          pairs.push([arr[i], arr[j]]);
      }
  }
  return pairs;
}
function StairCase(roundCorner,inputParam, model, fixedParam, zoom) {
  /**
   * 
   * inputParam, 坐标点
   * model,      模板
   * fixedParam, 标注偏移
   * zoom,       放大缩小
   * 
   * */
  var startP = JSON.parse(JSON.stringify(inputParam.startP)), endP = JSON.parse(JSON.stringify(inputParam.endP)), brokenLinePoint = inputParam.brokenLinePoint,
  R=roundCorner*1,cutPlate = model.cutPlate, plate = model.plate, markers = model.markers, myZoom = zoom * 1,
    markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
    markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour;
  var arrPoints = [];
  for (let i = 0; i < brokenLinePoint.length; i++) {
    arrPoints.push([brokenLinePoint[i].x, brokenLinePoint[i].y]);
  }
  arrPoints.unshift([startP.x, startP.y]);
  arrPoints.push([endP.x, endP.y]);
  console.log("arrPoints外==", arrPoints);
  console.log("arrPoints外=typeof=", typeof (arrPoints));
  console.log("arrPoints.length外=typeof=", arrPoints.length);
  var myStairCase = new makerjs.models.ConnectTheDots(true, JSON.stringify(arrPoints));
  // var myStairCase = new makerjs.models.ConnectTheDots(true, [[0, 0], [100, 200], [500, 0]]);
  
var arrNum=[];
for(let i=1;i<=arrPoints.length;i++){
  arrNum.push(i);
}
var arrs=getPairs(arrNum);
console.log("arrs====",arrs);
console.log("arrs[0]====",arrs[0]);
console.log("arrs[0][0]====",arrs[0][0]);
for(let j=0;j<arrs.length;j++){
  myStairCase.paths["arc"+j] = makerjs.path.fillet(myStairCase.paths["ShapeLine"+arrs[j][0]], myStairCase.paths["ShapeLine"+arrs[j][1]],R);
}
  
  var arrObjs = {};
  for (var i = 0; i < arrPoints.length; i++) {
    var myStairMarkX, Ax, Ay, Bx, By;
    myStairMarkX = new makerjs.models.Square(0);
    Ax = arrPoints[i][0] * 1 - 10;
    Ay = arrPoints[i][1] * 1;
    Bx = arrPoints[i][0] * 1 + 15;
    By = arrPoints[i][1] * 1;
    arrObjs["myStairMark" + i] = makerjs.model.addCaption(myStairMarkX, "(" + arrPoints[i][0] + "," + arrPoints[i][1] + ")", [Ax, Ay], [Bx, By]);//X文字标注 
  }
  //console.log("myMarkersssssss",myMarker);    
  //var myStairCasePoint = new makerjs.models.Square(0);
  plate = {
    models: {
      myStairCase: myStairCase
    },
  };
  markers = {
    models:
    {
      myStairCase,
      ...arrObjs
    }
  };

  cutPlate = {
    models: {
      myStairCase: myStairCase,
    }
  };
  console.log("markers=", markers);
  return { plate, cutPlate, markers }
};
export default StairCase;