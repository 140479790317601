//角3、方角
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
//输入数字返回区域字母
function changeArea(num){
  var areaPoint;
  switch(num){
 case "1": areaPoint = "A";break;
 case "2": areaPoint = "B";break;
 case "3": areaPoint = "C";break;
 case "4": areaPoint = "D";break;
 case "5": areaPoint = "E";break;
 case "6": areaPoint = "F";break;
 case "7": areaPoint = "G";break;
 case "8": areaPoint = "H";break;
 case "9": areaPoint = "I";break;
}
return areaPoint;
};
function showArea(data,outParam){ 
  console.log("data|||ObliqueAngle$$$$$$$$$$||||showArea====",data);
  var x=outParam.glassW*1,y=outParam.glassH*1;
  console.log("data==data.area===",data.area);
  console.log("data==typeof(data)===",typeof(data));
  console.log("data==data.length===",data.length);
  console.log("data[0]==data.length===",data[0]);
  console.log("data[1]==data.length===",data[1]);
//console.log("myObj=showArea=",obj);  
    var properNum=objHasOwnProperty(data);
    console.log("properNum===",properNum);
    var arrData=[];
    var arrArea=[];
    var arrAreaNum=[];
    for (let key in data) {
      console.log("测试key0=", key);
            if (data.hasOwnProperty(key)&& key!=="0") {              
              console.log("测试key0=", key);
              if (key !== "Rectangle") {
                console.log("myData=", data);
              console.log("测试key2=", key);
              console.log("data[key].shape=", data[key].shape);
              console.log("data[key].area=", data[key].area);            
              arrData.push(data[key]);
              arrArea.push(changeArea(data[key].area));
              arrAreaNum.push(data[key].area);
              }         
            }
          }      
         var xa = 0, ya = y,xc = x , yc = y ,xg = 0, yg = 0,xi = x , yi = 0,ha=0,wa=0,ra=0,hc=0,wc=0,rc=0,hg=0,wg=0,rg=0,hi=0,wi=0,ri=0,
         xawx=0,xawy=0,xahx=0,xahy=0,rawx=0,rawy=0,rahx=0,rahy=0,
         xcwx=0,xcwy=0,xchx=0,xchy=0,rcwx=0,rcwy=0,rchx=0,rchy=0,
         xgwx=0,xgwy=0,xghx=0,xghy=0,rgwx=0,rgwy=0,rghx=0,rghy=0,
         xiwx=0,xiwy=0,xihx=0,xihy=0,riwx=0,riwy=0,rihx=0,rihy=0;
         console.log("xawx",xawx);
        
    for(var i = 0; i < arrAreaNum.length; i++){
      var areaNums=arrAreaNum[i];
      var w=arrData[i].W*1,h=arrData[i].H*1,r=arrData[i].R*1;
      switch(areaNums){
         case "1":   
         console.log("switch objA.xawx",xawx);         
         ha=h,wa=w,ra=r,
         xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      rawx = xa + w - r, rawy = ya - h,
      rahx = xa + w, rahy = ya - h + r;
          break;
         case "3":        
         hc=h,wc=w,rc=r,
         xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc, xchy = yc - h,//C位置角H坐标 
      rcwx = xc - w+ r, rcwy = yc - h,
      rchx = xc - w, rchy = yc - h + r;
         break;
         case "7":                  
         hg=h,wg=w,rg=r,
         xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      rgwx = xg + w - r, rgwy = yg + h,
      rghx = xg + w, rghy = yg + h - r;
         break;
         case "9":                 
         hi=h,wi=w,ri=r, 
         xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi, xihy = yi + h,//I位置角H坐标
      riwx = xi - w + r, riwy = yi + h,
      rihx = xi - w, rihy = yi + h - r;
         break;
       }
    }  
          arrArea=arrArea.sort();
          arrArea=arrArea.join("");
        var whMark={ha,wa,ra,hc,wc,rc,hg,wg,rg,hi,wi,ri};
         var paramCoordinate = { xa, ya, xawx, xawy, xahx, xahy, rawx, rawy, rahx, rahy, xc, yc, xcwx, xcwy, xchx, xchy, rcwx, rcwy, rchx, rchy, xg, yg, xgwx, xgwy, xghx, xghy, rgwx, rgwy, rghx, rghy, xi, yi, xiwx, xiwy, xihx, xihy, riwx, riwy, rihx, rihy,ha,wa,ra,hc,wc,rc,hg,wg,rg,hi,wi,ri }
          console.log("showArea===arrData=",arrData);
          console.log("showArea===arrArea=",arrArea);
          console.log("paramCoordinate===arrArea=",paramCoordinate);
          var objData=[arrArea,paramCoordinate,whMark];
  
 
  
  return objData;
      };
function objHasOwnProperty(obj) {
        var count = 0;
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            count++;
          }
        }
        return count;
      };
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(8);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatCorner(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xahx = param.xahx, xahy = param.xahy, xawx = param.xawx, xawy = param.xawy, rawx = param.rawx, rawy = param.rawy, rahx = param.rahx, rahy = param.rahy,
    xc = param.xc, yc = param.yc, xcwx = param.xcwx, xcwy = param.xcwy, xchx = param.xchx, xchy = param.xchy, rcwx = param.rcwx, rcwy = param.rcwy, rchx = param.rchx, rchy = param.rchy,
    xg = param.xg, yg = param.yg, xgwx = param.xgwx, xgwy = param.xgwy, xghx = param.xghx, xghy = param.xghy, rgwx = param.rgwx, rgwy = param.rgwy, rghx = param.rghx, rghy = param.rghy,
    xi = param.xi, yi = param.yi, xiwx = param.xiwx, xiwy = param.xiwy, xihx = param.xihx, xihy = param.xihy, riwx = param.riwx, riwy = param.riwy, rihx = param.rihx, rihy = param.rihy,
    Ra = param.ra,Rc = param.rc,Rg = param.rg,Ri = param.ri,rda=Ra-(Ra/Math.sqrt(2)),rdc=Rc-(Rc/Math.sqrt(2)),
      rdg=Rg-(Rg/Math.sqrt(2)),rdi=Ri-(Ri/Math.sqrt(2));
  if (area == "A") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };

    //   var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLtLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLineC": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArcC": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLineC": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopRLine": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myCorner = {
      paths: {        
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopLtine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRightArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopRtLine": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightTopLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightRtLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "BottomRightLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomTopLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRtArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine2": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRtArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine2": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightRtLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "RightRtLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], Ra, false, false),
        "TopLtine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRtArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], Rc, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "RtLine": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  Ri, false, false),
        "RightLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], Rg, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return myCorner;
};
// 矩形等去角标记 连线函数
function creatCornerMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()",inputParam);
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var RA=inputParam.ra,RC=inputParam.rc,RG=inputParam.rg,RI=inputParam.ri,
  HA=inputParam.ha,WA=inputParam.wa,HC=inputParam.hc,WC=inputParam.wc,
  HG=inputParam.hg,WG=inputParam.wg,HI=inputParam.hi,WI=inputParam.wi,
  HH = inputParam.H, WW = inputParam.W, H = outParam.H, H1 = outParam.H1, H2 = outParam.H2, W = outParam.W, W1 = outParam.W1, W2 = outParam.W2,
  markShift = fixedParam.markShift * 1,markShiftTwo = fixedParam.markShiftTwo * 1,
  markShiftThree = fixedParam.markShiftThree * 1, markShiftFour = fixedParam.markShiftFour * 1, myZoom = zoom * 1,
    rd = OffsetParam.rMark * 1,
    //   ahd=OffsetParam.ahd*1,chd=OffsetParam.chd*1,cwd=OffsetParam.cwd*1,cwdd=OffsetParam.cwdd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa * 1, ya = param.ya * 1, xahx = param.xahx * 1, xahy = param.xahy * 1, xawx = param.xawx * 1, xawy = param.xawy * 1,
    xc = param.xc * 1, yc = param.yc * 1, xcwx = param.xcwx * 1, xcwy = param.xcwy * 1, xchx = param.xchx * 1, xchy = param.xchy * 1,
    xg = param.xg * 1, yg = param.yg * 1, xgwx = param.xgwx * 1, xgwy = param.xgwy * 1, xghx = param.xghx * 1, xghy = param.xghy * 1,
    xi = param.xi * 1, yi = param.yi * 1, xiwx = param.xiwx * 1, xiwy = param.xiwy * 1, xihx = param.xihx * 1, xihy = param.xihy * 1,
    ha= param.ha,wa=param.wa,hc=param.hc,wc=param.wc,hg=param.hg,wg=param.wg,hi=param.hi,wi=param.wi,
    Ra = param.ra,Rc = param.rc,Rg = param.rg,Ri = param.ri,rda=Ra-(Ra/Math.sqrt(2)),rdc=Rc-(Rc/Math.sqrt(2)),
      rdg=Rg-(Rg/Math.sqrt(2)),rdi=Ri-(Ri/Math.sqrt(2)),
    h = H * 1 * myZoom,
    h1 = H1 * 1 * myZoom,
    h2 = H2 * 1 * myZoom,
    w = W * 1 * myZoom,
    w1 = W1 * 1 * myZoom,
    w2 = W2 * 1 * myZoom,


    rra = RA * 1 * myZoom,
    rrc = RC * 1 * myZoom,
    rrg = RG * 1 * myZoom,
    rri = RI * 1 * myZoom,

    // ha = HA * 1 * myZoom,
    // wa = WA * 1 * myZoom,
    // hc = HC * 1 * myZoom,
    // wc = WC * 1 * myZoom,
    // hg = HG * 1 * myZoom,
    // wg = WG * 1 * myZoom,
    // hi = HI * 1 * myZoom,
    // wi = WI * 1 * myZoom,

    hh = HH * 1 * myZoom,
    ww = WW * 1 * myZoom;
    if (rra !== 0) {var TxtRa="R="+RA.toString();}
    if (rra !== 0) {var TxtRa="R="+RA.toString();}
    if (rrc !== 0) {var TxtRc="R="+RC.toString();} 
    if (rrg !== 0) {var TxtRg="R="+RG.toString();}
    if (rri !== 0) {var TxtRi="R="+RI.toString();}  
    if (ha !== 0) {var TxtHa=ha.toString();} 
    if (wa !== 0) {var TxtWa=wa.toString();}   
    if (hc !== 0) {var TxtHc=hc.toString();} 
    if (wc !== 0) {var TxtWc=wc.toString();}   
    if (hg !== 0) {var TxtHg=hg.toString();} 
    if (wg !== 0) {var TxtWg=wg.toString();}   
    if (hi !== 0) {var TxtHi=hi.toString();} 
    if (wi !== 0) {var TxtWi=wi.toString();}  

  if (area == "A") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    };      
    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
    
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
    }

  } else if (area == "C") {
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };      
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字  
    var markers = {
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
    }
    // var myCornerMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };      
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字  
    var markers = {
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
    }
  } else if (area == "I") {
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    };      
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字  
    var markers = {
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
    // var myCornerMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    };   
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };       
    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字
    makerjs.model.addCaption(myCornerMarkA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字      
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字 
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字 
   var markers = {
    myCornerMarkA,
    myCornerMarkWA,
    myCornerMarkHA,
    myCornerMarkWC,
    myCornerMarkHC,
    myCornerMarkRC,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    }; 
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };

    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
    
          
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
         
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
    }
  } else if (area == "AI") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    }; 
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 

    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
         
   
         
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
  } else if (area == "CG") {
   
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };
    
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字
          
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
            
    //////////********************* */
    var markers = {
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
    }
  } else if (area == "CI") {
  
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 

    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字
     
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
  } else if (area == "GI") {
  
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 

          
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
         
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
  } else if (area == "ACG") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    };  
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };

    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
         
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字
          
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
      
    
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
    }
  } else if (area == "ACI") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    };  
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 

    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
         
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字
     
         
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
  } else if (area == "AGI") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    }; 
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 

    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
         
    
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
         
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
  } else if (area == "CGI") {
   
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 
      
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字
          
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
         
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }  
  } else if (area == "ACGI") {
    var myCornerMarkRA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + wa - Ra, ya - ha + Ra], [xa + wa - rda, ya - ha + rda]),//左上角
      }
    };
    var myCornerMarkWA = {
      paths: {
        "LineS": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa, ya - ha - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xa , ya - ha -markShift], [xa + wa , ya- ha - markShift]),//横线
      }
    };
    var myCornerMarkHA = {
      paths: {
        "LineH": new makerjs.paths.Line([xa + wa, ya - ha], [xa + wa+markShiftFour, ya - ha]),//横线
        "LineS": new makerjs.paths.Line([xa+ wa+markShift , ya - ha], [xa+ wa+markShift , ya]),//横线
      }
    };  
    var myCornerMarkRC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-wc+rdc, yc-hc+rdc], [xc-wc+Rc, yc-hc+Rc]),//左上角
      }
    }; 
    var myCornerMarkWC = {
      paths: {
        "LineS": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc, yc - hc - markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xc , yc - hc -markShift], [xc - wc , yc- hc - markShift]),//横线
      }
    };
    var myCornerMarkHC = {
      paths: {
        "LineH": new makerjs.paths.Line([xc - wc, yc - hc], [xc - wc-markShiftFour, yc - hc]),//横线
        "LineS": new makerjs.paths.Line([xc- wc-markShift , yc - hc], [xc- wc-markShift , yc]),//横线
      }
    };
    var myCornerMarkRG = {
      paths: {
        "Line": new makerjs.paths.Line([wg-Rg, hg-Rg],[wg-rdg, hg-rdg]),//左上角
      }
    };
    var myCornerMarkWG = {
      paths: {
        "LineS": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg, yg + hg + markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xg , yg + hg +markShift], [xg + wg , yg+ hg+ markShift]),//横线
      }
    };
    var myCornerMarkHG = {
      paths: {
        "LineH": new makerjs.paths.Line([xg + wg, yg + hg], [xg + wg+markShiftFour, yg + hg]),//横线
        "LineS": new makerjs.paths.Line([xg+ wg+markShift , yg ], [xg+ wg+markShift , yg+ hg]),//横线
      }
    };
    var myCornerMarkRI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-wi+rdi, hi-rdi], [xi-wi+Ri, hi-Ri]),//左上角
      }
    }; 
    var myCornerMarkWI = {
      paths: {
        "LineS": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi, yi + hi+ markShiftFour]),//横线
        "LineH": new makerjs.paths.Line([xi , yi + hi +markShift], [xi - wi , yi+ hi + markShift]),//横线
      }
    };
    var myCornerMarkHI = {
      paths: {
        "LineH": new makerjs.paths.Line([xi - wi, yi + hi], [xi - wi-markShiftFour, yi + hi]),//横线
        "LineS": new makerjs.paths.Line([xi- wi-markShift , yi ], [xi- wi-markShift , yi+ hi]),//横线
      }
    }; 

    makerjs.model.addCaption(myCornerMarkWA, TxtWa, [xa , ya - ha -markShiftThree], [xa + wa , ya - ha- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHA, TxtHa, [xa+ wa+markShiftThree , ya - ha], [xa+ wa+markShiftThree , ya]);//H文字   
    makerjs.model.addCaption(myCornerMarkRA, TxtRa, [xa + wa - Ra - markShiftTwo * 2, ya - ha + Ra + markShiftTwo], [xa + wa - Ra, ya - ha + Ra + markShiftTwo]);//半径文字
         
    makerjs.model.addCaption(myCornerMarkWC, TxtWc, [xc-wc , yc - hc -markShiftThree], [xc , yc - hc- markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHC, TxtHc, [xc- wc-markShiftThree , yc - hc], [xc- wc-markShiftThree , yc]);//H文字   
    makerjs.model.addCaption(myCornerMarkRC, TxtRc, [xc-wc+Rc, yc-hc+Rc],[xc-wc+Rc+markShiftTwo * 2, yc-hc+Rc]);//半径文字
          
    makerjs.model.addCaption(myCornerMarkWG, TxtWg, [xg , yg + hg +markShiftThree], [xg + wg , yg + hg+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHG, TxtHg, [xg+ wg+markShiftThree , yg], [xg+ wg+markShiftThree , yg+ hg]);//H文字    
    makerjs.model.addCaption(myCornerMarkRG, TxtRg, [wg-Rg- markShiftTwo * 2, hg-Rg],[wg-Rg, hg-Rg]);//半径文字 
         
    makerjs.model.addCaption(myCornerMarkWI, TxtWi, [xi-wi , yi + hi +markShiftThree], [xi , yi + hi+ markShiftThree]);//W文字
    makerjs.model.addCaption(myCornerMarkHI, TxtHi, [xi- wi-markShiftThree , yi], [xi- wi-markShiftThree , yi + hi]);//H文字    
    makerjs.model.addCaption(myCornerMarkRI, TxtRi, [xi-wi+Ri, hi-Ri], [xi-wi+Ri+markShiftTwo*2, hi-Ri]);//半径文字    
    //////////********************* */
    var markers = {
      myCornerMarkRA,
      myCornerMarkWA,
      myCornerMarkHA,
      myCornerMarkRC,
      myCornerMarkWC,
      myCornerMarkHC,
      myCornerMarkRG,
      myCornerMarkWG,
      myCornerMarkHG,
      myCornerMarkWI,
      myCornerMarkHI,
      myCornerMarkRI,
    }
  }
  return { markers };
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Corner(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      R = R * 1 * myZoom,
      rd = R / Math.sqrt(2),
      rMark = R - rd,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }

    if (r !== 0) {
      var TxtR = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  } else {
    var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      r = R * 1 * myZoom,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }

    if (r !== 0) {
      var TxtR = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }

  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****")
    console.log("glassW||||", glassW)
    console.log("glassH||||", glassH)
    console.log("glassH||||----R", R)

    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
      awd = 0, ahd = 0, cwd = 0, cwdd = 0, chd = 0, iwd = 0, iwdd = 0, ihd = 0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      rawx = xa + w - R, rawy = ya - h,
      rahx = xa + w, rahy = ya - h + R,
      //C区域
      xc = glassW * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc, xchy = yc - h,//C位置角H坐标 
      rcwx = xc - w+ R, rcwy = yc - h,
      rchx = xc - w, rchy = yc - h + R,

      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      rgwx = xg + w - R, rgwy = yg + h,
      rghx = xg + w, rghy = yg + h - R,

      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0,
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi, xihy = yi + h,//I位置角H坐标
      riwx = xi - w + R, riwy = yi + h,
      rihx = xi - w, rihy = yi + h - R;

    var OffsetParam = { rMark };
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),
      cwd = awd,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - awd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: awd, iwdd: 0, ihd: 0 };
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa + w, xawy = ya + ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc - ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd, cwd: iwd, cwdd: 0, chd: 0, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移


      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa + w, xawy = ya - ahd,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc + ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: iwd, cwdd: 0, chd: ahd, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: cwd, iwdd: 0, ihd: 0 };
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + awd, xihy = yi + h;//I位置角H坐标

    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: awd, ihd: 0 };

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: cwd, ihd: 0 };

  }

  if(inputParam.area===undefined){
    var ObliqueAngleData=showArea(inputParam,outParam);
    var paramCoordinate =ObliqueAngleData[1];
    var areaPoint =ObliqueAngleData[0];
    var whMarksParam=ObliqueAngleData[2];
  }else{
// 坐标参数
var paramCoordinate = { R, xa, ya, xawx, xawy, xahx, xahy, rawx, rawy, rahx, rahy, xc, yc, xcwx, xcwy, xchx, xchy, rcwx, rcwy, rchx, rchy, xg, yg, xgwx, xgwy, xghx, xghy, rgwx, rgwy, rghx, rghy, xi, yi, xiwx, xiwy, xihx, xihy, riwx, riwy, rihx, rihy };
const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
var areaPoint = "";
console.log("//画1、斜角 ------", allKeys)
if (allKeys[0] == frame) {
  allKeys.shift();//去掉数组第一个元素
}
console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
if (allKeys.length == 0) {
  switch (area) {
    case "1": areaPoint = "A"; break;
    case "3": areaPoint = "C"; break;
    case "7": areaPoint = "G"; break;
    case "9": areaPoint = "I"; break;
  }
} else {
  switch (area) {
    case "1": areaPoint = getStrs("A", allKeys); break;
    case "3": areaPoint = getStrs("C", allKeys); break;
    case "7": areaPoint = getStrs("G", allKeys); break;
    case "9": areaPoint = getStrs("I", allKeys); break;
  }
}
  }

  
  /**
  * 画图
  * 
  */
  var ObjName = "myCorner" + areaPoint;
  var ObjNameM = "myCorner" + areaPoint + "Mark";//外框标记
  var ObjNameMCorner = "myCorner" + areaPoint + "MarkCorner";//角标记
  var ObjNameO = "myCorner" + areaPoint + "Obj";
  ObjName = creatCorner(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMCorner = creatCornerMark(outParam, areaPoint, paramCoordinate, whMarksParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMCorner===|||||||||角标注", ObjNameMCorner);
  ObjNameO = { ObjName };
  ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
  cutPlate.models = {};
  plate.models = {};
  markers.models = {};
  cutPlate.models["myCorner" + areaPoint] = ObjName;
  plate.models["myCorner" + areaPoint] = ObjName;

  console.log("ObjNameM.markers.models===|||||||||是否有所有标注", ObjNameM.markers.models);
  console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMCorner.markers);


  markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMCorner.markers };

  console.log("markers===|||||||||是否有所有标注", markers);
  
  myHolesNum++;
  return { plate, cutPlate, markers, myHolesNum }
};


export default Corner;