//角1、斜角
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
//输入数字返回区域字母
function changeArea(num){
  var areaPoint;
  switch(num){
 case "1": areaPoint = "A";break;
 case "2": areaPoint = "B";break;
 case "3": areaPoint = "C";break;
 case "4": areaPoint = "D";break;
 case "5": areaPoint = "E";break;
 case "6": areaPoint = "F";break;
 case "7": areaPoint = "G";break;
 case "8": areaPoint = "H";break;
 case "9": areaPoint = "I";break;
}
return areaPoint;
};
function showArea(data,outParam){
  console.log("data|||ObliqueAngle%%%%%%%88888||||showArea====",data);
  console.log("data|||ObliqueAngle%%%%%%%88888||||outParam====",outParam);
  var x=outParam.glassW*1,y=outParam.glassH*1;
  var properNum=objHasOwnProperty(data);
  console.log("properNum===",properNum);
  var arrData=[];
  var arrArea=[];
  var arrAreaNum=[];
  for (let key in data) {
          if (data.hasOwnProperty(key)) {
            
            if (key !== "Rectangle") {
              console.log("data=", data);
            console.log("测试key=", key);
            console.log("data[key].shape=", data[key].shape);
            console.log("data[key].area=", data[key].area);
            arrData.push(data[key]);
            arrArea.push(changeArea(data[key].area));
            arrAreaNum.push(data[key].area);
            }         
          }
        }
    
       var xa = 0, ya = y,xc = x , yc = y ,xg = 0, yg = 0,xi = x , yi = 0,
       xawx=0,xawy=0,xahx=0,xahy=0,
       xcwx=0,xcwy=0,xchx=0,xchy=0,
       xgwx=0,xgwy=0,xghx=0,xghy=0,
       xiwx=0,xiwy=0,xihx=0,xihy=0;
       console.log("xawx",xawx);
      
  for(var i = 0; i < arrAreaNum.length; i++){
    var areaNums=arrAreaNum[i];
    var w=arrData[i].W*1,h=arrData[i].H*1;
    switch(areaNums){
       case "1":   
       console.log("switch objA.xawx",xawx);
       xawx = xa+w, xawy = ya,//A位置角W坐标
        xahx = xa, xahy = ya - h;//A位置角H坐标;
        break;
       case "3":
       xcwx = xc - w, xcwy = yc,//C位置角W坐标
        xchx = xc, xchy = yc - h;//C位置角H坐标;
       break;
       case "7":
       xgwx = xg + w, xgwy = yg,//G位置角W坐标
        xghx = xg, xghy = yg + h;//G位置角H坐标;
       break;
       case "9": 
       xiwx = xi - w, xiwy = yi,//I位置角W坐标
        xihx = xi, xihy = yi + h;//I位置角H坐标;
       break;
     }
  }  
        arrArea=arrArea.sort();
        arrArea=arrArea.join("");
       var paramCoordinate = { xa, ya, xawx, xawy, xahx, xahy, xc, yc, xcwx, xcwy, xchx, xchy, xg, yg, xgwx, xgwy, xghx, xghy, xi, yi, xiwx, xiwy, xihx, xihy }
        console.log("showArea===arrData=",arrData);
        console.log("showArea===arrArea=",arrArea);
        console.log("paramCoordinate===arrArea=",paramCoordinate);
        var objData=[arrArea,paramCoordinate];
  return objData;
      };
function objHasOwnProperty(obj) {
        var count = 0;
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            count++;
          }
        }
        return count;
      };
// 矩形等去角 连线函数
function creatCorner(area, param) {
  console.log("是否调用此函数creatOneCorner()");
  var xa = param.xa, ya = param.ya, xahx = param.xahx, xahy = param.xahy, xawx = param.xawx, xawy = param.xawy,
    xc = param.xc, yc = param.yc, xcwx = param.xcwx, xcwy = param.xcwy, xchx = param.xchx, xchy = param.xchy,
    xg = param.xg, yg = param.yg, xgwx = param.xgwx, xgwy = param.xgwy, xghx = param.xghx, xghy = param.xghy,
    xi = param.xi, yi = param.yi, xiwx = param.xiwx, xiwy = param.xiwy, xihx = param.xihx, xihy = param.xihy;
  if (area == "A") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myOaAngle = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return myOaAngle;
};
// 矩形等去角标记 连线函数
function creatCornerMark(outParam,area, param,inputParam,fixedParam,OffsetParam,zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param",param);
  console.log("是否调用此函数OffsetParam",OffsetParam);
  var HH=inputParam.H,WW=inputParam.W,H = outParam.H, H1 = outParam.H1, H2 = outParam.H2, W = outParam.W, W1 = outParam.W1, W2 = outParam.W2,
  markShiftTwo = fixedParam.markShiftTwo*1,myZoom = zoom * 1,
    awd=OffsetParam.awd*1,ahd=OffsetParam.ahd*1,chd=OffsetParam.chd*1,cwd=OffsetParam.cwd*1,cwdd=OffsetParam.cwdd*1,
    ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa*1, ya = param.ya*1, xahx = param.xahx*1, xahy = param.xahy*1, xawx = param.xawx*1, xawy = param.xawy*1,
    xc = param.xc*1, yc = param.yc*1, xcwx = param.xcwx*1, xcwy = param.xcwy*1, xchx = param.xchx*1, xchy = param.xchy*1,
    xg = param.xg*1, yg = param.yg*1, xgwx = param.xgwx*1, xgwy = param.xgwy*1, xghx = param.xghx*1, xghy = param.xghy*1,
    xi = param.xi*1, yi = param.yi*1, xiwx = param.xiwx*1, xiwy = param.xiwy*1, xihx = param.xihx*1, xihy = param.xihy*1,
    h = H * 1 * myZoom,
        h1 = H1 * 1 * myZoom,
        h2 = H2 * 1 * myZoom,
        w = W * 1 * myZoom,
        w1 = W1 * 1 * myZoom,
        w2 = W2 * 1 * myZoom,
        
    hh = HH * 1 * myZoom,
    ww = WW * 1 * myZoom;
  if (hh !== 0) {
    var TxtH = HH;
} 
if (ww !== 0) {
    var TxtW = WW;
}
  if (area == "A") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
}
   
  } else if (area == "C") {    
    var myOaAngleMarkCRight = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
        "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
      }
  };
  var myOaAngleMarkCTop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
    }
};
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//宽度文字
var markers = {
  myOaAngleMarkCRight,
      myOaAngleMarkCTop,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myOaAngleMarkGLeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
        "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
        "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
      }
  };
  var myOaAngleMarkGBottom = {
    paths: {
      "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
      "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
      "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
    }
};
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//宽度文字
var markers = {
  myOaAngleMarkGLeft,
  myOaAngleMarkGBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myOaAngleMarkIRight = {
      paths: {
        "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
        "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
        "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
      }
  };
  var myOaAngleMarkIBottom = {
    paths: {
      "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
      "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
      "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
    }
};
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//宽度文字
var markers = {
  myOaAngleMarkIRight,
  myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
}; 
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
      myOaAngleMarkCRight,
     myOaAngleMarkCTop,
}  
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {

var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
}; 
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
var markers = {
      myOaAngleMarkCRight,
        myOaAngleMarkCTop,
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
}
   
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
   
var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
}; 
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
      myOaAngleMarkCRight,
        myOaAngleMarkCTop,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
 
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
}; 
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
      myOaAngleMarkCRight,
        myOaAngleMarkCTop,
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
};
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
      myOaAngleMarkCRight,
        myOaAngleMarkCTop,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    
var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
}; 
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
      myOaAngleMarkCRight,
        myOaAngleMarkCTop,
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myOaAngleMarkALeft = {
      paths: {
        "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, xahy], [xg-markShiftTwo, ya]),//左上角
        "LeftShortUpline": new makerjs.paths.Line([xa, ya], [xg - 2 * markShiftTwo, ya]),
        "LeftShortDnline": new makerjs.paths.Line([xahx, xahy], [xg - 2 * markShiftTwo, xahy]),
      }
  };
  var myOaAngleMarkATop = {
    paths: {
      "ATopLine": new makerjs.paths.Line([xa, ya+ahd+markShiftTwo], [xawx, ya+ahd+markShiftTwo]),//左上角 上
      "ATopShortLfline": new makerjs.paths.Line([xa, ya], [xa, ya+ahd+ 2 * markShiftTwo]),
      "ATopShortRtline": new makerjs.paths.Line([xawx, xawy], [xawx, ya+ahd+2*markShiftTwo]),
    }
};
var myOaAngleMarkCRight = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xchx+cwdd+markShiftTwo, xchy], [xchx+cwdd+markShiftTwo, yc]),//左上角
    "LeftShortUpline": new makerjs.paths.Line([xc, yc], [xc+cwd+markShiftTwo*2, yc]),
    "LeftShortDnline": new makerjs.paths.Line([xchx, xchy], [xchx+cwdd + 2 * markShiftTwo, xchy]),
  }
};
var myOaAngleMarkCTop = {
paths: {
  "ATopLine": new makerjs.paths.Line([xcwx, yc+markShiftTwo], [xc, yc+markShiftTwo]),//左上角 上
  "ATopShortLfline": new makerjs.paths.Line([xcwx, xcwy], [xcwx, yc+chd+ 2 * markShiftTwo]),
  "ATopShortRtline": new makerjs.paths.Line([xc, yc], [xc, yc+chd+2*markShiftTwo]),
}
}; 
var myOaAngleMarkGLeft = {
  paths: {
    "ALeftLine": new makerjs.paths.Line([xg-markShiftTwo, yg], [xg-markShiftTwo, xghy]),//左下角左
    "LeftShortUpline": new makerjs.paths.Line([xghx, xghy], [xg - 2 * markShiftTwo, xghy]),
    "LeftShortDnline": new makerjs.paths.Line([xg, xg], [xg - 2 * markShiftTwo, xg]),
  }
};
var myOaAngleMarkGBottom = {
paths: {
  "ABottomLine": new makerjs.paths.Line([xg, yg-markShiftTwo], [xgwx, yg-markShiftTwo]),//左下角 下
  "ABottomShortLfline": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftTwo*2]),
  "ABottomShortRtline": new makerjs.paths.Line([xgwx, xgwy], [xgwx, yg-markShiftTwo*2]),
}
};
var myOaAngleMarkIRight = {
  paths: {
    "IRightLine": new makerjs.paths.Line([xi+iwdd+markShiftTwo, yi], [xihx+iwd+markShiftTwo,xihy]),//左下角左
    "IRightShortUpline": new makerjs.paths.Line([xihx, xihy], [xihx+iwd+ 2 * markShiftTwo, xihy]),
    "IRightShortDnline": new makerjs.paths.Line([xi, yi], [xi+iwdd+ 2 * markShiftTwo, yi]),
  }
};
var myOaAngleMarkIBottom = {
paths: {
  "IBottomLine": new makerjs.paths.Line([xiwx, yi-markShiftTwo], [xi, yi-markShiftTwo]),//左下角 下
  "IABottomShortLfline": new makerjs.paths.Line([xiwx,yi], [xiwx,yi-markShiftTwo*2]),
  "IBottomShortRtline": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftTwo*2]),
}
};
makerjs.model.addCaption(myOaAngleMarkALeft, TxtH, [xg-2*markShiftTwo, xahy], [xg-2*markShiftTwo, ya]);//A长度文字  
makerjs.model.addCaption(myOaAngleMarkATop, TxtW, [xa, ya+ahd+2*markShiftTwo], [xawx, ya+ahd+2*markShiftTwo]);//A宽度文字
makerjs.model.addCaption(myOaAngleMarkCRight, TxtH, [xchx+cwdd+markShiftTwo*2, xchy], [xchx+cwdd+markShiftTwo*2, yc]);//C长度文字  
makerjs.model.addCaption(myOaAngleMarkCTop, TxtW, [xcwx, yc+markShiftTwo*2], [xc, yc+markShiftTwo*2]);//C宽度文字
makerjs.model.addCaption(myOaAngleMarkGLeft, TxtH, [xg-markShiftTwo*2, yg], [xg-markShiftTwo*2, xghy]);//G长度文字  
makerjs.model.addCaption(myOaAngleMarkGBottom, TxtW, [xg, yg-markShiftTwo*2], [xgwx, yg-markShiftTwo*2]);//G宽度文字
makerjs.model.addCaption(myOaAngleMarkIRight, TxtH, [xi+iwdd+markShiftTwo*2, yi], [xihx+iwd+markShiftTwo*2,xihy]);//I长度文字  
makerjs.model.addCaption(myOaAngleMarkIBottom, TxtW, [xiwx, yi-markShiftTwo*2], [xi, yi-markShiftTwo*2]);//I宽度文字
var markers = {
      myOaAngleMarkALeft,
      myOaAngleMarkATop,
      myOaAngleMarkCRight,
        myOaAngleMarkCTop,
        myOaAngleMarkGLeft,
        myOaAngleMarkGBottom,
        myOaAngleMarkIRight,
        myOaAngleMarkIBottom,
}
    // var myOaAngleMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return {markers};
};
function ObliqueAngle(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut,frame){
 
  if (typeof (frame) == "undefined") {
    var H = inputParam.H, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      clickArea=[],
      h = H * 1 * myZoom,
      w = W * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      
   
      if(plate.models===undefined||plate.models===""){
        console.log("undefinedunde**************！！！！！！！！！！@@@@@@@@@@@@@@@@");
        console.log("model==", model);
  console.log("outParam==", outParam);
  console.log("inputParam==", inputParam);
        plate.models={};
                }        
      if(cutPlate.models===undefined||cutPlate.models===""){
        console.log("22222222222");
        cutPlate.models={};
                }
      if(markers.models===undefined||markers.models===""){            
        console.log("333333333333");
        markers.models={};
                }
 
  } else {
    var H = inputParam.H, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.W, glassH = outParam.H, glassW1 = outParam.W1, glassW2 = outParam.W2, glassH1 = outParam.H1, glassH2 = outParam.H2,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      clickArea=[],
      h = H * 1 * myZoom,
      w = W * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
      if(plate.models===undefined||plate.models===""){
        console.log("1111111111111111");
        plate.models={};
                }        
                if(cutPlate.models===undefined||cutPlate.models===""){
                    console.log("22222222222");
                    //cutPlate.models={};
                    cutPlate.models={};
                }
                if(markers.models===undefined||markers.models===""){            
                    console.log("333333333333");
                    markers.models={};
                }
    /*
    h = H*1,
    w= W*1,
      x0 = X*1,
      y0 = Y*1;
      */
    if (h !== 0) {
      var TxtH = H;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }


 if(frame=="Rectangle"){ 
  var awd=0,ahd=0,cwd=0,cwdd=0,chd=0,iwd=0,iwdd=0,ihd=0;
    var OffsetParam={awd,ahd,cwd,cwdd,chd,iwd,iwdd,ihd};
  plate.models["Rectangle"]= new makerjs.models.Rectangle(glassW*1, glassH*1);  
  cutPlate.models["Rectangle"]= new makerjs.models.Rectangle(glassW*1, glassH*1);  

}else if(frame=="IsoscelesTrapezoid"){ 
      //在A区域 计算孔的位置
      var xwd=(glassW * 1-glassW1 * 1)/2, 
           
 xa=x0+xwd,ya=glassH * 1-y0,
 xa1=xwd*h/(glassH * 1),
 
  //B区域//只有Y标注没有X标注 
  //xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-xwd-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  //xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  //xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  //xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  //xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画1、斜角 A位置
    var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-5,ya+5],[(xa-xa1)-5,ya-h],[(xa-xa1),ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models[area]=myOaAngleA;
markers.models[area]=myOaAngleA;
markers.models["myOaAngleAMakerW"]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models[area]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 
  
    }else if(area==3){
      
     //画1、斜角 C位置
     var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+xa1+5,yc-h],[xc+xa1,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models[area]=myOaAngleC;
markers.models[area]=myOaAngleC;
markers.models["myOaAngleCMakerW"]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models[area])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
    }else if(area==7){

      //画1、斜角 G位置
     var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xa1,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"]=myOaAngleG;
markers.models["myOaAngleG"]=myOaAngleG;
markers.models["myOaAngleGMakerW"]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models["myOaAngleG"])

console.log("markers==",markers);
/*****************************************************************************/ 

      
    }else if(area==9){
     
       //画1、斜角 I位置
     var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-xa1),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"]=myOaAngleI;
markers.models["myOaAngleI"]=myOaAngleI;
markers.models["myOaAngleIMakerW"]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.IsoscelesTrapezoid, markers.models["myOaAngleI"])

console.log("markers==",markers);
/*****************************************************************************/ 

    }
}else if(frame=="RightAngledTrapezoid"){  
    //在A区域 计算孔的位置
    var  yhd=glassH * 1-glassH1 * 1,xwd=glassW * 1-glassW1 * 1,
    ahd=yhd*w/(glassW1*1),//Y方向偏移
    awd=((glassW1*1)-w)*yhd/(glassH*1), //X方向偏移
    iwd=xwd*h/(glassH*1), //X方向偏移
         
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0,ya=glassH * 1-y0-yhd,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1-xwd-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
  //画1、斜角 A位置
  var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-5,ya+5],[(xa-5),ya-h],[xa,ya-h],[xa+w,ya+ahd],[xa+w,ya+ahd+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

  }else if(area==3){
    
   //画1、斜角 C位置
   var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc-ahd],[xc-w,yc+5],[xc+5,yc+5],[xc+iwd+5,yc-h],[xc+iwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

  }else if(area==7){

    //画1、斜角 G位置
   var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

    
  }else if(area==9){
   
     //画1、斜角 I位置
   var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-iwd),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRATrapezoid, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

  }
}else if(frame=="OtherTrapezoidsA"){  
  //在A区域 计算孔的位置
  var  yhd=glassH1 * 1-glassH2 * 1,
  ahd=yhd*w/((glassW*1)-(glassW1*1)-(glassW2*1)),//Y方向偏移
  awd=(glassW1*1)*h/(glassH1*1), //X方向偏移 A 
  iwd=(glassW2*1)*h/(glassH2*1), //X方向偏移
  gwd=(glassW1*1)*h/(glassH1*1), //X方向偏移
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0+glassW1 * 1,ya=glassH1 * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1-glassW2 * 1-x0,yc=glassH2 * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya-ahd],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc+ahd],[xc-w,yc+ahd+5],[xc+5,yc+ahd+5],[xc+iwd+5,yc-h],[xc+iwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+gwd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-iwd),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidA, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}else if(frame=="OtherTrapezoidsB"){
  //在A区域 计算孔的位置
  var  yhd=glassH1 * 1-glassH2 * 1,
  ahd=yhd*w/((glassW*1)-(glassW1*1)-(glassW2*1)),//Y方向偏移
  awd=(glassW1*1)*h/(glassH*1), //X方向偏移 A 
  cwd=(glassW2*1)*h/(glassH*1), //X方向偏移 A 
  iwd=(glassW2*1)*h/(glassH2*1), //X方向偏移
  gwd=(glassW1*1)*h/(glassH1*1), //X方向偏移
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0+glassW1 * 1,ya=glassH * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1-glassW2 * 1-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+cwd+5,yc+5],[xc+cwd+5,yc-h],[xc+cwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+awd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+5,yi-5],[xi+5,yi+h],[(xi-cwd),yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myOTrapezoidB, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}else if(frame=="ParallelogramA"){
  //在A区域 计算孔的位置
  var  awd=(glassW1*1)*h/(glassH*1), //X方向偏移 A
xa=x0+glassW1 * 1,ya=glassH * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1+glassW1 * 1-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+5,yc-h],[xc-awd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+awd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+awd+5,yi-5],[xi+awd+5,yi+h],[xi+awd,yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramA, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}else if(frame=="ParallelogramB"){
  console.log("markers.models=",markers.models)  
  //在A区域 计算孔的位置
  var  awd=(glassW1*1)*h/(glassH*1), //X方向偏移 A  G
       cwd=(glassW2*1)*h/(glassH*1), //X方向偏移 C  I  
//xa=x0+xwd,ya=glassH * 1-y0,
//xa1=xwd*h/(glassH * 1),
xa=x0+glassW1 * 1,ya=glassH * 1-y0,
//B区域//只有Y标注没有X标注 
//xb=glassW * 1/2,yb=glassH * 1-y0,
//C区域
xc=glassW * 1+glassW2 * 1-x0,yc=glassH * 1-y0,
//D区域
//只有X标注没有Y标注 
//xd=x0,yd=glassH * 1/2,
//E区域
//只有X标注没有Y标注 
//xe=glassW * 1/2,ye=glassH * 1/2,
//F区域
//只有X标注没有Y标注 
//xf=glassW * 1-x0,yf=glassH * 1/2,
//在G区域 计算孔的位置
xg=x0,yg=y0,
//在H区域 计算孔的位置
//只标注Y
//xh=glassW*1/2,yh=y0,
//在I区域 计算孔的位置
xi=glassW*1-x0,yi=y0;
if(area==1){
//画1、斜角 A位置
var myOaAngleA=new makerjs.models.ConnectTheDots(true,[[xa-awd-5,ya+5],[xa-awd-5,ya-h],[xa-awd,ya-h],[xa+w,ya],[xa+w,ya+5]]);
////标记1、斜角 A位置
var myOaAngleAMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerW.origin = [xa,ya];
var myOaAngleAMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleAMakerH.origin = [xa,ya];
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleAMakerW, TxtW, [0,markShiftThree], [0+w, markShiftThree]);//W文字标注
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
/*****************************************************************************/
plate.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleA"+myHolesNum]=myOaAngleA;
markers.models["myOaAngleAMakerW"+myHolesNum]=myOaAngleAMakerW;
markers.models["myOaAngleAMakerH"+myHolesNum]=myOaAngleAMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleA"+myHolesNum]);
console.log("markers==",markers);
/*****************************************************************************/ 
/*****************************************************************************/ 

}else if(area==3){
  
 //画1、斜角 C位置
 var myOaAngleC=new makerjs.models.ConnectTheDots(true,[[xc-w,yc],[xc-w,yc+5],[xc+5,yc+5],[xc+5,yc-h],[xc-cwd,yc-h]]);
////标记1、斜角 C位置
var myOaAngleCMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerW.origin = [xc,yc];
var myOaAngleCMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleCMakerH.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0], [markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
makerjs.model.addCaption(myOaAngleCMakerW, TxtW, [0-w,markShiftThree], [0, markShiftThree]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleCMakerH, new makerjs.paths.Line([0+markShift,0-h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleC"+myHolesNum]=myOaAngleC;
markers.models["myOaAngleCMakerW"+myHolesNum]=myOaAngleCMakerW;
markers.models["myOaAngleCMakerH"+myHolesNum]=myOaAngleCMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleC"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

}else if(area==7){

  //画1、斜角 G位置
 var myOaAngleG=new makerjs.models.ConnectTheDots(true,[[xg+awd,yg+h],[xg-5,yg+h],[xg-5,yg-5],[xg+w,yg-5],[xg+w,yg]]);
////标记1、斜角 G位置
var myOaAngleGMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerW.origin = [xg,yg];
var myOaAngleGMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleGMakerH.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0+w,0], [0+w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0], [-markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleG"+myHolesNum]=myOaAngleG;
markers.models["myOaAngleGMakerW"+myHolesNum]=myOaAngleGMakerW;
markers.models["myOaAngleGMakerH"+myHolesNum]=myOaAngleGMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleG"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

  
}else if(area==9){
 
   //画1、斜角 I位置
 var myOaAngleI=new makerjs.models.ConnectTheDots(true,[[xi-w,yi-5],[xi+cwd+5,yi-5],[xi+cwd+5,yi+h],[xi+cwd,yi+h],[xi-w,yi]]);
////标记1、斜角 I位置
var myOaAngleIMakerW = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerW.origin = [xi,yi];
var myOaAngleIMakerH = new makerjs.models.Square(0); //孔的原点       
myOaAngleIMakerH.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/ 
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0-w,0], [0-w, 0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour,0]));//X轴加长标线
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线
/*****************************************************************************/
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
makerjs.model.addCaption(myOaAngleIMakerW, TxtW, [0-w,-markShiftFour], [0, -markShiftFour]);//W文字标注 
/*****************************************************************************/  
makerjs.model.addPath(myOaAngleIMakerH, new makerjs.paths.Line([0+markShift,0+h], [0+markShift, 0]));//H短线标注
makerjs.model.addCaption(myOaAngleIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注
/*****************************************************************************/ 
plate.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleI"+myHolesNum]=myOaAngleI;
markers.models["myOaAngleIMakerW"+myHolesNum]=myOaAngleIMakerW;
markers.models["myOaAngleIMakerH"+myHolesNum]=myOaAngleIMakerH;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myParallelogramB, markers.models["myOaAngleI"+myHolesNum])
console.log("markers==",markers);
/*****************************************************************************/ 

}
}

// 坐标参数
var ObliqueAngleData=showArea(inputParam,outParam);
var paramCoordinate =ObliqueAngleData[1];
var areaPoint =ObliqueAngleData[0];
var ObjName = "myOaAngle" + areaPoint;
var ObjNameM = "myOaAngle" + areaPoint + "Mark";//外框标记
var ObjNameMCorner = "myOaAngle" + areaPoint + "MarkCorner";//角标记
var ObjNameO = "myOaAngle" + areaPoint + "Obj";
console.log("ObjNameO==",ObjNameO);
ObjName = creatCorner(areaPoint, paramCoordinate);//图形
console.log("ObjName===|||||||||", ObjName);
ObjNameMCorner = creatCornerMark(outParam,areaPoint, paramCoordinate,inputParam,fixedParam,OffsetParam,zoom);//图形
console.log("ObjNameMCorner===|||||||||角标注", ObjNameMCorner);
ObjNameO = { ObjName };
ObjNameM = RectangleMark(outParam, model, fixedParam, zoom,frame);//外框标记
cutPlate.models= {};
plate.models = {};
markers.models= {};

cutPlate.models["myOaAngle" + areaPoint] = ObjName;
plate.models["myOaAngle" + areaPoint] = ObjName;

console.log("ObjNameM.markers.models===|||||||||是否有所有标注",ObjNameM.markers.models);
console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMCorner.markers);

markers.models = { ...ObjNameM.markers.models, ...ObjNameO,...ObjNameMCorner.markers};

console.log("markers.models====+++++++++++++++++++++++++--------------------|||||||||是否有所有标注", markers.models);
console.log("plate.models===+++++++++++++++++++++++++--------------------|||||||||是否有所有标注", plate.models);
console.log("cutPlate.models===+++++++++++++++++++++++++--------------------|||||||||是否有所有标注", cutPlate.models);



clickArea.push(area)
console.log("ObliqueAngle out==|||||*********",plate,markers,myHolesNum)
console.log("plate.models==|||||*********",plate.models)
const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(plate.models)));
console.log("allKeys==",allKeys.length);
// var obj=makerjs.model.combineSubtraction(plate.models.Rectangle, plate.models["myOaAngleA0"]);
// var objB=makerjs.model.combineSubtraction(obj.models.Rectangle, plate.models["myOaAngleG0"]);
// console.log("obj=",obj);
// console.log("objB=",objB);
myHolesNum++;
return{plate,cutPlate, markers,myHolesNum} 
};


export default ObliqueAngle;