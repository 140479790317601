//角5、地弹簧
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
function changeArea(num){
  var areaPoint;
  switch(num){
 case "1": areaPoint = "A";break;
 case "2": areaPoint = "B";break;
 case "3": areaPoint = "C";break;
 case "4": areaPoint = "D";break;
 case "5": areaPoint = "E";break;
 case "6": areaPoint = "F";break;
 case "7": areaPoint = "G";break;
 case "8": areaPoint = "H";break;
 case "9": areaPoint = "I";break;
}
return areaPoint;
};
function showArea(data,outParam){ 
  console.log("data|||ObliqueAngle$$$$$$$$$$||||showArea====",data);
  var x=outParam.glassW*1,y=outParam.glassH*1;
  console.log("data==data.area===",data.area);
  console.log("data==typeof(data)===",typeof(data));
  console.log("data==data.length===",data.length);
  console.log("data[0]==data.length===",data[0]);
  console.log("data[1]==data.length===",data[1]);
//console.log("myObj=showArea=",obj);  
    var properNum=objHasOwnProperty(data);
    console.log("properNum===",properNum);
    var arrData=[];
    var arrArea=[];
    var arrAreaNum=[];
    for (let key in data) {
      console.log("测试key0=", key);
            if (data.hasOwnProperty(key)&& key!=="0") {              
              console.log("测试key0=", key);
              if (key !== "Rectangle") {
                console.log("myData=", data);
              console.log("测试key2=", key);
              console.log("data[key].shape=", data[key].shape);
              console.log("data[key].area=", data[key].area);            
              arrData.push(data[key]);
              arrArea.push(changeArea(data[key].area));
              arrAreaNum.push(data[key].area);
              }         
            }
          }    
          console.log("arrData=",arrData);     
         var xa = 0, ya = y,xc = x , yc = y ,xg = 0, yg = 0,xi = x , yi = 0,
         Aa=0,Ab=0,Aaa=0,Ahd=0,Ca=0,Cb=0,Caa=0,Chd=0,Ga=0,Gb=0,Gaa=0,Ghd=0,Ia=0,Ib=0,Iaa=0,Ihd=0,
         da=0,wa=0,w1a=0,w2a=0,ha=0,h1a=0,ra=0,r1a=0,r2a=0,
         dc=0,wc=0,w1c=0,w2c=0,hc=0,h1c=0,rc=0,r1c=0,r2c=0,
         dg=0,wg=0,w1g=0,w2g=0,hg=0,h1g=0,rg=0,r1g=0,r2g=0,
         di=0,wi=0,w1i=0,w2i=0,hi=0,h1i=0,ri=0,r1i=0,r2i=0,
         xawx1=0,xawy1=0,xawx2=0,xawy2=0,xawx3=0,xawy3=0,xawx4=0,xawy4=0,
         xcwx1=0,xcwy1=0,xcwx2=0,xcwy2=0,xcwx3=0,xcwy3=0,xcwx4=0,xcwy4=0,
         xgwx1=0,xgwy1=0,xgwx2=0,xgwy2=0,xgwx3=0,xgwy3=0,xgwx4=0,xgwy4=0,
         xiwx1=0,xiwy1=0,xiwx2=0,xiwy2=0,xiwx3=0,xiwy3=0,xiwx4=0,xiwy4=0,
         arrA=[],arrC=[],arrG=[],arrI=[];       
    for(var i = 0; i < arrAreaNum.length; i++){
      var areaNums=arrAreaNum[i];
      var r1=arrData[i].R1*1,r2=arrData[i].R2*1,d=arrData[i].D*1,r=d/2,w=arrData[i].W*1,w1=arrData[i].W1*1,w2=arrData[i].W2*1,h=arrData[i].H*1,h1=arrData[i].H1*1;
      switch(areaNums){
         case "1":   
        // console.log("switch objA.xawx",xawx);         
         arrA=CircleTwo(xa+w,ya+r1,xa+w2,ya-r2,r1,r2);
  Aa=arrA[0]*1,  Ab=arrA[1]*1,  Ahd=h-(r1-Ab),Aaa=x-Aa;
  da=d,wa=w,w1a=w1,w2a=w2,ha=h,h1a=h1,ra=r,r1a=r1,r2a=r2,
         xawx1 = xa, xawy1 =ya-h,//A位置角1坐标
      xawx2 = xa+w,xawy2 = ya-h,//A位置角2坐标
      xawx3 = xa+Aa,xawy3 = ya-Ahd,//A位置角3坐标
      xawx4 = xa+w2,xawy4 = ya;//A位置角4坐标
          break;
         case "3":        
         arrC=CircleTwo(xc-w,yc+r1,xc-w2,yc-r2,r1,r2);
         Ca=arrC[0]*1,  Cb=arrC[1]*1,  Chd=h-(r1-Cb),Caa=x-Ca;
         dc=d,wc=w,w1c=w1,w2c=w2,hc=h,h1c=h1,rc=r,r1c=r1,r2c=r2,
         xcwx1 = xc, xcwy1 =yc-h,//C位置角1坐标
         xcwx2 = xc-w,xcwy2 = yc-h,//C位置角2坐标
         xcwx3 = xc-Caa,xcwy3 = yc-Chd,//C位置角3坐标
         xcwx4 = xc-w2,xcwy4 = yc;//C位置角4坐标
         break;
         case "7":                  
         
  arrG=CircleTwo(xg+w,yg-r1,xg+w2,yg+r2,r1,r2);
  Ga=arrG[0]*1,  Gb=arrG[1]*1,  Ghd=h-(r1-Gb),Gaa=x-Ga,
  dg=d,wg=w,w1g=w1,w2g=w2,hg=h,h1g=h1,rg=r,r1g=r1,r2g=r2,
         xgwx1 = xg, xgwy1 =yg+h,//G位置角1坐标
      xgwx2 = xg+w,xgwy2 = yg+h,//G位置角2坐标
      xgwx3 = xg+Ga,xgwy3 = yg+Ghd,//G位置角3坐标
      xgwx4 = xg+w2,xgwy4 = yg;//G位置角4坐标
         break;
         case "9":            
        
  arrI=CircleTwo(xi-w,yi-r1,xi-w2,yi+r2,r1,r2); 
  Ia=arrI[0]*1,  Ib=arrI[1]*1,  Ihd=h-(r1-Ib),Iaa=x-Ia,//I位置角H坐标
  di=d,wi=w,w1i=w1,w2i=w2,hi=h,h1i=h1,ri=r,r1i=r1,r2i=r2,
         xiwx1 = xi, xiwy1 =yi+h,//I位置角1坐标
      xiwx2 = xi-w,xiwy2 = yi+h,//I位置角2坐标
      xiwx3 = xi-Iaa,xiwy3 = yi+Ihd,//I位置角3坐标
      xiwx4 = xi-w2,xiwy4 = yi;//I位置角4坐标 
         break;
       }
    }  
          arrArea=arrArea.sort();
          arrArea=arrArea.join("");
        var whMark={Aa,Aaa,Ahd,Ca,Caa,Chd,Ga,Gaa,Ghd,Ia,Iaa,Ihd};
         var paramCoordinate = {da,wa,w1a,w2a,ha,h1a,ra,r1a,r2a,dc,wc,w1c,w2c,hc,h1c,rc,r1c,r2c,dg,wg,w1g,w2g,hg,h1g,rg,r1g,r2g,di,wi,w1i,w2i,hi,h1i,ri,r1i,r2i, xa, ya, xawx1, xawy1,xawx2, xawy2,xawx3, xawy3,xawx4, xawy4, xc, yc, xcwx1, xcwy1, xcwx2,xcwy2,xcwx3,xcwy3,xcwx4,xcwy4, xg, yg, xgwx1, xgwy1, xgwx2, xgwy2,xgwx3, xgwy3,xgwx4, xgwy4, xi, yi, xiwx1, xiwy1, xiwx2, xiwy2, xiwx3, xiwy3, xiwx4, xiwy4 }
          console.log("showArea===arrData=",arrData);
          console.log("showArea===arrArea=",arrArea);
          console.log("paramCoordinate===arrArea=",paramCoordinate);
          console.log("whMark===whMark=",whMark);
          var objData=[arrArea,paramCoordinate,whMark]; 
  
  return objData;
      };
function objHasOwnProperty(obj) {
        var count = 0;
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            count++;
          }
        }
        return count;
      };
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(8);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatFloorSpring(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1,xawx2 = param.xawx2, xawy2 = param.xawy2,xawx3 = param.xawx3, xawy3 = param.xawy3,xawx4 = param.xawx4, xawy4 = param.xawy4,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1,xcwx2 = param.xcwx2, xcwy2 = param.xcwy2,xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,xcwx4 = param.xcwx4, xcwy4 = param.xcwy4,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2,xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,xgwx4 = param.xgwx4, xgwy4 = param.xgwy4,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2,xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,xiwx4 = param.xiwx4, xiwy4 = param.xiwy4,
    r = param.r,r1 = param.r1,r2 = param.r2,w = param.w,w1 = param.w1,w2 = param.w2,h = param.h,h1 = param.h1,
    da = param.da,ra = param.ra,r1a = param.r1a,r2a = param.r2a,wa = param.wa,w1a = param.w1a,w2a = param.w2a,ha = param.ha,h1a = param.h1a,
    dc = param.dc,rc = param.rc,r1c = param.r1c,r2c = param.r2c,wc = param.wc,w1c = param.w1c,w2c = param.w2c,hc = param.hc,h1c = param.h1c,
    dg = param.dg,rg = param.rg,r1g = param.r1g,r2g = param.r2g,wg = param.wg,w1g = param.w1g,w2g = param.w2g,hg = param.hg,h1g = param.h1g,
    di = param.di,ri = param.ri,r1i = param.r1i,r2i = param.r2i,wi= param.wi,w1i = param.w1i,w2i = param.w2i,hi = param.hi,h1i = param.h1i;
  if (area == "A") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
      }
    };

    //   var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true ),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true ),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myFloorSpring = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        // "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myFloorSpring = {
      paths: {        
        "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineI": new makerjs.paths.Line([xa, ya],[xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {        
        // "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineI": new makerjs.paths.Line([xa, ya],[xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),  
         "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),  
         "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], r),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myFloorSpring = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya],[xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),      
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx4, xgwy4]),      
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]), 
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]), 
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),  
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };

    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),  
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myFloorSpring = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx4, xcwy4]),   
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myFloorSpring = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    var myFloorSpringCut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1],[xawx2, xawy2]),   
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2],[xawx3, xawy3], r1a,false,false),//是否大弧,是否顺时针
        "TopRightArcA": new makerjs.paths.Arc([xawx3, xawy3],[xawx4, xawy4], r2a,false,true),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx4, xawy4], [xcwx4, xcwy4]),
        "TopLeftArcC": new makerjs.paths.Arc([xcwx4, xcwy4],[xcwx3, xcwy3], r2c,false,true),//是否大弧,是否顺时针
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3],[xcwx2, xcwy2], r1c,false,false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),   
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2],[xiwx3, xiwy3], r2i,false,false),//是否大弧,是否顺时针
        "BottomLeftArcI": new makerjs.paths.Arc([xiwx3, xiwy3],[xiwx4, xiwy4], r1i,false,true),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx4, xiwy4], [xgwx4, xgwy4]),   
        "BottomRigthArcG": new makerjs.paths.Arc([xgwx4, xgwy4],[xgwx3, xgwy3], r2g,false,true),//是否大弧,是否顺时针
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3],[xgwx2, xgwy2], r1g,false,false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa+w1a, ya-h1a], ra),
        "RightHolesC": new makerjs.paths.Circle([xc-w1c, yc-h1c], rc),
        "RightHolesG": new makerjs.paths.Circle([xg+w1g, yg+h1g], rg),
        "RightHolesI": new makerjs.paths.Circle([xi-w1i, yi+h1i], ri),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return [myFloorSpring,myFloorSpringCut];
};
// 矩形等去角标记 连线函数
function creatFloorSpringMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatFloorSpringMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  console.log("是否调用此函数fixedParam", fixedParam);
  var HH = outParam.H, WW = outParam.W, D = inputParam.D,H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, W2 = inputParam.W2, W2 = inputParam.W2, R = inputParam.R, R1 = inputParam.R1, R2 = inputParam.R2,
    markShiftTwo = fixedParam.markShiftTwo * 1, myZoom = zoom * 1,
    // rd = OffsetParam.rMark * 1,
      Aa=inputParam.Aa*1,Aaa=inputParam.Aaa*1,Ahd=inputParam.Ahd*1,
      Ca=inputParam.Ca*1,Caa=inputParam.Caa*1,Chd=inputParam.Chd*1,
      Ga=inputParam.Ga*1,Gaa=inputParam.Gaa*1,Ghd=inputParam.Ghd*1,
      Ia=inputParam.Ia*1,Iaa=inputParam.Iaa*1,Ihd=inputParam.Ihd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1,xawx2 = param.xawx2, xawy2 = param.xawy2,xawx3 = param.xawx3, xawy3 = param.xawy3,xawx4 = param.xawx4, xawy4 = param.xawy4,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1,xcwx2 = param.xcwx2, xcwy2 = param.xcwy2,xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,xcwx4 = param.xcwx4, xcwy4 = param.xcwy4,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2,xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,xgwx4 = param.xgwx4, xgwy4 = param.xgwy4,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2,xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,xiwx4 = param.xiwx4, xiwy4 = param.xiwy4,
    r = param.r,r1 = param.r1,r2 = param.r2,w = param.w,w1 = param.w1,w2 = param.w2,h = param.h,h1 = param.h1,
    da = param.da,ra = param.ra,r1a = param.r1a,r2a = param.r2a,wa = param.wa,w1a = param.w1a,w2a = param.w2a,ha = param.ha,h1a = param.h1a,
    dc = param.dc,rc = param.rc,r1c = param.r1c,r2c = param.r2c,wc = param.wc,w1c = param.w1c,w2c = param.w2c,hc = param.hc,h1c = param.h1c,
    dg = param.dg,rg = param.rg,r1g = param.r1g,r2g = param.r2g,wg = param.wg,w1g = param.w1g,w2g = param.w2g,hg = param.hg,h1g = param.h1g,
    di = param.di,ri = param.ri,r1i = param.r1i,r2i = param.r2i,wi= param.wi,w1i = param.w1i,w2i = param.w2i,hi = param.hi,h1i = param.h1i,    
    markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
    markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
    
    d = D * 1 * myZoom,
    h = H * 1 * myZoom,
    h1 = H1 * 1 * myZoom,
    w = W * 1 * myZoom,
    w1 = W1 * 1 * myZoom,
    w2 = W2 * 1 * myZoom,
    r = R * 1 * myZoom,
    r1 = R1 * 1 * myZoom,
    r2 = R2 * 1 * myZoom,

    hh = HH * 1 * myZoom,
    ww = WW * 1 * myZoom;
    if (r !== 0) {
      var TxtR = "R=" + R;
    }if (r1 !== 0) {
      var TxtR1 = "R1=" + R1;
    }if (r2 !== 0) {
      var TxtR2 = "R2=" + R2;
    }if (w !== 0) {
      var TxtW = "W=" + W;
    }if (w1 !== 0) {
      var TxtW1 = "W1=" + W1;
    }if (w2 !== 0) {
      var TxtW2 = "W2=" + W2;
    }if (h !== 0) {
      var TxtH = "H=" + H;
    }if (h1 !== 0) {
      var TxtH1 = "H1=" + H1;
    }if (d !== 0) {
      var TxtD = "D=" + D;
    }
    ///////////////////
    
    if (ra !== 0) {
      var TxtRa = "R=" + ra.toString();
    }if (r1a !== 0) {
      var TxtR1a = "R1=" + r1a.toString();
    }if (r2a !== 0) {
      var TxtR2a = "R2=" + r2a.toString();
    }if (wa !== 0) {
      var TxtWa = "W=" + wa.toString();
    }if (w1a !== 0) {
      var TxtW1a = "W1=" + w1a.toString();
    }if (w2a !== 0) {
      var TxtW2a = "W2=" + w2a.toString();
    }if (ha !== 0) {
      var TxtHa = "H=" + ha.toString();
    }if (h1a !== 0) {
      var TxtH1a = "H1=" + h1a.toString();
    }if (da !== 0) {
      var TxtDa = "D=" + da.toString();
    }

    //////////////////////
    
    if (rc !== 0) {
      var TxtRc = "R=" + rc.toString();
    }if (r1c !== 0) {
      var TxtR1c = "R1=" + r1c.toString();
    }if (r2c !== 0) {
      var TxtR2c = "R2=" + r2c.toString();
    }if (wc !== 0) {
      var TxtWc = "W=" + wc.toString();
    }if (w1c !== 0) {
      var TxtW1c = "W1=" + w1c.toString();
    }if (w2c !== 0) {
      var TxtW2c = "W2=" + w2c.toString();
    }if (hc !== 0) {
      var TxtHc = "H=" + hc.toString();
    }if (h1c !== 0) {
      var TxtH1c = "H1=" + h1c.toString();
    }if (dc !== 0) {
      var TxtDc = "D=" + dc.toString();
    }

    ////////////////////////
    
    if (rg !== 0) {
      var TxtRg = "R=" + rg.toString();
    }if (r1g !== 0) {
      var TxtR1g = "R1=" + r1g.toString();
    }if (r2g !== 0) {
      var TxtR2g = "R2=" + r2g.toString();
    }if (wg !== 0) {
      var TxtWg = "W=" + wg.toString();
    }if (w1g !== 0) {
      var TxtW1g = "W1=" + w1g.toString();
    }if (w2g !== 0) {
      var TxtW2g = "W2=" + w2g.toString();
    }if (hg !== 0) {
      var TxtHg = "H=" + hg.toString();
    }if (h1g !== 0) {
      var TxtH1g = "H1=" + h1g.toString();
    }if (dg !== 0) {
      var TxtDg = "D=" + dg.toString();
    }
    //////////////////////////////
    
    if (ri !== 0) {
      var TxtRi = "R=" + ri.toString();
    }if (r1i !== 0) {
      var TxtR1i = "R1=" + r1i.toString();
    }if (r2i !== 0) {
      var TxtR2i = "R2=" + r2i.toString();
    }if (wi !== 0) {
      var TxtWi = "W=" + wi.toString();
    }if (w1i !== 0) {
      var TxtW1i = "W1=" + w1i.toString();
    }if (w2i !== 0) {
      var TxtW2i = "W2=" + w2i.toString();
    }if (hi !== 0) {
      var TxtHi = "H=" + hi.toString();
    }if (h1i !== 0) {
      var TxtH1i = "H1=" + h1i.toString();
    }if (di !== 0) {
      var TxtDi = "D=" + di.toString();
    }
console.log("*******************");
console.log("console.log TxtRg,TxtR1g,TxtR2g,TxtWg,TxtHg,TxtH1g,TxtDg",TxtRg,TxtR1g,TxtR2g,TxtWg,TxtHg,TxtH1g,TxtDg)
console.log("console.log TxtRg,TxtR1g,TxtR2g,TxtWg,TxtHg,TxtH1g,TxtDg",rg,r1g,r2g,wg,hg,h1g,dg)
console.log("&&&&&&&&&&&&&&&&&&&&&&&Aa,Aaa,Ahd,Ca,Caa,Chd,Ga,Gaa,Ghd,Ia,Iaa,Ihd",Aa,Aaa,Ahd,Ca,Caa,Chd,Ga,Gaa,Ghd,Ia,Iaa,Ihd);
  if (area == "A") {    
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */
  
    var markers = {
      myFsAMakerWA,
      myFsAMakerW1A,
       myFsAMakerW2A,
       myFsAMakerHA,
      myFsAMakerH1A,
       myFsAMakerR2A,
      myFsAMakerR1A,
      myFsAMakerDA,
    }

  } else if (area == "C") {
 
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
       
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
    
      var markers = {
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
      }
  } else if (area == "G") {
  
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

    
    /**************************************************** */
  
  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
/********************************************* */  
      var markers = {
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
      }
    
  } else if (area == "I") {
  
    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
       // "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };



    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

     
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2i,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   
/***************************************************/  
      var markers = {
         myFsAMakerWI,
         myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
         myFsAMakerH1I,
         myFsAMakerR2I,
         myFsAMakerR1I,
         myFsAMakerDI,
      }   
    
  } else if (area == "AC") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
   

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  
  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
       
      }
  

    
  } else if (area == "AG") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
  
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

  

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

 
  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  


  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
  

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
   
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {

    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

   
    /**************************************************** */
  
  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  
  /******************************************************** */
  var markers = {
    /**A */
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
 
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
   
    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  
  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
 
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

  
  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

   
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };


    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  
  /******************************************************** */
  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
   

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
   
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/   
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
  
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
  
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myFsAMakerWA = {
      paths: {
        "WALine0": new makerjs.paths.Line([xa,ya-ha], [xa,ya-ha-markShiftThree]),//左上角
        "WALine1": new makerjs.paths.Line([xa+wa,ya-ha], [xa+wa,ya-ha-markShiftThree]),//左上角
        "WALine2":new makerjs.paths.Line([xa,ya-ha-markShift], [xa+wa,ya-ha-markShift]),
      }
    };
    var myFsAMakerW1A = {
      paths: {
       "W1ALine0":new makerjs.paths.Line([xa,ya-ha-da-markShift], [xa+w1a,ya-ha-da-markShift]),
       "W1ALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a,ya-h1a-da-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
       "W2ALine0":new makerjs.paths.Line([xa,ya-ha-ha-markShiftThree], [xa+w2a,ya-ha-ha-markShiftThree]),
        "W2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a,ya-ha-ha-da-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHA = {
      
      paths: {
       "HALine0":new makerjs.paths.Line([xa,ya], [xa-markShiftFour, ya]),
       "HALine1": new makerjs.paths.Line([xa,ya-ha], [xa-markShiftFour, ya-ha]),//左上角
       "HALine2": new makerjs.paths.Line([xa-markShiftTwo,ya], [xa-markShiftTwo,ya-ha]),//左上角
      }
    };
    var myFsAMakerH1A = {    
      paths: {
       "H1ALine0":new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+da/2+markShiftFour, ya-h1a]),
       "H1ALine1": new makerjs.paths.Line([xa+w2a+markShift,ya], [xa+w2a+markShift,ya-h1a]),//左上角
      }
    };
    var myFsAMakerR2A = {    
      paths: {
       "R2ALine1": new makerjs.paths.Line([xa+w2a,ya], [xa+w2a, ya-r2a-markShiftFour]),//左上角
       "R2ALine2": new makerjs.paths.Line([xa+Aa-markShiftTwo,ya], [xa+Aa,ya-Ahd]),//左上角
      }
    };
    var myFsAMakerR1A = {    
      paths: {
       "R1ALine1": new makerjs.paths.Line([xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]),//左上角
      }
    };
    var myFsAMakerDA = {    
      paths: {
       "DALine1": new makerjs.paths.Line([xa+w1a,ya-h1a], [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*****************  C  *********** */
    var myFsAMakerWC = {
      paths: {
        "WCLine0": new makerjs.paths.Line([xc,yc-hc], [xc,yc-hc-markShiftThree]),//左上角
        "WCLine1": new makerjs.paths.Line([xc-wc,yc-hc], [xc-wc,yc-hc-markShiftThree]),//左上角
        "WCLine2":new makerjs.paths.Line([xc,yc-hc-markShift], [xc-wc,yc-hc-markShift]),
      }
    };
    var myFsAMakerW1C = {
      paths: {
       "W1CLine0":new makerjs.paths.Line([xc,yc-hc-dc-markShift], [xc-w1c,yc-hc-dc-markShift]),
       "W1CLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c,yc-h1c-dc-markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
       "W2CLine0":new makerjs.paths.Line([xc,yc-hc-hc-markShiftThree], [xc-w2c,yc-hc-hc-markShiftThree]),
        "W2CLine1": new makerjs.paths.Line([xc-w2c,yc], [xc-w2c,yc-hc-hc-dc-markShiftFour]),//左上角
      }
    };
    var myFsAMakerHC = {      
      paths: {
       "HCLine0":new makerjs.paths.Line([xc,yc], [xc+markShiftFour, yc]),
       "HCLine1": new makerjs.paths.Line([xc,yc-hc], [xc+markShiftFour, yc-hc]),//左上角
       "HCLine2": new makerjs.paths.Line([xc+markShiftTwo,yc], [xc+markShiftTwo,yc-hc]),//左上角
      }
    };
    var myFsAMakerH1C = {    
      paths: {
       "H1CLine0":new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-dc/2-markShiftFour, yc-h1c]),
       "H1CLine1": new makerjs.paths.Line([xc-w2c-markShift,yc], [xc-w2c-markShift,yc-h1c]),//左上角
      }
    };
    var myFsAMakerR2C = {    
      paths: {
      //  "R2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2, yc-r2-markShiftFour]),//左上角
       "R2CLine2": new makerjs.paths.Line([xc-Caa+markShiftTwo,yc], [xc-Caa,yc-Chd]),//左上角
      }
    };
    var myFsAMakerR1C = {    
      paths: {
       "R1CLine1": new makerjs.paths.Line([xc-wc,yc-Chd], [xc-wc-markShift,yc-Chd-markShift]),//左上角
      }
    };
    var myFsAMakerDC = {    
      paths: {
       "DCLine1": new makerjs.paths.Line([xc-w1c,yc-h1c], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /****************** G   ************** */
    var myFsAMakerWG = {
      paths: {
        "WGLine0": new makerjs.paths.Line([xg,yg+hg], [xg,yg+hg+markShiftThree]),//左上角
        "WGLine1": new makerjs.paths.Line([xg+wg,yg+hg], [xg+wg,yg+hg+markShiftThree]),//左上角
        "WGLine2":new makerjs.paths.Line([xg,yg+hg+markShift], [xg+wg,yg+hg+markShift]),
      }
    };
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0":new makerjs.paths.Line([xg,yg+hg+dg+markShift], [xg+w1g,yg+hg+dg+markShift]),
        "W1GLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g,yg+h1g+dg+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0":new makerjs.paths.Line([xg,yg+hg+hg+markShiftThree], [xg+w2g,yg+hg+hg+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g,yg+hg+hg+dg+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHG = {      
      paths: {
        "HGLine0":new makerjs.paths.Line([xg,yg], [xg-markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg,yg+hg], [xg-markShiftFour, yg+hg]),//左上角
        "HGLine2": new makerjs.paths.Line([xg-markShiftTwo,yg], [xg-markShiftTwo,yg+hg]),//左上角
      }
    };
    var myFsAMakerH1G = {    
      paths: {
        "H1GLine0":new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+dg/2+markShiftFour, yg+h1g]),
        "H1GLine1": new makerjs.paths.Line([xg+w2g+markShift,yg], [xg+w2g+markShift,yg+h1g]),//左上角
      }
    };
    var myFsAMakerR2G = {    
      paths: {
        "R2GLine1": new makerjs.paths.Line([xg+w2g,yg], [xg+w2g, yg+r2g+markShiftFour]),//左上角
        "R2GLine2": new makerjs.paths.Line([xg+Ga-markShiftTwo,yg], [xg+Ga,yg+Ghd]),//左上角
      }
    };
    var myFsAMakerR1G = {    
      paths: {
        "R1GLine1": new makerjs.paths.Line([xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]),//左上角
      }
    };
    var myFsAMakerDG = {    
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1g,yg+h1g], [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /************************   I  ******************* */
    var myFsAMakerWI = {
      paths: {
        "WILine0": new makerjs.paths.Line([xi,yi+hi], [xi,yi+hi+markShiftThree]),//左上角
        "WILine1": new makerjs.paths.Line([xi-wi,yi+hi], [xi-wi,yi+hi+markShiftThree]),//左上角
        "WILine2":new makerjs.paths.Line([xi,yi+hi+markShift], [xi-wi,yi+hi+markShift]),
      }
    };
    var myFsAMakerW1I = {
      paths: {
       "W1ILine0":new makerjs.paths.Line([xi,yi+hi+di+markShift], [xi-w1i,yi+hi+di+markShift]),
       "W1ILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i,yi+h1i+di+markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
       "W2ILine0":new makerjs.paths.Line([xi,yi+hi+hi+markShiftThree], [xi-w2i,yi+hi+hi+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi-w2i,yi], [xi-w2i,yi+hi+hi+di+markShiftFour]),//左上角
      }
    };
    var myFsAMakerHI = {      
      paths: {
       "HILine0":new makerjs.paths.Line([xi,yi], [xi+markShiftFour, yi]),
       "HILine1": new makerjs.paths.Line([xi,yi+hi], [xi+markShiftFour, yi+hi]),//左上角
       "HILine2": new makerjs.paths.Line([xi+markShiftTwo,yi], [xi+markShiftTwo,yi+hi]),//左上角
      }
    };
    var myFsAMakerH1I = {    
      paths: {
       "H1ILine0":new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-di/2-markShiftFour, yi+h1i]),
       "H1ILine1": new makerjs.paths.Line([xi-w2i-markShift,yi], [xi-w2i-markShift,yi+h1i]),//左上角
      }
    };
    var myFsAMakerR2I = {    
      paths: {
      //  "R2ILine1": new makerjs.paths.Line([xi-w2,yi], [xi-w2, yi-r2-markShiftFour]),//左上角
       "R2ILine2": new makerjs.paths.Line([xi-Iaa+markShiftTwo,yi], [xi-Iaa,yi+Ihd]),//左上角
      }
    };
    var myFsAMakerR1I = {    
      paths: {
       "R1ILine1": new makerjs.paths.Line([xi-wi,yi+Ihd], [xi-wi-markShift,yi+Ihd+markShift]),//左上角
      }
    };
    var myFsAMakerDI = {    
      paths: {
       "DILine1": new makerjs.paths.Line([xi-w1i,yi+h1i], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]),//左上角
      }
    };

    /**************************************************** */
    
  makerjs.model.addCaption(myFsAMakerWA, TxtWa, [xa,ya-ha-markShiftThree], [xa+wa,ya-ha-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1A, TxtW1a, [xa,ya-ha-da-markShiftThree], [xa+w1a,ya-ha-da-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2A, TxtW2a, [xa,ya-ha-ha-da-markShiftFour], [xa+w2a,ya-ha-ha-da-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHA, TxtHa, [xa-markShiftFour,ya-ha], [xa-markShiftFour,ya]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1A, TxtH1a, [xa+w2a+markShiftThree,ya-h1a], [xa+w2a+markShiftThree,ya]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2A, TxtR2a, [xa+Aa-markShiftThree,ya-Ahd+markShiftThree], [xa+Aa-markShift,ya-Ahd+markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1A, TxtR1a, [xa+wa,ya-Ahd], [xa+wa+markShift,ya-Ahd-markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDA, TxtDa, [xa+w1a+markShift+markHolesOneD,ya-h1a-da-markShiftFour-markHolesOneD],[xa+w1a+markHolesOneD+markShiftFour,ya-h1a-da-markShiftFour-markHolesOneD]);//D文字标注  
    
  /**************************************************** */

  
  makerjs.model.addCaption(myFsAMakerWC, TxtWc, [xc-wc,yc-hc-markShiftThree], [xc,yc-hc-markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1C, TxtW1c, [xc-w1c,yc-hc-dc-markShiftThree], [xc,yc-hc-dc-markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2C, TxtW2c, [xc-w2c,yc-hc-hc-dc-markShiftFour], [xc,yc-hc-hc-dc-markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHC, TxtHc, [xc+markShiftFour,yc-hc], [xc+markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1C, TxtH1c, [xc-w2c-markShiftThree,yc-h1c], [xc-w2c-markShiftThree,yc]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2C, TxtR2c, [xc-Caa+markShift,yc-Chd+markShift], [xc-Caa+markShiftThree,yc-Chd+markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1C, TxtR1c, [xc-wc-markShift,yc-Chd-markShift], [xc-wc,yc-Chd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDC, TxtDc,[xc-w1c-markHolesOneD-markShiftFour,yc-h1c-dc-markShiftFour-markHolesOneD], [xc-w1c-markShift-markHolesOneD,yc-h1c-dc-markShiftFour-markHolesOneD]);//D文字标注  
    

  /******************************************************** */
  makerjs.model.addCaption(myFsAMakerWG, TxtWg, [xg,yg+hg+markShiftThree], [xg+wg,yg+hg+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1G, TxtW1g, [xg,yg+hg+dg+markShiftThree], [xg+w1g,yg+hg+dg+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2G, TxtW2g, [xg,yg+hg+hg+dg+markShiftFour], [xg+w2g,yg+hg+hg+dg+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHG, TxtHg, [xg-markShiftFour,yg+hg], [xg-markShiftFour,yg]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1G, TxtH1g, [xg+w2g+markShiftThree,yg+h1g], [xg+w2g+markShiftThree,yg]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2G, TxtR2g, [xg+Ga-markShiftThree,yg+Ghd-markShiftThree], [xg+Ga-markShift,yg+Ghd-markShift]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1G, TxtR1g, [xg+wg,yg+Ghd], [xg+wg+markShift,yg+Ghd+markShift]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDG, TxtDg, [xg+w1g+markShift+markHolesOneD,yg+h1g+dg+markShiftFour+markHolesOneD],[xg+w1g+markHolesOneD+markShiftFour,yg+h1g+dg+markShiftFour+markHolesOneD]);//D文字标注  
    
  /******************************************************** */
  
  makerjs.model.addCaption(myFsAMakerWI, TxtWi, [xi-wi,yi+hi+markShiftThree], [xi,yi+hi+markShiftThree]);//半径文字  
  makerjs.model.addCaption(myFsAMakerW1I, TxtW1i, [xi-w1i,yi+hi+di+markShiftThree], [xi,yi+hi+di+markShiftThree]);//W1文字标注 
  makerjs.model.addCaption(myFsAMakerW2I, TxtW2i, [xi-w2i,yi+hi+hi+di+markShiftFour], [xi,yi+hi+hi+di+markShiftFour]);//W1文字标注
  makerjs.model.addCaption(myFsAMakerHI, TxtHi, [xi+markShiftFour,yi+hi], [xi+markShiftFour,yi]);//H文字标注
  makerjs.model.addCaption(myFsAMakerH1I, TxtH1i, [xi-w2i-markShiftThree,yi+h1i], [xi-w2i-markShiftThree,yi]);//H1文字标注
  makerjs.model.addCaption(myFsAMakerR2I, TxtR2i, [xi-Iaa+markShift,yi+Ihd-markShift], [xi-Iaa+markShiftThree,yi+Ihd-markShiftThree]);//R2文字标注
  makerjs.model.addCaption(myFsAMakerR1I, TxtR1i, [xi-wi-markShift,yi+Ihd+markShift], [xi-wi,yi+Ihd]);//R1文字标注
  makerjs.model.addCaption(myFsAMakerDI, TxtDi,[xi-w1i-markHolesOneD-markShiftFour,yi+h1i+di+markShiftFour+markHolesOneD], [xi-w1i-markShift-markHolesOneD,yi+h1i+di+markShiftFour+markHolesOneD]);//D文字标注  
   

  /******************************************************** */
  var markers = {
    /**A */
        myFsAMakerWA,
        myFsAMakerW1A,
         myFsAMakerW2A,
         myFsAMakerHA,
        myFsAMakerH1A,
         myFsAMakerR2A,
        myFsAMakerR1A,
        myFsAMakerDA,
        /**C */
        myFsAMakerWC,
        myFsAMakerW1C,
         myFsAMakerW2C,
         myFsAMakerHC,
        myFsAMakerH1C,
         myFsAMakerR2C,
        myFsAMakerR1C,
        myFsAMakerDC,
        /** G*/        
        myFsAMakerWG,
        myFsAMakerW1G,
         myFsAMakerW2G,
         myFsAMakerHG,
        myFsAMakerH1G,
         myFsAMakerR2G,
        myFsAMakerR1G,
        myFsAMakerDG,
        /**I */
        myFsAMakerWI,
        myFsAMakerW1I,
         myFsAMakerW2I,
         myFsAMakerHI,
        myFsAMakerH1I,
         myFsAMakerR2I,
        myFsAMakerR1I,
        myFsAMakerDI,
      }
  

    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return { markers };
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
 //已知相切两圆的圆心坐标及半径，求切点坐标
 function CircleTwo(x1,y1,x2,y2,r1,r2){
    var x=(r1*x2+x1*r2)/(r2+r1);    
     var d=Math.sqrt((r1*r1)-(x-x1)*(x-x1));             
   var Arr = [x,d]; 
   return Arr;
 };
function FloorSpring(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
  var frame = outParam.shapeName;
  console.log("outParam=====FloorSpringFloorSpring??????????????======", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var D=inputParam.D,R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
    plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,


      w = W*1*myZoom,
      w1 = W1*1*myZoom,
      w2 = W2*1*myZoom,
      h = H*1*myZoom,
      h1 = H1*1*myZoom,
      r1 = R1*1*myZoom,
      r2 = R2*1*myZoom,
      d = D*1*myZoom,
      r = d/2,
      a=0,//圆心坐标[a,b]
      b=0,//圆心坐标[a,b]  
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;  
        if(plate.models===undefined||plate.models===""){
          console.log("1111111111111111");
          plate.models={};
                  }        
                  if(cutPlate.models===undefined||cutPlate.models===""){
                      console.log("22222222222");
                      //cutPlate.models={};
                      cutPlate.models={};
                  }
                  if(markers.models===undefined||markers.models===""){            
                      console.log("333333333333");
                      markers.models={};
                  }

      if(d!==0){
        var TxtD="∮"+D;
       }
       if(r1!==0){
        var TxtR1="R1="+R1;
       }
       if(r2!==0){
        var TxtR2="R2="+R2;
       }
       if(w!==0){
        var TxtW=W;
       }
       if(w1!==0){
        var TxtW1=W1;
       }
       if(w2!==0){
        var TxtW2=W2;
       }
       if(h!==0){
        var TxtH=H;
       }
       if(h1!==0){
        var TxtH1=H1;
       }
       if(x0!==0){          
       var TxtX=X;
       }
       if(y0!==0){          
        var TxtY=Y;
       }
  } else {
    var D=inputParam.D,R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
    plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      w = W*1*myZoom,
   w1 = W1*1*myZoom,
   w2 = W2*1*myZoom,
   h = H*1*myZoom,
   h1 = H1*1*myZoom,
   r1 = R1*1*myZoom,
   r2 = R2*1*myZoom,
   d = D*1*myZoom,
   r = d/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;  
     if(plate.models===undefined||plate.models===""){
      console.log("1111111111111111");
      plate.models={};
              }        
              if(cutPlate.models===undefined||cutPlate.models===""){
                  console.log("22222222222");
                  //cutPlate.models={};
                  cutPlate.models={};
              }
              if(markers.models===undefined||markers.models===""){            
                  console.log("333333333333");
                  markers.models={};
              }
    /* 
   w = W*1,
   w1 = W1*1,
   w2 = W2*1,
   h = H*1,
   h1 = H1*1,
   r1 = R1*1,
   r2 = R2*1,
   d = D*1,
   r = (D*1)/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1,
     y0 = Y*1;
     */   
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(r1!==0){
      var TxtR1="R1="+R1;
     }
     if(r2!==0){
      var TxtR2="R2="+R2;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(w2!==0){
      var TxtW2=W2;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
    }
  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****") 

    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
    //C区域
    xc = glassW * 1 - x0, yc = glassH * 1 - y0,
    //在G区域 计算孔的位置
    xg = x0, yg = y0,
    //在I区域 计算孔的位置
    xi = glassW * 1 - x0, yi = y0,
    arrA=[],arrC=[],arrG=[],arrI=[];

  arrA=CircleTwo(xa+w,ya+r1,xa+w2,ya-r2,r1,r2);
  var Aa=arrA[0]*1,  Ab=arrA[1]*1,  Ahd=h-(r1-Ab),Aaa=glassW * 1-Aa;
  arrC=CircleTwo(xc-w,yc+r1,xc-w2,yc-r2,r1,r2);
  var Ca=arrC[0]*1,  Cb=arrC[1]*1,  Chd=h-(r1-Cb),Caa=glassW * 1-Ca;
  arrG=CircleTwo(xg+w,yg-r1,xg+w2,yg+r2,r1,r2);
  var Ga=arrG[0]*1,  Gb=arrG[1]*1,  Ghd=h-(r1-Gb),Gaa=glassW * 1-Ga;
  arrI=CircleTwo(xi-w,yi-r1,xi-w2,yi+r2,r1,r2); 
  var Ia=arrI[0]*1,  Ib=arrI[1]*1,  Ihd=h-(r1-Ib),Iaa=glassW * 1-Ia,
// var a=arr[0]*1,b=arr[1]*1,aa=glassW * 1-a,hd=h-(r1-b),
      xawx1 = xa, xawy1 =ya-h,//A位置角1坐标
      xawx2 = xa+w,xawy2 = ya-h,//A位置角2坐标
      xawx3 = xa+Aa,xawy3 = ya-Ahd,//A位置角3坐标
      xawx4 = xa+w2,xawy4 = ya,//A位置角4坐标
      // "TopLine": new makerjs.paths.Line([xc-w2, yc+5],[xc+5, yc+5]),
      //   "RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
      //   "BottomLine": new makerjs.paths.Line( [xc+5, yc-h],[xc-w, yc-h]),
      //   "BottomRightArc": new makerjs.paths.Arc([xc-w, yc-h], [xc-aa, yc-hd],r1,false,true),//是否大弧,是否顺时针  
      //   "BottomLeftArc": new makerjs.paths.Arc([xc-aa, yc-hd], [xc-w2, yc],r2,false,false),//是否大弧,是否顺时针
      //   "LeftLine": new makerjs.paths.Line([xc-w2, yc], [xc-w2, yc+5]),
      //   "RightHoles": new makerjs.paths.Circle([xc-w1, yc-h1], r),
      xcwx1 = xc, xcwy1 =yc-h,//C位置角1坐标
      xcwx2 = xc-w,xcwy2 = yc-h,//C位置角2坐标
      xcwx3 = xc-Caa,xcwy3 = yc-Chd,//C位置角3坐标
      xcwx4 = xc-w2,xcwy4 = yc,//C位置角4坐标
      // var myFloorSpringG = {
      //   paths: {  
      //     "LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
      //     "TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg+w, yg+h]),
      //     "TopLeftArc": new makerjs.paths.Arc([xg+w, yg+h], [xg+a, yg+hd],r1,false,true),//是否大弧,是否顺时针  
      //     "TopRightArc": new makerjs.paths.Arc([xg+a, yg+hd], [xg+w2, yg],r2,false,false),//是否大弧,是否顺时针
      //     "RightLine": new makerjs.paths.Line([xg+w2, yg], [xg+w2, yg-5]),
      //     "BottomLine": new makerjs.paths.Line([xg+w2, yg-5], [xg-5, yg-5]),
      //     "RightHoles": new makerjs.paths.Circle([xg+w1, yg+h1], r),
      //   }
      //   };

      xgwx1 = xg, xgwy1 =yg+h,//G位置角1坐标
      xgwx2 = xg+w,xgwy2 = yg+h,//G位置角2坐标
      xgwx3 = xg+Ga,xgwy3 = yg+Ghd,//G位置角3坐标
      xgwx4 = xg+w2,xgwy4 = yg,//G位置角4坐标
      
      // var myFloorSpringI = {
      //   paths: {
      //     "BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w2, yi-5]),
      //     "LeftLine": new makerjs.paths.Line([xi-w2, yi-5], [xi-w2, yi]), 
      //     "TopLeftArc": new makerjs.paths.Arc([xi-w2, yi], [xi-aa, yi+hd],r2,false,false),//是否大弧,是否顺时针  
      //     "TopRightArc": new makerjs.paths.Arc([xi-aa, yi+hd], [xi-w, yi+h],r1,false,true),//是否大弧,是否顺时针
      //     "TopLine": new makerjs.paths.Line([xi-w, yi+h], [xi+5, yi+h]),
      //     "RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5]),
      //     "RightHoles": new makerjs.paths.Circle([xi-w1, yi+h1], r),
      //   }
      //   };

      xiwx1 = xi, xiwy1 =yi+h,//I位置角1坐标
      xiwx2 = xi-w,xiwy2 = yi+h,//I位置角2坐标
      xiwx3 = xi-Iaa,xiwy3 = yi+Ihd,//I位置角3坐标
      xiwx4 = xi-w2,xiwy4 = yi;//I位置角4坐标      
var OffsetParam={Aa,Aaa,Ahd,Ca,Caa,Chd,Ga,Gaa,Ghd,Ia,Iaa,Ihd};
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),
      cwd = awd,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - awd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: awd, iwdd: 0, ihd: 0 };
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa + w, xawy = ya + ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc - ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd, cwd: iwd, cwdd: 0, chd: 0, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移


      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa + w, xawy = ya - ahd,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc + ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: iwd, cwdd: 0, chd: ahd, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: cwd, iwdd: 0, ihd: 0 };
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + awd, xihy = yi + h;//I位置角H坐标

    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: awd, ihd: 0 };

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: cwd, ihd: 0 };

  }
  if(inputParam.area===undefined){
    var ObliqueAngleData=showArea(inputParam,outParam);
    var paramCoordinate =ObliqueAngleData[1];
    var areaPoint =ObliqueAngleData[0];
    var whMarksParam=ObliqueAngleData[2];
    console.log("whMarksParam=",whMarksParam);
  }else{
// 坐标参数
var paramCoordinate = {d,w,w1,w2,h,h1,r,r1,r2, xa, ya, xawx1, xawy1,xawx2, xawy2,xawx3, xawy3,xawx4, xawy4, xc, yc, xcwx1, xcwy1, xcwx2,xcwy2,xcwx3,xcwy3,xcwx4,xcwy4, xg, yg, xgwx1, xgwy1, xgwx2, xgwy2,xgwx3, xgwy3,xgwx4, xgwy4, xi, yi, xiwx1, xiwy1, xiwx2, xiwy2, xiwx3, xiwy3, xiwx4, xiwy4 };
const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
var areaPoint = "";
console.log("//画1、斜角 ------", allKeys)
if (allKeys[0] == frame) {
  allKeys.shift();//去掉数组第一个元素
}
console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
if (allKeys.length == 0) {
  switch (area) {
    case "1": areaPoint = "A"; break;
    case "3": areaPoint = "C"; break;
    case "7": areaPoint = "G"; break;
    case "9": areaPoint = "I"; break;
  }
} else {
  switch (area) {
    case "1": areaPoint = getStrs("A", allKeys); break;
    case "3": areaPoint = getStrs("C", allKeys); break;
    case "7": areaPoint = getStrs("G", allKeys); break;
    case "9": areaPoint = getStrs("I", allKeys); break;
  }
}
  }
  
  /**
  * 画图
  * 
  */
  var ObjName = "myFloorSpring" + areaPoint;
  var ObjNameM = "myFloorSpring" + areaPoint + "Mark";//外框标记
  var ObjNameMCorner = "myFloorSpring" + areaPoint + "MarkCorner";//角标记
  var ObjNameO = "myFloorSpring" + areaPoint + "Obj";
  ObjName = creatFloorSpring(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMCorner = creatFloorSpringMark(outParam, areaPoint, paramCoordinate, whMarksParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMCorner===|||||||||角标注", ObjNameMCorner);
  ObjNameO = { ObjName:ObjName[0] };
  ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
  cutPlate.models = {};
  plate.models = {};
  markers.models = {};
  cutPlate.models["myFloorSpring" + areaPoint] = ObjName[1];
  plate.models["myFloorSpring" + areaPoint] = ObjName[0];
  console.log("ObjNameM.markers.models===|||||||||地弹簧1是否有所有标注", ObjNameM.markers.models);
  console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMCorner.markers);
  markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMCorner.markers };
  console.log("markers===|||||||||是否有所有标注", markers);
  console.log("plate===|||||地弹簧1||||是否有所有标注", plate);
  console.log("cutPlate===||||地弹簧1|||||是否有所有标注", cutPlate);

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default FloorSpring;