<template>
  <div class="contanerBox">
    <!-- 头部Header -->
    <div class="conHeader">
      <div class="MenuBtns">
        <div class="logo-img-contener">
          <span class="logo-img-item">
            <img src="../assets/200.png" width="40px" alt="" />
            <span style="font-size: 12px">&nbsp</span>
          </span>
        </div>
        <div class="openMenu">
          <!-- <i class="iconfont icon-drawdakai"></i> -->
          <label class="open-svg display-clo">
            <span class="display-clo">
              <i class="iconfont icon-drawdakai"></i>
              <span style="font-size: 12px">打开</span>
            </span>
            <input
              type="file"
              accept="image/png,image/svg+xml,application/json"
              id="openSVG"
              @change="handleFileChange"
            />
          </label>
        </div>
        <div class="div-cursor" @click="dialogFormVisible = true">
          <i class="iconfont icon-drawxinjian10"></i>
          <span style="font-size: 12px">新建</span>
        </div>

        <div class="div-cursor" @click="saveMyPng">
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="保存PNG图"
            placement="bottom"
          > -->
          <i class="iconfont icon-drawPNG"></i>

          <span style="font-size: 12px">保存图</span>
          <!-- </el-tooltip> -->
        </div>
        <div
          class="div-cursor"
          @click="canUndo ? drawUndo(stateIndex - 1) : null"
        >
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="撤销"
            placement="bottom"
          > -->
          <i
            :style="{ color: canUndo ? '' : '#878889' }"
            class="iconfont icon-drawnishizhen"
          ></i>
          <span style="font-size: 12px">撤销</span>
          <!-- </el-tooltip> -->
        </div>
        <div
          class="div-cursor"
          @click="canRedo ? drawRedo(stateIndex + 1) : null"
        >
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="恢复"
            placement="bottom"
          > -->
          <i
            :style="{ color: canRedo ? '' : '#878889' }"
            class="iconfont icon-drawshunshizhen"
          ></i>
          <span style="font-size: 12px">恢复</span>
          <!-- </el-tooltip> -->
        </div>
        <div class="div-cursor" v-if="isClearSvg" @click="clearSvg">
          <!-- <el-tooltip
            class="item-test"
            effect="dark"
            content="清空"
            placement="bottom"
          > -->

          <i class="iconfont icon-drawqingkong"></i>
          <span style="font-size: 12px">清空</span>

          <!-- </el-tooltip> -->
        </div>
        <div class="div-cursor">
          <el-dropdown @command="handleHolesCommand">
            <span class="el-dropdown-link display-clo">
              <i
                class="iconfont icon-drawhuituxiadezicaidantubiao_tihuanyuanxingdingweikongweigulidian1"
              ></i>
              <span style="font-size: 12px">打孔机</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="boliang">博亮打孔机</el-dropdown-item>
              <el-dropdown-item command="jingrui">精锐打孔机</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="div-cursor" @click="exWaterJet">
          <!-- <el-tooltip
            class="item-test"
            effect="dark"
            content="导出水刀"
            placement="bottom"
          > -->
          <i class="iconfont icon-drawqiege"></i>

          <span style="font-size: 12px">水刀</span>
          <!-- </el-tooltip> -->
        </div>
        <div class="div-cursor" @click="glassCut">
          <!-- <el-tooltip
            class="item-test"
            effect="dark"
            content="导出切割机格式"
            placement="bottom"
          > -->
          <i class="iconfont icon-drawConcise"></i>
          <span style="font-size: 12px">切割机</span>
          <!-- </el-tooltip> -->
        </div>
        <div class="div-cursor" @click="changeRect">         
          <i class="iconfont icon-drawjuxing"></i>
          <span style="font-size: 12px">修改尺寸</span>
        </div>
        <div class="div-cursor" @click="creatImg">
          <i class="iconfont icon-drawiconmobanku"></i>
          <span style="font-size: 12px">存模板</span>
        </div>
        <div class="div-cursor" @click="openMdWin(mdParma.mdTpye)">
          <i class="iconfont icon-drawshujuyuanguanli"></i>
          <span style="font-size: 12px">模板库</span>
        </div>
        <!-- <div class="div-cursor" @click="openAbnormalShapedLibrary()">
          <i class="iconfont icon-drawzhengjiaoduobianxing"></i>
          <span style="font-size: 12px">异形库</span>
        </div> -->
      </div>

      <div class="MenuBtns-right">
        <div class="r_content">
          <el-dropdown @command="handleClick">
            <span class="el-dropdown-link">
              <img class="user-img" src="../assets/images/girls.jpeg" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="sysSet">系统设置</el-dropdown-item>
              <el-dropdown-item command="logOut">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 内容conMain -->
    <div class="conMain">
      <!-- 右侧中间内容 conMainCon -->
      <div class="conMainCon" id="content">
        <div id="view" class="drawBack">
          <div id="drawCanvas" class="draw"></div>
        </div>
      </div>
      <div class="conMainRight">
        <div>
          <el-tabs type="border-card" @tab-click="handleClickCard">
            <el-tab-pane label="孔" v-model="activeName">
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickHole"
                style="height: 80vh"
              >
                <el-tab-pane label="单孔" name="first">
                  <!-- 单孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/SingleHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">请输入孔的直径D：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'SingleHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 单孔 -->
                </el-tab-pane>
                <el-tab-pane label="吊轮孔2" name="second">
                  <!-- 吊轮孔2 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleTwo.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">请输入孔的直径D：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleTwo')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 吊轮孔2 -->
                </el-tab-pane>
                <el-tab-pane label="双固定孔列" name="eight">
                  <!-- 双固定孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/DoubleFixHolesCol.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'DoubleFixHolesCol')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双固定孔 -->
                </el-tab-pane>
                <el-tab-pane label="双固定孔" name="nine">
                  <!-- 双固定孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/DoubleFixHoles.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'DoubleFixHoles')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双固定孔 -->
                </el-tab-pane>
                <el-tab-pane label="双吊轮孔列" name="fourth">
                  <!-- 双吊轮孔列 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleTwoCol.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleTwoCol')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双吊轮孔列 -->
                </el-tab-pane>
                <el-tab-pane label="双吊轮孔排" name="five">
                  <!-- 双吊轮孔排 -->

                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleTwoRow.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleTwoRow')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 双吊轮孔排 -->
                </el-tab-pane>
                <el-tab-pane label="三孔" name="seventeen">
                  <!-- 三孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img :src="threeHolePic" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  类型选择 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >选择类型</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row
                    class="row-bg hole-lineH"
                    type="flex"
                    justify="center"
                  >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="A"
                        >类型A</el-radio
                      ></el-col
                    >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="B"
                        >类型B</el-radio
                      ></el-col
                    >
                  </el-row>
                  <el-row
                    class="row-bg hole-lineH"
                    type="flex"
                    justify="center"
                  >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="C"
                        >类型C</el-radio
                      ></el-col
                    >
                    <el-col :span="12"
                      ><el-radio
                        @change="threeHolesChange"
                        v-model="threeHoleType"
                        label="D"
                        >类型D</el-radio
                      ></el-col
                    >
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="threeHoleFnClick()"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 三孔 -->
                </el-tab-pane>
                <el-tab-pane label="拉手孔" name="eleven">
                  <!-- 拉手孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/HandleHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HandleHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 拉手孔 -->
                </el-tab-pane>
                <el-tab-pane label="吊轮孔列" name="fourteen">
                  <!-- 吊轮孔列 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleCol.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleCol')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 吊轮孔列 -->
                </el-tab-pane>
                <el-tab-pane label="吊轮孔3" name="third">
                  <!-- 吊轮孔3 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HangWheelHoleThree.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">请输入孔的直径D：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangWheelHoleThree')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 吊轮孔3 -->
                </el-tab-pane>
                <el-tab-pane label="晾物孔" name="six">
                  <!-- 晾物孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/HangHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> D:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> W:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入孔的参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H2:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H2"
                          placeholder="请输入孔的参数H2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HangHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 晾物孔 -->
                </el-tab-pane>
                <el-tab-pane label="拉手孔T字" name="seven">
                  <!-- 拉手孔T字 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/HandleHoleTShaped.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> D:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> W:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入孔的参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px"> H2:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H2"
                          placeholder="请输入孔的参数H2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HandleHoleTShaped')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 拉手孔T字 -->
                </el-tab-pane>
                <el-tab-pane label="相对吊轮孔" name="ten">
                  <!-- 相对吊轮孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RelSuWheelHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'RelSuWheelHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 相对吊轮孔 -->
                </el-tab-pane>
                <el-tab-pane label="旋转锁孔" name="twelve">
                  <!-- 旋转锁孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RotatLockHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="3">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'RotatLockHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 旋转锁孔 -->
                </el-tab-pane>
                <el-tab-pane label="拉手孔3" name="thirteen">
                  <!-- 拉手孔3 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/HandleHole3.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'HandleHole3')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 拉手孔3 -->
                </el-tab-pane>
                <el-tab-pane label="7字拉手孔" name="fifteen">
                  <!-- 7字拉手孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img
                          src="../assets/image/7ShapedHandleHole.jpg"
                          alt=""
                        />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入孔的直径D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'Shaped7HandleHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 7字拉手孔 -->
                </el-tab-pane>
                <el-tab-pane label="锁孔" name="sixteen">
                  <!-- 锁孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/KeyHole.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D1：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D1"
                          placeholder="请输入孔的直径D1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">D2：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="D2"
                          placeholder="请输入孔的直径D2"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="false"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Hole', 'KeyHole')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 锁孔 -->
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="缺" v-model="activeName">
              <!-- <el-divider><i class="el-icon-more-outline"></i></el-divider> -->
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickGap"
                style="height: 80vh"
              >
                <el-tab-pane label="蝴蝶" name="first">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入蝴蝶参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="蝴蝶2" name="second">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap2.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入蝴蝶参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入蝴蝶参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入蝴蝶参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap2')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="蝴蝶3" name="third">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap3.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R1"
                          placeholder="请输入蝴蝶参数R1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">R2：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R2"
                          placeholder="请输入蝴蝶参数R2"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入蝴蝶参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H2：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H2"
                          placeholder="请输入蝴蝶参数H2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap3')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="蝴蝶4" name="twelfth">
                  <!-- 蝴蝶 -->
                  <!-- 位置和示意图 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ButterflyGap4.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >蝴蝶参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R1"
                          placeholder="请输入蝴蝶参数R1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">R2：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R2"
                          placeholder="请输入蝴蝶参数R2"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入蝴蝶参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入蝴蝶参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入蝴蝶参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'ButterflyGap4')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 蝴蝶 -->
                </el-tab-pane>
                <el-tab-pane label="U型" name="fourth">
                  <!-- U型 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/UShapeGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >U型缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入U型缺参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入U型缺参数参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'UShapeGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- U型 -->
                </el-tab-pane>
                <el-tab-pane label="应力孔" name="five">
                  <!-- 应力孔 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/StressHoleGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >应力孔参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入应力孔的参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入应力孔的参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入应力孔的参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'StressHoleGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 应力孔 -->
                </el-tab-pane>
                <el-tab-pane label="跑道缺" name="six">
                  <!-- 跑道缺 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RunwayGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >跑道缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="跑道缺参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="跑道缺参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="跑道缺参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W1:</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="跑道缺参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'RunwayGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 跑道缺 -->
                </el-tab-pane>
                <el-tab-pane label="方缺" name="seven">
                  <!-- 方缺 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/SquareGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >方缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入方缺参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入方缺参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入方缺参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'SquareGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 方缺 -->
                </el-tab-pane>
                <el-tab-pane label="矩形缺" name="eight">
                  <!-- 方缺 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/RectAngleGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >矩形缺参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入矩形缺参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入矩形缺参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入矩形缺参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'RectAngleGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 方缺 -->
                </el-tab-pane>
                <el-tab-pane label="铜夹" name="nine">
                  <!-- 铜夹 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/CopperClipGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >铜夹参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">D:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入铜夹参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">R:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入铜夹参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">W:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入铜夹参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入铜夹参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span style="font-size: 12px">H1:</span>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入铜夹参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'CopperClipGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 铜夹 -->
                </el-tab-pane>
                <el-tab-pane label="长条夹" name="ten">
                  <!-- 长条夹 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/LongClipGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >长条夹参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入长条夹参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">D1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D1"
                          placeholder="请输入长条夹参数D1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入长条夹参数H"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入长条夹参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'LongClipGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 长条夹 -->
                </el-tab-pane>
                <el-tab-pane label="椭圆" name="eleven">
                  <!-- 长条夹 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/EllipseGap.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >椭圆参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">Rx：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Rx"
                          placeholder="请输入椭圆参数Rx"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Ry：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Ry"
                          placeholder="请输入椭圆参数Ry"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="showX"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="showY"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Gap', 'EllipseGap')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 长条夹 -->
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="角" v-model="activeName">
              <!-- <el-divider><i class="el-icon-more-outline"></i></el-divider> -->
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickCorner"
                style="height: 80vh"
              >
                <el-tab-pane label="斜角" name="first">
                  <!-- 斜角 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/ObliqueAngle.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >斜角参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="斜角参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="斜角参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'ObliqueAngle')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 斜角 -->
                </el-tab-pane>
                <el-tab-pane label="圆角" name="second">
                  <!-- 圆角 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/Fillet.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >圆角参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入圆角参数R"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'Fillet')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 圆角 -->
                </el-tab-pane>
                <el-tab-pane label="方角" name="third">
                  <!-- 方角 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/Corner.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >方角参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入方角参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入方角参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入方角参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'Corner')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 方角 -->
                </el-tab-pane>
                <el-tab-pane label="圆角2" name="fourth">
                  <!-- 圆角2 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/Fillet2.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >圆角2参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="2">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入圆角2参数R"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入圆角2参数W"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入圆角2参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'Fillet2')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 圆角2 -->
                </el-tab-pane>
                <el-tab-pane label="地弹簧" name="five">
                  <!-- 地弹簧 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/FloorSpring.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >地弹簧参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入地弹簧参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入地弹簧参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入地弹簧参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入地弹簧参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入地弹簧参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入地弹簧参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">R1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R1"
                          placeholder="请输入地弹簧参数R1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">R2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R2"
                          placeholder="请输入地弹簧参数R2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'FloorSpring')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地弹簧 -->
                </el-tab-pane>
                <el-tab-pane label="地弹簧2" name="six">
                  <!-- 地弹簧 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/FloorSpring2.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >地弹簧参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入地弹簧参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入地弹簧参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入地弹簧参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W"
                          placeholder="请输入地弹簧参数W"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入地弹簧参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入地弹簧参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入地弹簧参数R"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'FloorSpring2')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地弹簧 -->
                </el-tab-pane>
                <el-tab-pane label="地弹簧3" name="seven">
                  <!-- 地弹簧 -->
                  <!-- 位置和示意图 -->

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 左侧 -->
                        <img src="../assets/image/FloorSpring3.jpg" alt="" />
                        <!-- 左侧 -->
                      </div></el-col
                    >
                    <el-col :span="12"
                      ><div class="grid-content">
                        <!-- 右侧 -->
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="1"
                                >A</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="2"
                                >B</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="3"
                                >C</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          class="row-bg hole-lineH"
                          type="flex"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="4"
                                >D</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="5"
                                >E</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="6"
                                >F</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>
                        <el-row
                          type="flex"
                          class="row-bg hole-lineH"
                          justify="center"
                        >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="7"
                                >G</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                disabled
                                v-model="radio"
                                label="8"
                                >H</el-radio
                              >
                            </div></el-col
                          >
                          <el-col :span="8"
                            ><div class="grid-content hole-position">
                              <el-radio
                                @change="pationChange"
                                v-model="radio"
                                label="9"
                                >I</el-radio
                              >
                            </div></el-col
                          >
                        </el-row>

                        <!-- 右侧 -->
                      </div></el-col
                    >
                  </el-row>
                  <!--  圆形参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >地弹簧参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">D：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="D"
                          placeholder="请输入地弹簧参数D"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H"
                          placeholder="请输入地弹簧参数H"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">H1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="H1"
                          placeholder="请输入地弹簧参数H1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">R：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="R"
                          placeholder="请输入地弹簧参数R"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W1：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W1"
                          placeholder="请输入地弹簧参数W1"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">W2：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="W2"
                          placeholder="请输入地弹簧参数W2"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <!--  偏移参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >偏移参数设置</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">X：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="X"
                          placeholder="X"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">Y：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input
                          v-model="Y"
                          placeholder="Y"
                          :disabled="true"
                        ></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  水刀参数 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">其它</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-checkbox v-model="checked">采用水刀</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                  <!--  添加按钮 -->
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="drawLevelOne('Corner', 'FloorSpring3')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地弹簧 -->
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>

            <el-tab-pane label="异形" v-model="activeName">
              <el-tabs
                :tab-position="tabPosition"
                v-model="activeName"
                @tab-click="handleClickSpecialShaped"
                style="height: 80vh"
              >
                <el-tab-pane label="楼梯" name="first">
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <img
                          src="../assets/image/specialShaped.jpg"
                          alt=""
                        /></div
                    ></el-col>
                    <el-col :span="12">
                      <el-row>                       
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >矩形外框尺寸</el-divider
                        >
                      </div>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <div class="grid-content">W：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="specialShapedRect.w" placeholder="W"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">H：</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content">
                        <el-input v-model="specialShapedRect.h" placeholder="H"></el-input>
                      </div>
                    </el-col>
                  </el-row>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >底边起始坐标</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="6">
                      <div class="grid-content">X(始)：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="bottomLinePoint.startP.x" placeholder="坐标点X"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">Y(始)：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="bottomLinePoint.startP.y" placeholder="坐标点Y"></el-input>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <div class="grid-content">X(终)：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="bottomLinePoint.endP.x" placeholder="坐标点X"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">Y(终)：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="bottomLinePoint.endP.y" placeholder="坐标点Y"></el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >从左到右输入点坐标</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
          <el-col :span="4">
                      <div class="grid-content">序号</div>
                    </el-col>
                     <el-col :span="5">
                      <div class="grid-content inputPadding">X值</div>
                    </el-col>                   
                   <el-col :span="5">
                     <div class="grid-content inputPadding">Y值</div>
                   </el-col>                   
                   <el-col :span="10">
                     <div class="grid-content inputPadding"><el-button type="success" size="mini"  @click="dialogStairFloorVisible = true">楼层</el-button> <el-button type="success" size="mini"   @click="dialogStairAddVisible = true">添加</el-button></div>
                   </el-col>                    
        </el-row>
        <!-- <el-divider></el-divider> -->
                  <div
      v-for="(item,index) in brokenLinePoint" :key="item.id"
    style="width: 100%">
       
       
    <el-row>        
                   <el-col :span="4">
                    <div class="grid-content"><span>{{index+1}}</span></div>
                    </el-col>            
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input  
                        :ref="`input${index}`"         
                        v-model="item.x" placeholder="坐标点X"></el-input>
                      </div>
                    </el-col>                   
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input  @keyup.enter.native="handleEnterPoint(index)" v-model="item.y" placeholder="坐标点Y"></el-input>
                      </div>
                    </el-col> 
                    <el-col :span="10">
                      <div class="grid-content">
                        <el-button class="btnTop" size="mini" @click="delPoint(index)" type="danger">删除</el-button>
                      </div>
                    </el-col>
                  </el-row>
  </div>
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left">操作</el-divider>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <div class="grid-content">圆角：</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content">
                        <el-input v-model="roundCorner" placeholder="圆角值"></el-input>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="grid-content">
                        <el-button
                          type="primary"
                          icon="el-icon-plus"
                          @click="creatStairDraw('SpecialShaped', 'StairCase')"
                          >添加图形</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 底部FOOTER -->
    <div class="footers">
      <div class="foot_lf">Copyright &copy; 2013-2053 营口经济技术开发区贵友软件开发有限公司　  
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo"> 
          <img src="../assets/image/gonganwang.png" width="12" height="12"/> 辽公网安备21080402111341号　 </a>  
          <a target="_blank" href="http://beian.miit.gov.cn">辽ICP备2022000450号-2</a><br />
    </div>
    </div>
    <!-- 底部FOOTER -->
    <!-- 矩形尺寸参数设置弹出框 -->
    <div id="svgToImg" class="draw" hidden></div>
    <!-- 保留生成缩略图用 -->
    <div>
      <el-dialog
        title="玻璃尺寸设置"
        :visible.sync="dialogFormVisible"
        width="24%"
        center
      >
        <el-form :model="form">
          <!-- <el-form-item label="长度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassW" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="宽度Y(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassH" autocomplete="off"></el-input>
          </el-form-item> -->
          <el-form-item label="厚度(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>

        <!--20240828 <el-row>
                    <el-col :span="24">
                      <div class="grid-content">
                        <el-divider content-position="left"
                          >示意图及区域位置选择</el-divider
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"
                      ><div class="grid-content">
                        <img
                          :src="imageTrapezoidSrc"
                          alt=""
                        /></div
                    ></el-col>
                    <el-col :span="12"
                      ><div class="grid-content txtHeight">
                        <el-row>
                          <el-col :span="12" class=""> 选择梯形类型</el-col>
                        </el-row>
                        <el-row>
                          <el-select @change="selTrapezoidChanged"
                            v-model="spacelTrapezoidOptionsValue"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in spacelTrapezoidOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-row></div
                    ></el-col>
                  </el-row> -->

        <div class="block">
          <component
            :is="currenGlassFrameComponent"
            @sedX="recieveMsg"
            :my-post="myPost"
          ></component>

          <el-row>
            <el-col :span="24">
              <div class="grid-content">
                <el-divider content-position="left"
                  >示意图及区域位置选择</el-divider
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><div class="grid-content">
                <img :src="imageGlassFrameSrc" alt="" /></div
            ></el-col>
            <el-col :span="12"
              ><div class="grid-content txtHeight">
                <el-row>
                  <el-col :span="12" class=""> 选择外框类型</el-col>
                </el-row>
                <el-row>
                  <el-cascader
                    v-model="glassFrameOptionsValue"
                    :options="glassFrameOptions"
                    :props="{ expandTrigger: 'hover' }"
                    @change="selGlassFrameChanged"
                  ></el-cascader>
                </el-row></div
            ></el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              creatShapeBorder('special-shaped', currenGlassFrameComponent)
            "
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!-- /**登录窗口 */ -->

    <!-- 矩形尺寸参数设置弹出框 -->
    <!-- 矩形尺寸修改参数弹出框 -->
    <div>
      <el-dialog
        title="玻璃尺寸修改"
        :visible.sync="changeGlassVisible"
        width="18%"
        center
      >
        <el-form :model="form">
          <el-form-item label="长度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassW" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="宽度Y(mm)" :label-width="formLabelWidth">
            <el-input v-model="form.glassH" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="厚度(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changeGlassVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeSetRect">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 矩形尺寸修改参数弹出框 -->
    <!-- 系统设置参数弹出框 -->
    <div>
      <el-dialog
        title="系统设置"
        :visible.sync="systemSetVisible"
        width="18%"
        center
      >
        <el-form>
          <el-form-item label="字体" :label-width="formLabelWidth">
            <el-select v-model="renderOptions.fontSize" placeholder="请选择">
              <el-option
                v-for="item in SystemSetFontSizeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线条粗细" :label-width="formLabelWidth">
            <el-select v-model="renderOptions.strokeWidth" placeholder="请选择">
              <el-option
                v-for="item in SystemSetStrokeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图形放大倍数" :label-width="formLabelWidth">
            <el-select v-model="zoomM" placeholder="请选择">
              <el-option
                v-for="item in zoomOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="systemSetVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveSystemSet">保 存</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 系统设置参数弹出框 -->
    <!-- 厚度参数弹出框 -->
    <div>
      <el-dialog
        title="玻璃厚度设置"
        :visible.sync="thicknessSetVisible"
        width="18%"
        center
      >
        <el-form>
          <el-form-item label="厚度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="thicknessSetVisible = false">取 消</el-button>
          <el-button type="primary" @click="changSetThickness">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 厚度参数弹出框 changSetUserMdThickness-->
    <!-- 模板自用库厚度设置弹出窗口 -->
    <!-- 厚度参数弹出框 -->
    <div>
      <el-dialog
        title="模板自用库玻璃厚度设置"
        :visible.sync="thicknessUserMdSetVisible"
        width="18%"
        center
      >
        <el-form>
          <el-form-item label="厚度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassUserMdThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="thicknessUserMdSetVisible = false">取 消</el-button>
          <el-button type="primary" @click="changSetUserMdThickness">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 模板自用库厚度设置弹出窗口 -->
    <!-- 上传信息弹出框 -->
    <div>
      <el-dialog
        title="模板信息"
        :visible.sync="saveJsonDataVisible"
        width="25%"
        center
      >
        <el-form :model="jsonForm">
          <el-form-item label="模板名称：" :label-width="formUpJsonLableWidth">
            <el-input
              v-model="jsonForm.MdObjName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="模板类型：" :label-width="formUpJsonLableWidth">
            <el-radio-group v-model="jsonForm.MdObjType">
              <el-radio :label="1">孔</el-radio>
              <el-radio :label="2">缺</el-radio>
              <el-radio :label="3">角</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="示意图：" :label-width="formUpJsonLableWidth">
            <!-- <el-button type="primary" @click="creatImg" icon="el-icon-edit"></el-button> -->
            <el-image
              style="width: 200px; height: 200px"
              :src="upLoadImgURL"
            ></el-image>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="saveJsonDataVisible = false">取 消</el-button>
          <el-button type="primary" @click="sendDataToPHP">立即创建</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 上传信息弹出框 -->
 <!-- 20250102异形模板库窗口 -->
 <div>
      <el-drawer
        title="异形模板库"
        :visible.sync="MdAbnormalShapeWin"
        direction="ltr"
        size="100%"
      >
      <el-container>
  <el-aside >
      <div  v-for="(item) in MDASMenulabel"
                      :key="item.id">
        <el-card shadow="hover" style="width: 95%;" class="asideCard">
          <el-row>
          <!-- <div style="width: 95%; display: inline-block;"> -->
        
            <el-image
      style="width: 50px; height: 50px"
      :src="item.iconUrl"
      :fit="fit"></el-image>
          <!-- </div> --> <span>{{item.title}}</span>
        </el-row>
         
        </el-card>
      </div>
    <!-- <el-menu default-active="1" class="el-menu-vertical-demo"
    active-text-color="#ffd04b" 
    @open="handleOpenLabelMenu" @close="handleCloseLabelMenu">
      <div  v-for="(item) in MDASMenulabel"
                      :key="item.id">
        <el-submenu :index="item.id">
      <template slot="title">
      <i :class="item.iconUrl"></i>
      <span slot="title">{{item.title}}</span>
    </template>
    <el-menu-item-group>
      <img :src="item.iconUrl" alt="" />    
    </el-menu-item-group>    
  </el-submenu>
  </div>
</el-menu> -->
  </el-aside>
  <el-main>
    <div v-if="mainMdShow">
      <el-row :gutter="20">
  <el-col :span="20"> 
    <div class="mdashape-img-container">
                    <div v-for="(item, index) in MainImg" :key="item.id">
                      <div  class="mdashape-img-item" >  
                        <img                      
                          :class="{ holesActiveImg: index === activeImg }"
                           class="showimgstyle"
                          :src="item.imgUrl"
                          @click="mdImgClick(index)"                          
                        />  
                        <ul class="span-title">{{item.title}}</ul>
                        </div>                        
                    </div>                   
                  </div>  </el-col>
  <el-col :span="4" class="bgParma">
    <div class="parmaRect">
    <div class="grid-content bg-purple-dark">
    <div>
  <el-input placeholder="800" v-model="input1">
    <template slot="prepend">B  (B>T)　</template>
  </el-input>
</div>
</div>
    <div class="grid-content bg-purple-dark">
    <div>
  <el-input placeholder="800" v-model="input1">
    <template slot="prepend">B  (B>T)　</template>
  </el-input>
</div>
</div>
    <div class="grid-content bg-purple-dark">
    <div>
  <el-input placeholder="800" v-model="input1">
    <template slot="prepend">B  (B>T)　</template>
  </el-input>
</div>
</div>
    <div class="grid-content bg-purple-dark">
    <div>
  <el-input placeholder="800" v-model="input1">
    <template slot="prepend">B  (B>T)　</template>
  </el-input>
</div>
</div>
    <div class="grid-content bg-purple-dark">
    <div>
  <el-input placeholder="800" v-model="input1">
    <template slot="prepend">B  (B>T)　</template>
  </el-input>
</div>
</div>
    <div class="grid-content bg-purple-dark">
    <div>
  <el-input placeholder="800" v-model="input1">
    <template slot="prepend">B  (B>T)　</template>
  </el-input>
</div>
</div>
</div>
<div class="imgRect"><img src="../assets/image/SingleHole.jpg" /></div>
</el-col>
</el-row>

                 
                  
                  

       </div>
       <div v-else="">
                  <el-empty :image-size="200"></el-empty>
                </div>
  </el-main>
</el-container>
      
      </el-drawer>
    </div>
    <!-- 20250102异形模板库窗口 -->

    <!-- 模板库窗口 -->
    <div>
      <el-drawer
        title="模板图库"
        :visible.sync="MdWin"
        direction="rtl"
        size="80%"
      >
        <el-tabs type="border-card" v-model="activeMD">
          <el-tab-pane name="userMd" label="自用库">
            <el-tabs
              :tab-position="tabUserMdPosition"
              v-model="activeUserMD"
              @tab-click="handleUserMdClickGap"
              style="height: 1200px"
            >
              <el-tab-pane name="1" label="孔">
                <div v-if="holesMdShow">
                  <div class="holes-img-container">
                    <div
                      v-for="(item, index) in getMdDataDraw"
                      :key="item.userMdID"
                    >
                      <div  class="holes-img-item" >                                          
                      <el-image                      
                          :class="{ holesActiveImg: index === activeImg }"                                                                      
                          class="showimgstyle"
                          :src="item.userMdUrl"
                          @click="mdImgClick(index)"
                          fit="contain"                          
                        >
                      </el-image>                      
                        <span class="demonstration holes-img-bottom">{{
                          item.userMdName
                        }}</span><br>                      
                        <span class="demonstration holes-img-bottom"><el-button type="text" @click="delMdImgClick(item.userMdID,item.userMdType,index)"><i class="el-icon-delete"></i>删除</el-button></span>
                      </div>
                    </div>                   
                  </div>
                  <el-divider>
                    <span class="holes-img-parmSet" >— 参数设置 —</span>
                  </el-divider>
                  <el-row :gutter="20" class="setParamH">
                    <el-col :span="6">
                      <el-form
                        status-icon
                        :model="inputLoadParam"
                        ref="inputLoadParam"
                        label-width="200px"
                        class="demo-ruleForm"
                      >
                        <!-- <div v-for="(valA, keyA, i) in inputLoadParam" :key="i">
                          <div>{{ paramName[keyA.split("-")[0]] }}参数</div>
                          <div v-for="(valB, keyB, j) in valA" :key="j">
                            <el-form-item
                              :label="
                                paramName[keyA.split('-')[0]] + ':' + keyB
                              "
                              :prop="keyB"
                            >
                              <el-input
                                v-model="valA[keyB]"
                                :placeholder="valB"
                                @input="changeMdUpdataMsg"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div> -->

                        <el-collapse v-model="activeUserMdNames" @change="handleCollapseChange"  accordion>
  <el-collapse-item v-for="(valA, keyA, i) in inputLoadParam" :key="i" :title="paramName[keyA.split('-')[0]] + '参数:'" :name="i">
    <div v-for="(valB, keyB, j) in valA" :key="j">
                            <el-form-item
                              :label="
                                paramName[keyA.split('-')[0]] + ':' + keyB
                              "
                              :prop="keyB"
                            >
                              <el-input
                                v-model="valA[keyB]"
                                :placeholder="valB"
                                @input="changeMdUpdataMsg"
                              ></el-input>
                            </el-form-item>
                          </div>
  </el-collapse-item>
</el-collapse>

                        <el-form-item class="btnUserMdReSet">
                          <el-button @click="resetUserMdForm(inputLoadParam)"
                            >重置</el-button
                          >
                          <!-- <el-button type="primary" @click="submitUserMdForm(inputLoadParam)">修改</el-button> -->
                        </el-form-item>
                      </el-form>
                      <!-- </div> -->
                    </el-col>
                    <el-col :span="18">
                      <div class="exportMenu">
                        <el-row class="block-col-2">
                          <el-col :span="2">
                            <el-button
                              class="imgUserMdMenu"
                              type="text"
                              @click="loadMdPng(activeBigImgUrl)"
                              ><i icon="el-icon-edit"></i>图片<i
                                class="el-icon-picture-outline"
                              ></i
                            ></el-button>                        
                          </el-col>
                          <el-col :span="2" class="imgUserMdDrop">
                            <el-dropdown @command="handleHolesUserMdCommand">
                              <span class="el-dropdown-link">
                                孔模板<i
                                  class="el-icon-arrow-down"
                                ></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  icon="el-icon-plus"
                                  command="boliang"
                                  >博亮</el-dropdown-item
                                >
                                <el-dropdown-item
                                  icon="el-icon-circle-plus"
                                  command="jingrui"
                                  >精锐</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-col>
                          <el-col :span="2" class="imgUserMdDrop">
                            <el-dropdown @command="exUserMdWaterJet">
                              <span class="el-dropdown-link">
                                水刀<i
                                  class="el-icon-arrow-down el-icon--right"
                                ></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-plus"
                                command="yifeng"
                                  >亦丰</el-dropdown-item
                                >
                                <el-dropdown-item icon="el-icon-circle-plus"
                                command="borui"
                                  >玻锐</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-col>
                          <el-col :span="2">
                              <el-button
                              class="imgUserMdMenu"
                              type="text"
                              @click="glassUserMdCut()"
                              ><i icon="icon-drawqiegeji1"></i>切割机<i
                                class="icon-drawqiegeji1"
                              ></i
                            ></el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <div class="grid-content bg-purple">
                        <div class="holes-img-parmSet-imgs">
                          <span class="demonstration div-cursor">{{ activeBigSize.X }}*{{ activeBigSize.Y }}</span>
                          <div class="showImgBig">
                          <el-image 
                            style="width:auto;height:auto;"
                            :src="activeBigImgUrl"
                            fit="contain-down"></el-image>
                          </div>
                        </div>
                      </div>
                      <!-- <el-divider>
                        <span class="holes-img-parmSet">— 选择导出文件 —</span>
                      </el-divider> -->
                      
                    </el-col>
                  </el-row>
                </div>
                <div v-else="">
                  <el-empty :image-size="200"></el-empty>
                </div>
              </el-tab-pane>
              <el-tab-pane name="2" label="缺">
                <div v-if="gapsMdShow">
                  <div class="holes-img-container">
                    <div
                      v-for="(item, index) in getMdDataDraw"
                      :key="item.userMdID"
                    >
                      <div
                        class="holes-img-item"
                      >
                        <el-image
                        :class="{ holesActiveImg: index === activeImg }"
                        @click="mdImgClick(index)"
                        class="showimgstyle"
                          :src="item.userMdUrl"
                          fit="contain"
                        ></el-image>
                        <span class="demonstration holes-img-bottom">{{
                          item.userMdName
                        }}</span><br>                      
                        <span class="demonstration holes-img-bottom"><el-button type="text" @click="delMdImgClick(item.userMdID,item.userMdType)"><i class="el-icon-delete"></i>删除</el-button></span>
                      </div>
                    </div>                   
                  </div>
                  <el-divider>
                    <span class="holes-img-parmSet" >— 参数设置 —</span>
                  </el-divider>
                  <el-row :gutter="20" class="setParamH">
                    <el-col :span="6">
                      <el-form
                        status-icon
                        :model="inputLoadParam"
                        ref="inputLoadParam"
                        label-width="200px"
                        class="demo-ruleForm"
                      >                      

                        <el-collapse v-model="activeUserMdNames" @change="handleCollapseChange"  accordion>
  <el-collapse-item v-for="(valA, keyA, i) in inputLoadParam" :key="i" :title="paramName[keyA.split('-')[0]] + '参数:'" :name="i">
    <div v-for="(valB, keyB, j) in valA" :key="j">
                            <el-form-item
                              :label="
                                paramName[keyA.split('-')[0]] + ':' + keyB
                              "
                              :prop="keyB"
                            >
                              <el-input
                                v-model="valA[keyB]"
                                :placeholder="valB"
                                @input="changeMdUpdataMsg"
                              ></el-input>
                            </el-form-item>
                          </div>
  </el-collapse-item>
</el-collapse>

                        <el-form-item class="btnUserMdReSet">
                          <el-button @click="resetUserMdForm(inputLoadParam)"
                            >重置</el-button
                          >
                          <!-- <el-button type="primary" @click="submitUserMdForm(inputLoadParam)">修改</el-button> -->
                        </el-form-item>
                      </el-form>
                      <!-- </div> -->
                    </el-col>
                    <el-col :span="18">
                      <div class="exportMenu">
                        <el-row class="block-col-2">
                          <el-col :span="2">
                            <el-button
                              class="imgUserMdMenu"
                              type="text"
                              @click="loadMdPng(activeBigImgUrl)"
                              ><i icon="el-icon-edit"></i>图片<i
                                class="el-icon-picture-outline"
                              ></i
                            ></el-button>                        
                          </el-col>
                          <el-col :span="2" class="imgUserMdDrop">
                            <el-dropdown @command="exUserMdWaterJet">
                              <span class="el-dropdown-link">
                                水刀<i
                                  class="el-icon-arrow-down el-icon--right"
                                ></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-plus"
                                command="yifeng"
                                  >亦丰</el-dropdown-item
                                >
                                <el-dropdown-item icon="el-icon-circle-plus"
                                command="borui"
                                  >玻锐</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-col>
                          <el-col :span="2">
                              <el-button
                              class="imgUserMdMenu"
                              type="text"
                              @click="glassUserMdCut()"
                              ><i icon="icon-drawqiegeji1"></i>切割机<i
                                class="icon-drawqiegeji1"
                              ></i
                            ></el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <div class="grid-content bg-purple">
                        <div class="block holes-img-parmSet-imgs">
                          <span class="demonstration div-cursor"
                            >{{ activeBigSize.X }}*{{ activeBigSize.Y }}</span
                          >
                          <div class="showImgBig">
                          <el-image 
                            style="width:auto;height:auto;"
                            :src="activeBigImgUrl"
                            fit="contain-down"></el-image>
                          </div>
                        </div>
                      </div>
                      <!-- <el-divider>
                        <span class="holes-img-parmSet">— 选择导出文件 —</span>
                      </el-divider> -->
                      
                    </el-col>
                  </el-row>
                </div>
                <div v-else="">
                  <el-empty :image-size="200"></el-empty>
                </div>
              </el-tab-pane>
              <el-tab-pane name="3" label="角">
                <div v-if="cornersMdShow">
                  <div class="holes-img-container">
                    <div                      
                      v-for="(item, index) in getMdDataDraw"
                      :key="item.userMdID"
                    >
                      <div
                        class="holes-img-item"
                      >
                        <el-image
                        :class="{ holesActiveImg: index === activeImg }"
                        @click="mdImgClick(index)"
                        class="showimgstyle"
                          :src="item.userMdUrl"
                          fit="contain"
                        ></el-image>
                        <span class="demonstration holes-img-bottom">{{
                          item.userMdName
                        }}</span><br>                      
                        <span class="demonstration holes-img-bottom"><el-button type="text" @click="delMdImgClick(item.userMdID,item.userMdType)"><i class="el-icon-delete"></i>删除</el-button></span>
                      </div>
                    </div>                   
                  </div>
                  <el-divider>
                    <span class="holes-img-parmSet" >— 参数设置 —</span>
                  </el-divider>
                  <el-row :gutter="20" class="setParamH">
                    <el-col :span="6">
                      <el-form
                        status-icon
                        :model="inputLoadParam"
                        ref="inputLoadParam"
                        label-width="200px"
                        class="demo-ruleForm"
                      >
                  <el-collapse v-model="activeUserMdNames" @change="handleCollapseChange"  accordion>
  <el-collapse-item v-for="(valA, keyA, i) in inputLoadParam" :key="i" :title="paramName[keyA.split('-')[0]] + '参数:'" :name="i">
    <div v-for="(valB, keyB, j) in valA" :key="j">
                            <el-form-item
                              :label="
                                paramName[keyA.split('-')[0]] + ':' + keyB
                              "
                              :prop="keyB"
                            >
                              <el-input
                                v-model="valA[keyB]"
                                :placeholder="valB"
                                @input="changeMdUpdataMsg"
                              ></el-input>
                            </el-form-item>
                          </div>
  </el-collapse-item>
</el-collapse>

                        <el-form-item class="btnUserMdReSet">
                          <el-button @click="resetUserMdForm(inputLoadParam)"
                            >重置</el-button
                          >
                          <!-- <el-button type="primary" @click="submitUserMdForm(inputLoadParam)">修改</el-button> -->
                        </el-form-item>
                      </el-form>
                      <!-- </div> -->
                    </el-col>
                    <el-col :span="18">
                      <div class="exportMenu">
                        <el-row class="block-col-2">
                          <el-col :span="2">
                            <el-button
                              class="imgUserMdMenu"
                              type="text"
                              @click="loadMdPng(activeBigImgUrl)"
                              ><i icon="el-icon-edit"></i>图片<i
                                class="el-icon-picture-outline"
                              ></i
                            ></el-button>                        
                          </el-col>
                          <el-col :span="2" class="imgUserMdDrop">
                            <el-dropdown @command="exUserMdWaterJet">
                              <span class="el-dropdown-link">
                                水刀<i
                                  class="el-icon-arrow-down el-icon--right"
                                ></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-plus"
                                command="yifeng"
                                  >亦丰</el-dropdown-item
                                >
                                <el-dropdown-item icon="el-icon-circle-plus"
                                command="borui"
                                  >玻锐</el-dropdown-item
                                >
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-col>
                          <el-col :span="2">
                              <el-button
                              class="imgUserMdMenu"
                              type="text"
                              @click="glassUserMdCut()"
                              ><i icon="icon-drawqiegeji1"></i>切割机<i
                                class="icon-drawqiegeji1"
                              ></i
                            ></el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <div class="showimgsmd grid-content bg-purple">
                        <div class="block holes-img-parmSet-imgs">
                          <span class="demonstration div-cursor"
                            >{{ activeBigSize.X }}*{{ activeBigSize.Y }}</span
                          >
                          <div class="showImgBig">
                          <el-image
                          lazy 
                            style="width:auto;height:600px;"
                            :src="activeBigImgUrl"
                            fit="scale-down"></el-image>
                          </div>
                        </div>
                      </div>
                      <!-- <el-divider>
                        <span class="holes-img-parmSet">— 选择导出文件 —</span>
                      </el-divider> -->
                      
                    </el-col>
                  </el-row>
                </div>
                <div v-else="">
                  <el-empty :image-size="200"></el-empty>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane name="sysMd" label="系统库">系统库</el-tab-pane>
        </el-tabs>
      </el-drawer>
    </div>
    <!-- 模板库窗口 -->
    <!-- 修改自用库模板玻璃尺寸 -->
    <div>
      <el-dialog
        title="模板玻璃尺寸修改"
        :visible.sync="changeMdRectWinVisible"
        width="18%"
        center
      >
        <el-form :model="activeBigSize">
          <el-form-item label="长度X(mm)" :label-width="formLabelWidth">
            <el-input v-model="activeBigSize.X" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="宽度Y(mm)" :label-width="formLabelWidth">
            <el-input v-model="activeBigSize.Y" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="厚度(mm)" :label-width="formLabelWidth">
            <el-input v-model="GlassMDThickness" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changeMdRectWinVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeMdRect">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 修改自用库模板玻璃尺寸 -->
<!-- 楼梯增加坐标点窗口20241223 -->
    <div>
      <el-dialog
        title="请从左到右输入每个点的坐标"
        :visible.sync="addStairPointWinVisible"
        width="18%"
        center
      >
      <div
      v-for="item in brokenLinePoint" :key="item.id"
    style="width: 100%">
        <el-row>
          <el-col :span="4">
                      <div class="grid-content">序号</div>
                    </el-col>
                     <el-col :span="5">
                      <div class="grid-content inputPadding">X值</div>
                    </el-col>                   
                   <el-col :span="5">
                     <div class="grid-content inputPadding">Y值</div>
                   </el-col>                   
                   <el-col :span="10">
                     <div class="grid-content inputPadding">操作</div>
                   </el-col>                    
        </el-row>
       
    <el-row>        
                   <el-col :span="4">
                    <div class="grid-content"><span>{{(item.id)*1+1}}</span></div>
                    </el-col>            
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input v-model="item.x" placeholder="坐标点X"></el-input>
                      </div>
                    </el-col>                   
                    <el-col :span="5">
                      <div class="grid-content">
                        <el-input v-model="item.y" placeholder="坐标点Y"></el-input>
                      </div>
                    </el-col> 
                    <el-col :span="10">
                      <div class="grid-content">
                        <el-button  size="mini">添加</el-button>
                        <el-button size="mini" type="danger">删除</el-button>
                      </div>
                    </el-col>
                  </el-row>
  </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addStairPointWinVisible = false">取 消</el-button>
          <el-button type="primary" @click="addStairPoint">确 定</el-button>
        </div>
      </el-dialog>
    </div>
<!-- 楼梯增加坐标点窗口20241223 -->
<!-- 楼梯层数选择 -->
<div>
  <el-dialog title="楼梯层数" :visible.sync="dialogStairFloorVisible" 
        width="10%"
        center> 
   
      <el-select v-model="addFloorNum" placeholder="请选择楼层">
    <el-option
      v-for="item in optionsFloor"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogStairFloorVisible = false">取 消</el-button>
    <el-button type="primary" @click="addFloor()">确 定</el-button>
  </div>
</el-dialog>
</div>
<!-- 楼梯层数选择 -->
<!-- 楼梯添加坐标输入框选择 -->
<div>
  <el-dialog title="添加输入坐标数量" :visible.sync="dialogStairAddVisible" 
        width="10%"
        center> 
        <el-input
  placeholder="数量"
  v-model="addPointNum"
  clearable>
</el-input>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogStairAddVisible = false">取 消</el-button>
    <el-button type="primary" @click="addPoint()">确 定</el-button>
  </div>
</el-dialog>
</div>
<!-- 楼梯添加坐标输入框选择 -->

  </div>
</template>
 <script>
// import mdDrawer from "../components/mdDrawer.vue";
import Rectangle from "../components/Rectangle/Rectangle.vue";
import IsoscelesTrapezoid from "../components/Trapezoid/IsoscelesTrapezoid.vue";
import RightAngledTrapezoid from "../components/Trapezoid/RightAngledTrapezoid.vue";
import OtherTrapezoidsA from "../components/Trapezoid/OtherTrapezoidsA.vue";
import OtherTrapezoidsB from "../components/Trapezoid/OtherTrapezoidsB.vue";
import ParallelogramA from "../components/Parallelogram/ParallelogramA.vue";
import ParallelogramB from "../components/Parallelogram/ParallelogramB.vue";
import makerjs from "makerjs";
import Cookies from "js-cookie";
export default {
  components: {
    Rectangle,
    IsoscelesTrapezoid,
    RightAngledTrapezoid,
    OtherTrapezoidsA,
    OtherTrapezoidsB,
    ParallelogramA,
    ParallelogramB,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      paramName: {
        SingleHole: "单孔",
        HangWheelHoleTwo: "吊轮孔2",
        HangWheelHoleThree: "吊轮孔3",
        HangWheelHoleTwoCol: "双吊轮孔列",
        HangWheelHoleTwoRow: "双吊轮孔排",
        HangHole: "晾物孔",
        HandleHoleTShaped: "拉手孔T字",
        DoubleFixHoles: "双固定孔",
        DoubleFixHolesCol: "双固定孔列",
        RelSuWheelHole: "相对吊轮孔",
        HandleHole: "拉手孔",
        RotatLockHole: "旋转锁孔",
        HandleHole3: "拉手孔3",
        HangWheelHoleCol: "吊轮孔列",
        Shaped7HandleHole: "7字拉手孔",
        KeyHole: "锁孔",
        ThreeHoles: "三孔A",
        ThreeHoles2: "三孔B",
        ThreeHoles3: "三孔C",
        ThreeHoles4: "三孔D",
        ButterflyGap: "蝴蝶缺",
        ButterflyGap2: "蝴蝶缺2",
        ButterflyGap3: "蝴蝶缺3",
        ButterflyGap4: "蝴蝶缺4",
        UShapeGap: "U型缺",
        StressHoleGap: "应力孔",
        RunwayGap: "跑道缺",
        SquareGap: "方缺",
        UShapeGap: "U型缺",
        RectAngleGap: "矩形缺",
        CopperClipGap: "铜夹",
        LongClipGap: "长条夹",
        EllipseGap: "椭圆",
        ObliqueAngle: "斜角",
        Fillet: "圆角",
        Corner: "方角",
        Fillet2: "圆角2",
        FloorSpring: "地弹簧",
        FloorSpring2: "地弹簧2",
        FloorSpring3: "地弹簧3",
        Rectangle: "矩形框",
        IsoscelesTrapezoid: "等腰梯形",
        RightAngledTrapezoid: "直角梯形",
        OtherTrapezoidsA: "梯形A框",
        OtherTrapezoidsB: "梯形B框",
        ParallelogramA: "平形四边形A框",
        ParallelogramB: "平形四边形B框",
      },
/**异形模板库相关参数 */
MdAbnormalShapeWin:false,
MDASMenulabel:[
{
  id:"0",
  title:"导航一",
  iconUrl:require("../assets/image/SingleHole.jpg")
},
{
  id:"1",
  title:"导航二",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航三",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航四",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航五",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航六",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航七",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航八",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航九",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航十",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
{
  id:"1",
  title:"导航十一",
  iconUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
},
],
MainImg:[
  {
  id:"0",
  title:"导航一",
  imgUrl:require("../assets/image/SingleHole.jpg")
},
{
  id:"1",
  title:"导航二",
  imgUrl:require("../assets/image/IsoscelesTrapezoid.jpg")
}],
mainMdShow:true,
/**异形模板库相关参数 */

/**楼梯相关参数 */
roundCorner:"2.5",//楼梯圆角值
optionsFloor: [{
          value: '1',
          label: '一层'
        }, {
          value: '2',
          label: '二层'
        }, {
          value: '3',
          label: '三层'
        }, {
          value: '4',
          label: '四层'
        }, {
          value: '5',
          label: '五层'
        }, {
          value: '6',
          label: '六层'
        }, {
          value: '7',
          label: '七层'
        }, {
          value: '8',
          label: '八层'
        }, {
          value: '9',
          label: '九层'
        }, {
          value: '10',
          label: '十层'
        }, {
          value: '11',
          label: '十一层'
        }, {
          value: '12',
          label: '十二层'
        }, {
          value: '23',
          label: '十三层'
        }],
addPointNum:"2",
addFloorNum:"2",
dialogStairFloorVisible:false,
dialogStairAddVisible:false,
specialShapedRect:{w:0,h:0},
addStairPointWinVisible:false,
bottomLinePoint:{
  startP:{x:0,y:0},  
  endP:{x:0,y:0},
},//底部直接开始，结束点坐标
brokenLinePoint:[{id:new Date().getTime() + (Math.random().toString(36)).substring(2),x:0,y:0}],//中间折线点坐标
/**楼梯相关参数 */

      /**模板窗口 */
      isRefresh:true,
      showImage: true,
      showMask: false,
      glassMDFrameParam:"",
      activeUserMdNames:[0], 
      userMdDxf:"",
      thicknessUserMdSetVisible:false,
      GlassUserMdThickness:"5" ,    
      holeUserMdD: "", //导出孔文件中的所有孔坐标 数组
      showUserMdBigImgH:"",
      userMdHolesBrand: "", //打孔机品牌
      userMdWaterJetBrand:"",//水刀品牌
      changeMdRectWinVisible: false,
      GlassMDThickness: "",
      userMdHolesNum: "",
      userMdPlate: { models: "" },
      userMdcutPlate: { models: "" },
      userMdmarkers: { models: "" }, //包含标记数据 给到SVG
      userMddxf: "",
      activeImg: "",
      holesMdShow: false,
      gapsMdShow: false,
      cornersMdShow: false,
      inputLoadParam: {},
      activeParam: [],
      activeBigSize: {
        X: "",
        Y: "",
      },
      userMdRect: "",
      testSvg: "",
      activeBigImgUrl: "",
      MdWin: false,
      activeMD: "userMd",
      tabUserMdPosition: "left", //自用库左右分
      activeUserMD: "1", //1:Hole  2:Gap 3:Corner
      /**孔模板 */
      mdParma: {
        userName: "",
        mdTpye: "1",
      },
      holesNum: 0,
      holesCount: 10,
      holesLoading: false,
      holesUrl: "../assets/images/girls.jpeg",
      mdName: [],
      getMdDataDraw: [],
      getDrawOp: [],
      downMdImgUrl: "",
      // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      /**孔模板 */

      mdWinActive: ["1"],

      /**模板窗口 */
      showImg: false,
      upLoadImgURL: "",
      limitCount: 1,
      uploadImgDisabled: false,
      addAddressRules: {
        comPhone: [
          { required: true, message: "请输入正确手机号", trigger: "blur" },
          { validator: checkPhone, trigger: ["blur", "change"] },
        ],
      },
      myPost: false,
      drawer: false,
      innerDrawer: false,
      //水刀基准点位置
      benchMark: "I",
      tabLabel: "",
      changeGlassVisible: false, //修改玻璃尺寸弹窗可见性
      //画基本图形相关参数
      /*
      楼梯相关参数
      */
      spacelOptions: [
        {
          value: "1",
          label: "3",
        },
        {
          value: "2",
          label: "4",
        },
        {
          value: "3",
          label: "5",
        },
        {
          value: "4",
          label: "6",
        },
        {
          value: "5",
          label: "7",
        },
      ],
      spacelOptionsValue: "",
      //外框形状选择
      glassFrameOptions: [
        {
          value: "0",
          Name: "Rectangle",
          label: "矩形",
          inputParam: {
            W: 0,
            H: 0,
          },
        },
        {
          value: "1",
          Name: "Trapezoid",
          label: "梯形",
          children: [
            {
              value: "0",
              Name: "IsoscelesTrapezoid",
              label: "等腰梯形",
              inputParam: {
                H: 0,
                W: 0,
                W1: 0,
              },
            },
            {
              value: "1",
              Name: "RightAngledTrapezoid",
              label: "直角梯形",
              inputParam: {
                H: 0,
                H1: 0,
                W: 0,
                W1: 0,
              },
            },
            {
              value: "2",
              Name: "OtherTrapezoidsA",
              label: "其它梯形A",
              inputParam: {
                H1: 0,
                H2: 0,
                W: 0,
                W1: 0,
                W2: 0,
              },
            },
            {
              value: "3",
              Name: "OtherTrapezoidsB",
              label: "其它梯形B",
              inputParam: {
                H: 0,
                W: 0,
                W1: 0,
                W2: 0,
              },
            },
          ],
        },
        {
          value: "2",
          Name: "Parallelogram",
          label: "平形四边形",
          children: [
            {
              value: "0",
              Name: "ParallelogramA",
              label: "平形四边形A",
              inputParam: {
                H: 0,
                W: 0,
                W1: 0,
              },
            },
            {
              value: "1",
              Name: "ParallelogramB",
              label: "平形四边形B",
              inputParam: {
                H: 0,
                W: 0,
                W1: 0,
                W2: 0,
              },
            },
          ],
        },
      ],
      glassFrameOptionsValue: "0",
      imgGlassFrameName: "Rectangle",
      currenGlassFrameComponent: "Rectangle",
      glassFrameParam: "",
      /*
      楼梯相关参数
      */
      /*梯形相关参数*/
      spacelTrapezoidOptions: [
        {
          value: "0",
          Name: "IsoscelesTrapezoid",
          label: "等腰梯形",
          inputParam: [],
        },
        {
          value: "1",
          Name: "RightAngledTrapezoid",
          label: "直角梯形",
          inputParam: [],
        },
        {
          value: "2",
          Name: "OtherTrapezoidsA",
          label: "其它梯形A",
          inputParam: [],
        },
        {
          value: "3",
          Name: "OtherTrapezoidsB",
          label: "其它梯形B",
          inputParam: [],
        },
      ],
      spacelTrapezoidOptionsValue: "0",
      imgTrapezoidName: "IsoscelesTrapezoid",
      currenTrapezoidComponent: "IsoscelesTrapezoid",
      /*梯形相关参数*/
      /*平形四边相关参数*/
      spacelParallelogramOptions: [
        {
          value: "0",
          Name: "ParallelogramA",
          label: "平形四边形A",
          inputParam: [],
        },
        {
          value: "1",
          Name: "ParallelogramB",
          label: "平形四边形B",
          inputParam: [],
        },
      ],
      spacelParallelogramOptionsValue: "0",
      imgParallelogramName: "ParallelogramA",
      currenParallelogramComponent: "ParallelogramA",
      /*平形四边相关参数*/
      holeD: "", //导出孔文件中的所有孔坐标 数组
      toERPParams: false, //标记是否有数据从ERP传过来
      setSysStorage: {
        fontSize: "16px",
      }, //需要缓存数据
      SystemSetStrokeOptions: [
        {
          value: "0.3mm",
          label: "0.3mm",
        },
        {
          value: "0.6mm",
          label: "0.6mm",
        },
        {
          value: "1.0mm",
          label: "1.0mm",
        },
        {
          value: "1.2mm",
          label: "1.2mm",
        },
        {
          value: "1.5mm",
          label: "1.5mm",
        },
      ],
      SystemSetFontSizeOptions: [
        {
          value: "16px",
          label: "16px",
        },
        {
          value: "18px",
          label: "18px",
        },
        {
          value: "20px",
          label: "20px",
        },
        {
          value: "22px",
          label: "22px",
        },
        {
          value: "24px",
          label: "24px",
        },
        {
          value: "28px",
          label: "28px",
        },
        {
          value: "32px",
          label: "32px",
        },
        {
          value: "36px",
          label: "36px",
        },
        {
          value: "42px",
          label: "42px",
        },
        {
          value: "48px",
          label: "48px",
        },
      ],
      zoomM: 1,
      zoomOptions: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      isLoadCanvas: false,
      stateIndex: 0,
      isDrawUndo: true,
      isDrawRedo: true,
      isClearSvg: true,
      canvasState: [],
      //初始化固定参数不会发生变化
      disabled: false, //区域位置是否可选择性 禁用
      //矩形框相关参数
      dialogFormVisible: false,
      //矩形框相关参数
      systemSetVisible: false,
      thicknessSetVisible: false, //厚度参数设置弹窗
      formLabelWidth: "100px",
      //矩形框相关参数
      springOffset: 0,
      direction: "rtl",
      tabPosition: "left",
      //x、y标记偏移位置 孔
      markShiftF: 5, //矩形偏移量 第一层偏移量（最靠近矩形框的里层）
      markShift: 10, //矩形偏移量 第一层偏移量（最靠近矩形框的里层）
      markShiftTow: 15, //矩形偏移量 第二层偏移量（最靠近矩形框的里层）
      markShiftTwo: 15, //矩形偏移量 第二层偏移量（最靠近矩形框的里层）
      markShiftThree: 20, //矩形偏移量 第三层偏移量（最靠近矩形框的里层）
      markShiftFour: 25, //矩形偏移量 第四层偏移量（最靠近矩形框的里层）
      //圆形直径标注线加长设置 孔
      markHolesOneD: 15,

      /*撤销和恢复/反撤销操作*/
      myStep: 0,
      history: [], // 存储历史记录
      future: [], // 存储未来的操作记录
      currentState: {}, // 当前状态  Initial state 初始状态
      operationHistory: [], // 存储历史记录的参数记录
      operationFuture: [], // 存储未来的参数记录
      operationCurrent: {}, // 当前状态  Initial state 初始参数状态
      /*撤销和恢复/反撤销操作*/
      showX: false,
      showY: false,
      activeName: "first",
      form: {
        glassW: "", //长度
        glassH: "", //宽度
      },
      //区域位置选择单选
      radio: "7",
      //三孔不同类型选择单选
      threeHoleType: "A",
      //三孔示意图参数
      threeHolePic: require("../assets/image/ThreeHoles.jpg"),
      //三孔调用函数名称变化参数
      threeHoleFn: "creatThreeHoles",
      //水刀是否选择
      checked: false,
      //X、Y偏移值 初始值
      X: 0,
      Y: 0,
      D: 0,
      R: 0,
      Rx: 0,
      Ry: 0,
      R1: 0,
      R2: 0,
      D1: 0,
      D2: 0,
      H: 0,
      H1: 0,
      H2: 0,
      W: 0,
      W1: 0,
      W2: 0,
      //画图相关参数
      myHolesNum: 0, //孔的数量
      plate: "", //只有路径数据 供转换DXF格式使用
      markers: "", //包含标记数据 给到SVG
      dxf: "",
      MyJSON: {
        MdUserName: "",
        MdObjJsons: {
          plate: "", //初始只包含图 不包含出入刀线等信息
          cutPlate: "", // 包含出入刀线 和水刀相关
          markers: "", //包含标注的图片信息
          Rect: "", //玻璃尺寸
          operationHistory: "",
          frame:"",
        },
        MdObjName: "",
        MdObjUrl: "",
      },
      /****************/
      draw: "",
      svg: "",
      metaParameters: [
        { title: "width", type: "range", min: 1, max: 200, value: 50 },
        { title: "height", type: "range", min: 1, max: 100, value: 30 },
        { title: "hole radius", type: "range", min: 0, max: 10, value: 3 },
        { title: "rim", type: "range", min: 1, max: 20, value: 2 },
        { title: "fillet", type: "range", min: 0, max: 10, value: 5 },
      ], //画图参数
      operationHistory: [], //操作记录
      operationParam: "",
      dxfOptions: {
        //  units:'4',
      },
      renderOptions: {
        accuracy: 0.01,
        // origin: [0,0],
        strokeLineCap: "round",
        annotate: true,
        flow: { size: 8 },
        units: makerjs.unitType.Millimeter,
        svgAttrs: {
          id: "myDrawing",
          // style: "padding-left:" + 10 + "px; padding-top:" + 10 + "px",
        },
        strokeWidth: "0.3mm",
        fontSize: "16px",
        scale: 1,
        useSvgPathOnly: true,
        viewBox: true,
      },
      renderOptionsImg: {
        accuracy: 0.01,
        // origin: [0,0],
        strokeLineCap: "round",
        annotate: true,
        flow: { size: 8 },
        units: makerjs.unitType.Millimeter,
        svgAttrs: {
          id: "myImgs",
          // style: "padding-left:" + 10 + "px; padding-top:" + 10 + "px",
        },
        strokeWidth: "0.3mm",
        fontSize: "16px",
        scale: 1,
        useSvgPathOnly: true,
        viewBox: true,
      },
      //todxf option
      /**
       * 对接水刀相关参数
       */
      isWaterJet: false,
      θCut: 45, //出入刀线角度，默认45
      rCut: 2.5, //出入刀线 切线圆的半径
      cutPlate: "",
      cutParam: {
        θCut: 45, //出入刀线角度，默认45
        rCut: 2.5, //出入刀线 切线圆的半径
      }, //水刀相关参数
      /**
       * 对接水刀相关参数
       */
      /**
       * 对接博亮打孔机 参数
       */
      GlasslD: "GYID001", //玻璃ID
      Pieces: "1", //   未知
      GlassThickness: "5", //玻璃厚度
      gyName: "", //操作人员
      gySerNum: "", //服务器序号
      gySerCusName: "", //客户服务器名称
      HolesBrand: "", //打孔机品牌

      /**
       * 对接博亮打孔机 参数
       */
      gyErpParam: { OrderChildId: "", Name: "", Thick: "" },
      gyErpUrl: [
        "http://www.gyglass.cn/ghscan",
        "https://www.xd475.com/ghscan",
        "https://www.gy429.cn/ghscan",
        "https://www.gyxd475.cn/ghscan",
        "https://www.xd475.cn/ghscan",
        "https://www.w35db.cn/ghscan",
        "https://www.w35fb.cn/ghscan",
        "https://www.wn35fb.cn/ghscan",
        "https://www.wn35db.cn/ghscan",
        "https://www.gy429.com/ghscan",
        "https://www.gy065.cn/ghscan",
        "https://www.gy066.cn/ghscan",
      ],
      upUrl: "",
      // frame:"Rectangle"
      /**登录相关 */
      logSetVisible: false, //登录窗口
      logForm: {
        comName: "",
        comPhone: "",
        comPassCode: "",
      },
      /**登录相关 */
      /**上传模板数据弹窗 */
      jsonForm: {
        MdObjName: "",
        MdObjType: 1,
      },
      saveJsonDataVisible: false,
      imageUrl: "",
      formUpJsonLableWidth: "120px",
      /**上传模板数据弹窗 */
      frame:"Rectangle",
    };
  },
  computed: {
    mdWinSetParamChange(val) {
      console.log(val);
    },

    /**孔模板加载图 */
    holesNoMore() {
      return this.holesCount >= 20;
    },
    holesDisabled() {
      return this.holesLoading || this.holesNoMore;
    },
    /**孔模板加载图 */

    imageGlassFrameSrc() {
      return require(`@/assets/image/${this.imgGlassFrameName}.jpg`);
    },

    /**梯形示意图动态调用图片*/
    imageTrapezoidSrc() {
      // 使用require动态导入图片
      return require(`@/assets/image/${this.imgTrapezoidName}.jpg`);
      //this.selectParallelogramID=this.selectParallelogramID
    },

    /**平形四边形示意图动态调用图片*/

    /**平形四边形示意图动态调用图片*/
    imageParallelogramSrc() {
      // 使用require动态导入图片
      return require(`@/assets/image/${this.imgParallelogramName}.jpg`);
    },

    /**梯形示意图动态调用图片*/

    canUndo() {
      console.log("this.stateIndex > 0=", this.stateIndex > 0);
      return this.stateIndex > 0;
    },
    canRedo() {
      console.log("this.stateIndex =", this.stateIndex);
      console.log("this.history.length - 1=", this.history.length - 1);
      return this.stateIndex < this.myStep;
    },
    cachedUserList() {
      // 如果缓存中已有数据，直接返回缓存数据
      if (localstorage.getItem("setSysStorage")) {
        return JsON.parse(localstorage.getItem("setSysStorage"));
      } else {
        //如果缓存中没有数据，发送网络请求获取数据
        //并将数据缓存到本地存储
        /*
axios.get("/api/userList").then((res)=>{
this.userList =res.data;
localstorage.setItem("userList",JsoN.stringify(res.data));});
return this.userList;
*/
      }
    },
  },
  mounted() {
    //创建SVG画布
    //this.$myutils.demos.demo(30,50,15);
    /**
 mounted() {
    const params = new URLSearchParams(window.location.search);
    let name = params.get('name'); // John
    let age = params.get('age'); // 30
    console.log(name, age);
  }
  */
    ////获取URL地址参数
    //http://localhost:8082/index.html?id=gy123&gyLen=800&gyWid=600&gyThick=8&gyName=8&gySer=9_ycyss2024
    const params = new URLSearchParams(window.location.search);
    //toERPParams
    console.log("params.size=", params.size);
    if (params.size == 0) {
      this.toERPParams = false;
    } else if (params.size > 0) {
      this.toERPParams = true;
      let id = params.get("id"); // ID
      this.GlasslD = id;
      console.log("id=", id);
      console.log("this.GlasslD=", this.GlasslD);
      //比较 值大的为长度，小的为宽度。

      let gyLen = params.get("gyLen"); // gyLen
      let gyWid = params.get("gyWid"); // gyWid
      if (gyLen * 1 >= gyWid * 1) {
        console.log("长=长");
        this.form.glassW = gyLen; // gyLen
        this.form.glassH = gyWid; // gyWid
      } else {
        this.form.glassW = gyWid; // gyLen
        this.form.glassH = gyLen; // gyWid
      }
      let gyThick = params.get("gyThick"); // gyThick
      let gyName = params.get("gyName"); // gyName
      let gySer = params.get("gySer"); // gySer
      this.GlassThickness = gyThick;
      this.gySer = gySer;
      this.gyName = gyName;
      let arr = gySer.split("_");
      //跨域
      /*************/
      /*************/
      // this.gySerNum=arr[0];//服务器序号
      // this.gySerCusName=arr[1];//客户服务器名称
      // this.upUrl='/nine/ghscan'+this.gySerCusName+'/ghscan.asmx/GetOkFromHuaTu';
      /*************/
      /*************/
      //同域
      /*************/
      /*************/
      let index = arr[0] - 1;
      let upSer = arr[1];
      this.upUrl = this.gyErpUrl[index] + upSer + "/ghscan.asmx/GetOkFromHuaTu";
      /*************/
      /*************/
      //var UPURL='/api'+this.gySerNum+'/ghscan'+this.gySerCusName+'/ghscan.asmx/GetOkFromHuaTu';
    }
  },
  

  methods: {
////////////异形模板库
openAbnormalShapedLibrary(){
this.MdAbnormalShapeWin=true;
},
handleOpenLabelMenu(key, keyPath){
  console.log(key, keyPath);
},
handleCloseLabelMenu(key, keyPath){
  console.log(key, keyPath);
},

////////////异形模板库
//楼梯相关函数方法
///局部刷新函数

/******楼梯相关函数*/
/**打开添加坐标窗口
 * 可见性控制
 */
openAddStairPointWin(){
  this.addStairPointWinVisible=true;
},
//添加坐标窗口，确定按钮，
/**
*确定后图完成，添加坐标可实时改变图的形状
*
*/
addStairPoint(){
  this.addStairPointWinVisible=false;
},
addPoint(){ 
  this.dialogStairAddVisible=false;  
// addPointNum:"2",
// addFloorNum:"2",
 console.log("this.addPointNum=",this.addPointNum);
 console.log("this.addFloorNum=",this.addFloorNum);
  for(let i=0;i<this.addPointNum;i++){
  var obj={id:new Date().getTime() + (Math.random().toString(36)).substring(2),x:0,y:0};
    this.brokenLinePoint.push(obj);
  }  
  console.log("this.brokenLinePoint==",this.brokenLinePoint);
},
addFloor(){
this.dialogStairFloorVisible=false;
// addPointNum:"2",
// addFloorNum:"2",
var nums=this.addFloorNum*2+2;
  for(let i=1;i<nums;i++){
var obj={id:new Date().getTime() + (Math.random().toString(36)).substring(2),x:0,y:0};
    this.brokenLinePoint.push(obj);
  }   
console.log("this.brokenLinePoint==",this.brokenLinePoint);
},
handleEnterPoint(index){
  var obj={id:new Date().getTime() + (Math.random().toString(36)).substring(2),x:0,y:0};
  this.brokenLinePoint.push(obj);   
  // console.log("index==",`input${index+1}`); 
  // console.log("this.$refs==",this.$refs);
  // var input="input"+(index+1);
  // console.log("input=",input);
  // console.log("this.$refs[input]=",this.$refs[input]);
  // setTimeout(() => {
  //     if (this.$refs[input]["0"]) {
  //       this.$refs[input]["0"].focus();
  //     } 
  //     }, 500);
   
  // var obj={id:id*1+1,x:0,y:0};
  // var newId=obj.id
  // console.log("newId",newId);
  // this.brokenLinePoint.push(obj);
  
  
},

delPoint(id){
  this.brokenLinePoint.splice(id,1);
},
creatStairDraw(type,shape){
  //creatStairDraw('SpecialShaped', 'StairCase')"
  //roundCorner
  console.log("type==",type);
  console.log("shape==",shape);
  var brokenLinePoint=JSON.parse(JSON.stringify(this.brokenLinePoint));
  var inputPoint={...this.bottomLinePoint,brokenLinePoint};
console.log("inputPoint==",inputPoint);
var model = {
        plate: this.plate,
        markers: this.markers,
        cutPlate: this.cutPlate,
      };      
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };
  var myobj = this.$myutils[shape](
          this.roundCorner,
          inputPoint,
          model,
          fixedParam,
          this.zoomM,
        );
        console.log("myobj==",myobj);
        console.log("myobj.cutPlate.models==",myobj.cutPlate.models);        
        var w=this.specialShapedRect.w,h=this.specialShapedRect.h,maker=myobj.cutPlate.models;
        if(w==0||h==0){
this.myOpenMsg("矩形外框尺寸不能为0","贵友提示您：")
        }else{
          this.creatDraw(
        myobj.markers,
        myobj.plate,
        myobj.cutPlate,
        w,
        h
      );
      this.plate = this.deepCopy(myobj.plate);
      this.markers = this.deepCopy(myobj.markers);
      this.cutPlate = this.deepCopy(myobj.cutPlate);
      this.myHolesNum = this.deepCopy(myobj.myHolesNum);
        }        
},
 
//以上为楼梯相关函数方法
     //输入数字返回区域字母
     changeArea(num){
      var areaPoint;
      switch(num){
     case "1": areaPoint = "A";break;
     case "2": areaPoint = "B";break;
     case "3": areaPoint = "C";break;
     case "4": areaPoint = "D";break;
     case "5": areaPoint = "E";break;
     case "6": areaPoint = "F";break;
     case "7": areaPoint = "G";break;
     case "8": areaPoint = "H";break;
     case "9": areaPoint = "I";break;
   }
   return areaPoint;
    },
    //多层级对象属性值去掉双引号。
    removeQuotesDeep(obj) {
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        try {
          const parsed = JSON.parse(obj[key]);
          obj[key] = parsed;
        } catch (e) {
          // 不是有效的JSON，或者解析过程中抛出错误，保持原样
        }
      } else if (typeof obj[key] === 'object') {
        this.removeQuotesDeep(obj[key]);
      }
    });
  }
  return obj;
},
//返回属性数量
    objHasOwnProperty(obj) {
      var count = 0;
      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          count++;
        }
      }
      return count;
    },
    /**模板窗口 */
    handleUserMdClickGap(tab, event){
      if(tab.label=="孔"){
        this.mdParma.mdTpye=1;
      }else if(tab.label=="缺"){
        this.mdParma.mdTpye=2;
      }else if(tab.label=="角"){
        this.mdParma.mdTpye=3;
      }
      console.log("this.mdParma.mdTpye=",this.mdParma.mdTpye);
      this.openMdWin(this.mdParma.mdTpye);
      // mdParma: {
      //   userName: "",
      //   mdTpye: "",
      // },
      console.log("tab.label==", tab.label);
      console.log("handleUserMdClickGap==///////////tab//////////",tab);
      console.log("handleUserMdClickGap==///////////event//////////",event);
    },

    handleCollapseChange(val) {
        console.log("val=",val);

      },
//自用模板志出切割机文件
glassUserMdCut(){
  var i=this.activeImg;
  var rectW=this.activeBigSize.X,rectH=this.activeBigSize.Y;
      console.log("this.getMdDataDraw=", JSON.parse(JSON.stringify(this.getMdDataDraw))[i].cutPlate.models);
      var obj=  JSON.parse(JSON.stringify(this.getMdDataDraw[i].cutPlate)); 
     this.userMdDxf = makerjs.exporter.toDXF(this.removeQuotesDeep(obj));     
      if (this.objIsEmpty(this.getMdDataDraw[i].cutPlate.models)) {
        this.myOpenMsg("图形为空，请先画图。", "提示：");
      } else {
        //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
        var arrs = [];
        console.log("******************");
        //var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
        var strings =
          "0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n" +
          rectW +
          "\n20\n" +
          rectH +
          "\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

        var splitStr = "0\nSECTION\n2\nENTITIES\n";
        if (this.benchMark == "G") {
          var splitStrP = "0\nPOINT\n8\n0\n10\n0\n20\n0\n"; //加入基点坐标
        } else if (this.benchMark == "I") {
          var splitStrP =
            "0\nPOINT\n8\n0\n10\n" + rectW + "\n20\n0\n"; //加入基点坐标
        }
        var arrs = this.userMdDxf.split(splitStr);
        var arrStr = splitStr + splitStrP;
        console.log("arrStr=", arrStr);
        console.log("arrs[0]=", arrs[0], "arrs[1]=", arrs[1]);
        this.userMdDxf = strings + arrStr + arrs[1];
        console.log("this.userMdDxf******=", this.userMdDxf);
        this.saveUserMdDXF();
      }
},
//自用模板导出水刀格式
saveUserMdDXF(){
  var fileNames = this.creatDate();
      var blob = new Blob([this.userMdDxf]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);

      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames + ".dxf"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);
},
saveUserMdWaterJetYiFeng(){
  console.log("亦丰水刀");
  // getMdDataDraw
  var i=this.activeImg;
  var rectW=this.activeBigSize.X,rectH=this.activeBigSize.Y;
      console.log("this.getMdDataDraw=", JSON.parse(JSON.stringify(this.getMdDataDraw))[i].cutPlate.models);
      var obj=  JSON.parse(JSON.stringify(this.getMdDataDraw[i].cutPlate)); 
     this.userMdDxf = makerjs.exporter.toDXF(this.removeQuotesDeep(obj));     
      if (this.objIsEmpty(this.getMdDataDraw[i].cutPlate.models)) {
        this.myOpenMsg("图形为空，请先画图。", "提示：");
      } else {
        //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
        var arrs = [];
        console.log("******************");
        //var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
        var strings =
          "0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n" +
          rectW +
          "\n20\n" +
          rectH +
          "\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

        var splitStr = "0\nSECTION\n2\nENTITIES\n";
        if (this.benchMark == "G") {
          var splitStrP = "0\nPOINT\n8\n0\n10\n0\n20\n0\n"; //加入基点坐标
        } else if (this.benchMark == "I") {
          var splitStrP =
            "0\nPOINT\n8\n0\n10\n" + rectW + "\n20\n0\n"; //加入基点坐标
        }
        var arrs = this.userMdDxf.split(splitStr);
        var arrStr = splitStr + splitStrP;
        console.log("arrStr=", arrStr);
        console.log("arrs[0]=", arrs[0], "arrs[1]=", arrs[1]);
        this.userMdDxf = strings + arrStr + arrs[1];
        console.log("this.userMdDxf******=", this.userMdDxf);
        this.saveUserMdDXF();
      }
},
saveUserMdWaterJetBoRui(){  
  console.log("玻锐水刀");
  // getMdDataDraw
  var i=this.activeImg;
  var rectW=this.activeBigSize.X,rectH=this.activeBigSize.Y;
      console.log("this.getMdDataDraw=", JSON.parse(JSON.stringify(this.getMdDataDraw))[i].cutPlate.models);
      var obj=  JSON.parse(JSON.stringify(this.getMdDataDraw[i].cutPlate)); 
     this.userMdDxf = makerjs.exporter.toDXF(this.removeQuotesDeep(obj));     
      if (this.objIsEmpty(this.getMdDataDraw[i].cutPlate.models)) {
        this.myOpenMsg("图形为空，请先画图。", "提示：");
      } else {
        //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
        var arrs = [];
        console.log("******************");
        //var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
        var strings =
          "0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n" +
          rectW +
          "\n20\n" +
          rectH +
          "\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

        var splitStr = "0\nSECTION\n2\nENTITIES\n";
        if (this.benchMark == "G") {
          var splitStrP = "0\nPOINT\n8\n0\n10\n0\n20\n0\n"; //加入基点坐标
        } else if (this.benchMark == "I") {
          var splitStrP =
            "0\nPOINT\n8\n0\n10\n" + rectW + "\n20\n0\n"; //加入基点坐标
        }
        var arrs = this.userMdDxf.split(splitStr);
        var arrStr = splitStr + splitStrP;
        console.log("arrStr=", arrStr);
        console.log("arrs[0]=", arrs[0], "arrs[1]=", arrs[1]);
        this.userMdDxf = strings + arrStr + arrs[1];
        console.log("this.userMdDxf******=", this.userMdDxf);
        this.saveUserMdDXF();
      }
},
exUserMdWaterJet(command) { 
      console.log("exUserMdWaterJet=");
  this.userMdWaterJetBrand = command;
      if (command == "yifeng") {
        this.saveUserMdWaterJetYiFeng();
      } else if (command == "borui") {
        this.saveUserMdWaterJetBoRui();
      }     
 },

////////////////

delMdImgClick(id,type,index){
console.log("测试id===",id);
var delParama={userMdID:0,userMdName:""};
const base = process.env.NODE_ENV === "development" ? "/api" : "";
      var myURLToJson = base + "/getData/delMdData.php";
      var token = Cookies.get("token");
      var dataName = JSON.parse(token);
      console.log("token.username==", dataName.username);
      delParama.userMdName = dataName.username;
      delParama.userMdID = id;
      console.log("delParama==", delParama);
this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios
        .post(myURLToJson, delParama, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {          
          console.log("res==", res);
          if (res.data.flag == "1") {
            console.log("测试删除成功！")
            this.$message({
          message: '删除成功',
          type: 'success'
        }); 
        if(this.activeImg==index){
console.log("测试是当前选中模板")
// this.getMdDataDraw.splice([index],1);
console.log("this.inputLoadParam",this.inputLoadParam)
console.log("this.activeImg",this.activeImg)
var num =this.getMdDataDraw.length,indexs=index*1+1;
console.log("num==",num);
console.log("index==",index);
this.getMdDataDraw.splice([index],1);
this.getDrawOp.splice([index],1); 
if(num==indexs){
  this.mdImgClick(index-1);
}else{
  this.mdImgClick(this.activeImg);
}

        }else{          
console.log("非选中模板&&&&&&")  
var num =this.getMdDataDraw.length;
console.log("num==",num); 
console.log("index==",index);     
        this.getMdDataDraw.splice([index],1);
this.getDrawOp.splice([index],1);  
        
        }        
       // this.downMdData();           
          } else if (res.data.flag == "2") {
            this.$message.error(res.data.info);
          }
          // console.log("res.data22==",JSON.parse(datas));
        })
        .catch((error) => {
          // 处理错误
          console.error("error=", error);
        });

          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

   
},

    //自用模板库精锐打孔机
    saveHolesUserMdJingrui() {
      var count=this.objHasOwnProperty(this.inputLoadParam);
      if (count <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else{
        console.log("自用模板库精锐打孔机");
        var arrH = [];
        var rectW=this.activeBigSize.X,rectH=this.activeBigSize.Y;
      var arrH = [];
     var activeParam = [];
     activeParam=this.getDrawOp[this.activeImg]; 
     console.log("activeParam",activeParam);    
     var exOprationParam = JSON.parse(JSON.stringify(this.inputLoadParam));     
     console.log("exOprationParam",exOprationParam);    
      // getMdDataDraw
      // inputLoadParam
      for (let key in activeParam) {
        if (activeParam.hasOwnProperty(key)) {
          // 确保key是对象自身的属性
          let value = activeParam[key];
          console.log("key",key);
          console.log("value",value);
          console.log("value.type",value.type);
          console.log("value.area",value.area);
          console.log("value.shape",value.shape);
          console.log("exOprationParam[key]",exOprationParam[key]);
          // var propName=this.activeParam[key].shape+"-"+key;
          exOprationParam[key]["type"] = value.type;
          exOprationParam[key]["area"] = value.area;
          exOprationParam[key]["shape"] = value.shape;
        }
        
      }
      delete exOprationParam["Rectangle"];     
      console.log("exOprationParam=",exOprationParam);  
      console.log("exOprationParam.length=",exOprationParam.length);
      console.log("this.getMdDataDraw=", this.getMdDataDraw);
      console.log("this.getDrawOp.length=", this.getDrawOp.length);
      for (let key in exOprationParam) {
                if (exOprationParam.hasOwnProperty(key)) {
                  // 确保key是对象自身的属性
                  let value = exOprationParam[key];
                  console.log(value.type + "*******: *****" + value.type);
          if (value.type == "Hole") {
            /** 孔*/
            if (value.shape == "SingleHole") {
              /**
               * 单孔
               *
               */
              if (value.area * 1 == 1) {
                var Hx = (value.X * 1).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 3) {
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 4) {
                var Hx = (value.X * 1).toFixed(0);
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 5) {
                var Hx = ((rectW * 1) / 2).toFixed(0);
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 6) {
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hx = (value.X * 1).toFixed(0);
                var Hy = (value.Y * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hx = ((rectW * 1) / 2).toFixed(0);
                var Hy = (value.Y * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 9) {
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy = (value.Y * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 单孔
               *
               *
               */
            } else if (value.shape == "HangWheelHoleTwo") {
              console.log("吊轮孔2");
              /**
               * 吊轮孔2
               *
               */
              if (value.area * 1 == 4) {
                console.log("吊轮孔22222");
                var Hx = (value.X * 1).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 6) {
                console.log("吊轮孔22222");
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 吊轮孔2
               *
               *
               */
            } else if (value.shape == "HangWheelHoleThree") {
              /**
               * 吊轮孔3
               *
               */
              if (value.area * 1 == 4) {
                var Hx = (value.X * 1).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = ((rectH * 1) / 2).toFixed(0);
                var Hy3 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 吊轮孔3
               *
               *
               */
            } else if (value.shape == "HangWheelHoleTwoCol") {
              /**
               * 4、双吊轮孔列
               *
               */
              if (value.area * 1 == 4) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.H * 1 +
                  value.X * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);

                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 6) {
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);

                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 4、双吊轮孔列
               *
               *
               */
            } else if (value.shape == "HangWheelHoleTwoRow") {
              /**
               * 5、双吊轮孔排
               *
               */
              if (value.area * 1 == 4) {
                var Hx = (value.X * 1).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  +(value.W * 1) +
                  value.Y * 1
                ).toFixed(0);
                var Hy3 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy4 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy4 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 5、双吊轮孔排
               *
               *
               */
            } else if (value.shape == "HangHole") {
              /**
               * 6、晾物孔
               *
               */
              if (value.area * 1 == 1) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  value.X * 1 +
                  value.H1 * 1 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 3) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1 -
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  value.X * 1 +
                  value.H1 * 1 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 9) {
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1 -
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 6、晾物孔
               *
               *
               */
            } else if (value.shape == "HandleHoleTShaped") {
              /**
               * 7、拉手孔T字
               *
               */
              if (value.area * 1 == 1) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  value.X * 1 +
                  value.H1 * 1 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 3) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1 -
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  value.X * 1 +
                  value.H1 * 1 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 9) {
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H1 * 1 -
                  value.H2 * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 7、拉手孔T字
               *
               *
               */
            } else if (value.shape == "DoubleFixHoles") {
              /**
               * 8、双固定孔
               *
               */
              if (value.area * 1 == 1) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 3) {
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 4) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 5) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 6) {
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy = (value.Y * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy = (value.Y * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 9) {
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy = (value.Y * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (value.shape == "DoubleFixHolesCol") {
              /**
               * 8、双固定孔列
               *
               */
              if (value.area * 1 == 1) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx = (value.X * 1).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx = ((rectW * 1) / 2).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 3) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 4) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx = (value.X * 1).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 5) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx = ((rectW * 1) / 2).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 6) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx = (value.X * 1).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx = ((rectW * 1) / 2).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 9) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hd = (value.D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (value.shape == "RelSuWheelHole") {
              /**
               * 9、相对吊轮孔
               *
               */
              if (value.area * 1 == 4) {
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy3 = (
                  value.Y * 1 +
                  value.W * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy4 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hy5 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hy6 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hx = (value.X * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy4 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy5 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy6 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 9、相对吊轮孔
               *
               *
               */
            } else if (value.shape == "HandleHole") {
              /**
               * 10、拉手孔
               *
               */
              if (value.area * 1 == 2) {
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx = ((rectW * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 4) {
                var Hx = (value.X * 1).toFixed(0);
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 6) {
                var Hx = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx = ((rectW * 1) / 2).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 10、拉手孔
               *
               *
               */
            } else if (value.shape == "RotatLockHole") {
              /**
               * 11、旋转锁孔
               *
               */
              if (value.area * 1 == 1) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 11、旋转锁孔
               *
               *
               */
            } else if (value.shape == "HandleHole3") {
              /**
               * 12、拉手孔3
               *
               */
              if (value.area * 1 == 1) {
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hx3 = (
                  value.X * 1 +
                  value.H * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 2) {
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.H * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 3) {
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hx3 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hy = (value.Y * 1).toFixed(0);
                var Hx1 = (
                  value.X * 1 +
                  value.H * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hx3 = (value.X * 1).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 8) {
                var Hy = (value.Y * 1).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.H * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 9) {
                var Hy = (value.Y * 1).toFixed(0);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hx3 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.H * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 12、拉手孔3
               *
               *
               */
            } else if (value.shape == "HangWheelHoleCol") {
              /**
               * 13、吊轮孔列
               *
               */
              if (value.area * 1 == 1) {
                var Hy = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 4) {
                var Hy = ((rectH * 1) / 2).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (value.area * 1 == 7) {
                var Hy = (value.Y * 1).toFixed(0);
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hd = (value.D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 13、吊轮孔列
               *
               *
               */
            } else if (value.shape == "Shaped7HandleHole") {
              /**
               * 14、7字拉手孔
               *
               */
              if (value.area * 1 == 1) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(0);
              } else if (value.area * 1 == 2) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(0);
              } else if (value.area * 1 == 3) {
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(0);
              } else if (value.area * 1 == 7) {
                var Hx1 = (value.X * 1).toFixed(0);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(0);
              } else if (value.area * 1 == 8) {
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(0);
              } else if (value.area * 1 == 9) {
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(0);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(0);
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(0);
              }

              var Hd = (value.D * 1).toFixed(0);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 14、7字拉手孔
               *
               *
               */
            } else if (value.shape == "KeyHole") {
              /**
               * 15、锁孔
               *
               */
              if (value.area * 1 == 2) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(0);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.W * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.W * 1
                ).toFixed(0);
              } else if (value.area * 1 == 8) {
                var Hy1 = (value.Y * 1).toFixed(0);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(0);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  value.W * 1
                ).toFixed(0);
                var Hx2 = ((rectW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (rectW * 1) / 2 +
                  value.W * 1
                ).toFixed(0);
              }

              var Hd1 = (value.D1 * 1).toFixed(0);
              var Hd2 = (value.D2 * 1).toFixed(0);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd1 +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd2 +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx3 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd1 +
                "mm\n" +
                "P=0\n";

              /**
               * 15、锁孔
               *
               *
               */
            } else if (value.shape == "ThreeHoles") {
              /**
               * 16、三孔
               *
               */
              if (value.area * 1 == 1) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (value.X * 1).toFixed(1);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 2) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 3) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 4) {
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx1 = (value.X * 1).toFixed(1);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 5) {
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 6) {
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 7) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (value.X * 1).toFixed(1);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 8) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 9) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              }

              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 16、三孔
               *
               *
               */
            } else if (value.shape == "ThreeHoles2") {
              /**
               * 17、三孔2
               *
               */
              if (value.area * 1 == 1) {
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (value.X * 1).toFixed(1);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 2) {
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 3) {
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 4) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx1 = (value.X * 1).toFixed(1);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 5) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 6) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 7) {
                var Hy2 = (value.Y * 1).toFixed(1);
                var Hy1 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (value.X * 1).toFixed(1);
                var Hx2 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 8) {
                var Hy2 = (value.Y * 1).toFixed(1);
                var Hy1 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 9) {
                var Hy2 = (value.Y * 1).toFixed(1);
                var Hy1 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              }

              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 17、三孔2
               *
               *
               */
            } else if (value.shape == "ThreeHoles3") {
              /**
               * 18、三孔3
               *
               */
              if (value.area * 1 == 1) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (value.X * 1).toFixed(1);
                var Hx1 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 2) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 3) {
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 4) {
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx2 = (value.X * 1).toFixed(1);
                var Hx1 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 5) {
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 6) {
                var Hy2 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 7) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (value.X * 1).toFixed(1);
                var Hx1 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 8) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 9) {
                var Hy1 = (value.Y * 1).toFixed(1);
                var Hy2 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              }

              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 18、三孔3
               *
               *
               */
            } else if (value.shape == "ThreeHoles4") {
              /**
               * 19、三孔4
               *
               */
              if (value.area * 1 == 1) {
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (value.X * 1).toFixed(1);
                var Hx1 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 2) {
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 3) {
                var Hy2 = (
                  rectH * 1 -
                  value.Y * 1
                ).toFixed(1);
                var Hy1 = (
                  rectH * 1 -
                  value.Y * 1 -
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 4) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx2 = (value.X * 1).toFixed(1);
                var Hx1 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 5) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 6) {
                var Hy1 = (
                  (rectH * 1) / 2 +
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (rectH * 1) / 2 -
                  (value.H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 7) {
                var Hy2 = (value.Y * 1).toFixed(1);
                var Hy1 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (value.X * 1).toFixed(1);
                var Hx1 = (
                  value.X * 1 +
                  value.W * 1
                ).toFixed(1);
              } else if (value.area * 1 == 8) {
                var Hy2 = (value.Y * 1).toFixed(1);
                var Hy1 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  (rectW * 1) / 2 -
                  (value.W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (rectW * 1) / 2 +
                  (value.W * 1) / 2
                ).toFixed(1);
              } else if (value.area * 1 == 9) {
                var Hy2 = (value.Y * 1).toFixed(1);
                var Hy1 = (
                  value.Y * 1 +
                  value.H * 1
                ).toFixed(1);
                var Hx2 = (
                  rectW * 1 -
                  value.X * 1
                ).toFixed(1);
                var Hx1 = (
                  rectW * 1 -
                  value.X * 1 -
                  value.W * 1
                ).toFixed(1);
              }

              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 19、三孔4
               *
               *
               */
            }            
            arrH.push(holesDatas);
            /** */
          }
        }
      }
        this.holeUserMdD = arrH.join("");
        console.log("arrH===", arrH);
        console.log("arrH.length", arrH.length);
        console.log("this.holeUserMdD.length", this.holeUserMdD.length);
        console.log("this.holeUserMdD", this.holeUserMdD);
        var arr = this.holeUserMdD.split("P=0\n");
        arr = arr.slice(0, arr.length - 1);
        console.log("arr", arr);
        console.log("arr.length", arr.length);
        var arrHole = [];
        for (let i = 0; i < arr.length; i++) {
          var j = i * 1 + 1;
          const targetString = "Hole";
          const replacementString = "Hole" + j;
          const regex = new RegExp(targetString, "g");
          const arrs = arr[i];
          var str = arrs.replace(regex, replacementString);
          console.log("j=", j);
          arrHole.push(str);
        }
        //this.holeD=
        // console.log("arrDate", arr);
        console.log("arrHole=", arrHole);
        this.holeUserMdD = arrHole.join("");
        if (arrHole.length == 0) {
          this.myOpenMsg("您画的图中无孔，导出无效，请确认！", "提示：");
        } else {
          this.thicknessUserMdSetVisible = true;
        }
      }
    },
    //自用模板库博亮打孔机
    saveHolesUserMdBoliang() {
      console.log("****模板导出博亮打孔机格式。");
      var count=this.objHasOwnProperty(this.inputLoadParam);
      if (count <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else{
        var rectW=this.activeBigSize.X,rectH=this.activeBigSize.Y;
      var arrH = [];
     var activeParam = [];
     activeParam=this.getDrawOp[this.activeImg]; 
     console.log("activeParam",activeParam);    
     var exOprationParam = JSON.parse(JSON.stringify(this.inputLoadParam));     
     console.log("exOprationParam",exOprationParam);    
      // getMdDataDraw
      // inputLoadParam
      for (let key in activeParam) {
        if (activeParam.hasOwnProperty(key)) {
          // 确保key是对象自身的属性
          let value = activeParam[key];
          console.log("key",key);
          console.log("value",value);
          console.log("value.type",value.type);
          console.log("value.area",value.area);
          console.log("value.shape",value.shape);
          console.log("exOprationParam[key]",exOprationParam[key]);
          // var propName=this.activeParam[key].shape+"-"+key;
          exOprationParam[key]["type"] = value.type;
          exOprationParam[key]["area"] = value.area;
          exOprationParam[key]["shape"] = value.shape;
        }
        
      }
      delete exOprationParam["Rectangle"];     
      console.log("exOprationParam=",exOprationParam);  
      console.log("exOprationParam.length=",exOprationParam.length);
      console.log("this.getMdDataDraw=", this.getMdDataDraw);
      console.log("this.getDrawOp.length=", this.getDrawOp.length);
      for (let key in exOprationParam) {
                if (exOprationParam.hasOwnProperty(key)) {
                  // 确保key是对象自身的属性
                  let value = exOprationParam[key];
                  console.log(value.type + "*******: *****" + value.type);
                  if(value.type=="Hole"){
                    if (value.shape == "SingleHole") {
            /**
             * 单孔
             *
             */
            if (value.area * 1 == 1) {
              var Hx = (value.X * 1).toFixed(1);
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 3) {
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 4) {
              var Hx = (value.X * 1).toFixed(1);
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 5) {
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 6) {
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hx = (value.X * 1).toFixed(1);
              var Hy = (value.Y * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hy = (value.Y * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            } else if (value.area * 1 == 9) {
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy = (value.Y * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
            }

            /**
             * 单孔
             *
             *
             */
          } else if (value.shape == "HangWheelHoleTwo") {
            console.log("吊轮孔2");
            /**
             * 吊轮孔2
             *
             */
            if (value.area * 1 == 4) {
              console.log("吊轮孔22222");
              var Hx = (value.X * 1).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 6) {
              console.log("吊轮孔22222");
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 吊轮孔2
             *
             *
             */
          } else if (value.shape == "HangWheelHoleThree") {
            /**
             * 吊轮孔3
             *
             */
            if (value.area * 1 == 4) {
              var Hx = (value.X * 1).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = ((rectH * 1) / 2).toFixed(1);
              var Hy3 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy3 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 吊轮孔3
             *
             *
             */
          } else if (value.shape == "HangWheelHoleTwoCol") {
            /**
             * 4、双吊轮孔列
             *
             */
            if (value.area * 1 == 4) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.H * 1 +
                value.X * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);

              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 6) {
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 4、双吊轮孔列
             *
             *
             */
          } else if (value.shape == "HangWheelHoleTwoRow") {
            /**
             * 5、双吊轮孔排
             *
             */
            if (value.area * 1 == 4) {
              var Hx = (value.X * 1).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                +(value.W * 1) +
                value.Y * 1
              ).toFixed(1);
              var Hy3 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy4 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy3 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy4 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 5、双吊轮孔排
             *
             *
             */
          } else if (value.shape == "HangHole") {
            /**
             * 6、晾物孔
             *
             */
            if (value.area * 1 == 1) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                value.X * 1 +
                value.H1 * 1 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.H1 * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 3) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1 -
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                value.X * 1 +
                value.H1 * 1 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.H1 * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 9) {
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1 -
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 6、晾物孔
             *
             *
             */
          } else if (value.shape == "HandleHoleTShaped") {
            /**
             * 7、拉手孔T字
             *
             */
            if (value.area * 1 == 1) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                value.X * 1 +
                value.H1 * 1 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.H1 * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 3) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1 -
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                value.X * 1 +
                value.H1 * 1 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.H1 * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 9) {
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1
              ).toFixed(1);
              var Hx3 = (
                rectW * 1 -
                value.X * 1 -
                value.H1 * 1 -
                value.H2 * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 7、拉手孔T字
             *
             *
             */
          } else if (value.shape == "DoubleFixHoles") {
            /**
             * 8、双固定孔
             *
             */
            if (value.area * 1 == 1) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 3) {
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 4) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 5) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 6) {
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy = (value.Y * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy = (value.Y * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 9) {
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy = (value.Y * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 8、双固定孔
             *
             *
             */
          } else if (value.shape == "DoubleFixHolesCol") {
            /**
             * 8、双固定孔列
             *
             */
            if (value.area * 1 == 1) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx = (value.X * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 3) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 4) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx = (value.X * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 5) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 6) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx = (value.X * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 9) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 8、双固定孔
             *
             *
             */
          } else if (value.shape == "RelSuWheelHole") {
            /**
             * 9、相对吊轮孔
             *
             */
            if (value.area * 1 == 4) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy3 = (
                value.Y * 1 +
                value.W * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy4 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1 -
                value.W * 1
              ).toFixed(1);
              var Hy5 = (
                rectH * 1 -
                value.Y * 1 -
                value.W * 1
              ).toFixed(1);
              var Hy6 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hx = (value.X * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy3 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy4 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy5 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy6 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 9、相对吊轮孔
             *
             *
             */
          } else if (value.shape == "HandleHole") {
            /**
             * 10、拉手孔
             *
             */
            if (value.area * 1 == 2) {
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 4) {
              var Hx = (value.X * 1).toFixed(1);
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 6) {
              var Hx = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx = ((rectW * 1) / 2).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 10、拉手孔
             *
             *
             */
          } else if (value.shape == "RotatLockHole") {
            /**
             * 11、旋转锁孔
             *
             */
            if (value.area * 1 == 1) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 11、旋转锁孔
             *
             *
             */
          } else if (value.shape == "HandleHole3") {
            /**
             * 12、拉手孔3
             *
             */
            if (value.area * 1 == 1) {
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx3 = (
                value.X * 1 +
                value.H * 1 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 2) {
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 3) {
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx3 = (
                rectW * 1 -
                value.X * 1 -
                value.H * 1 -
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hy = (value.Y * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.H * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx3 = (value.X * 1).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 8) {
              var Hy = (value.Y * 1).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 9) {
              var Hy = (value.Y * 1).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx3 = (
                rectW * 1 -
                value.X * 1 -
                value.H * 1 -
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 12、拉手孔3
             *
             *
             */
          } else if (value.shape == "HangWheelHoleCol") {
            /**
             * 13、吊轮孔列
             *
             */
            if (value.area * 1 == 1) {
              var Hy = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 4) {
              var Hy = ((rectH * 1) / 2).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            } else if (value.area * 1 == 7) {
              var Hy = (value.Y * 1).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.H * 1
              ).toFixed(1);
              var Hd = (value.D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";
            }

            /**
             * 13、吊轮孔列
             *
             *
             */
          } else if (value.shape == "Shaped7HandleHole") {
            /**
             * 14、7字拉手孔
             *
             */
            if (value.area * 1 == 1) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
            } else if (value.area * 1 == 2) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
            } else if (value.area * 1 == 3) {
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
            } else if (value.area * 1 == 7) {
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
            } else if (value.area * 1 == 8) {
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
            } else if (value.area * 1 == 9) {
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
            }

            var Hd = (value.D * 1).toFixed(1);
            var holesDatas =
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy2 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n";

            /**
             * 14、7字拉手孔
             *
             *
             */
          } else if (value.shape == "KeyHole") {
            /**
             * 15、锁孔
             *
             */
            if (value.area * 1 == 2) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.W * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 8) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                value.W * 1
              ).toFixed(1);
              var Hx2 = ((rectW * 1) / 2).toFixed(1);
              var Hx3 = (
                (rectW * 1) / 2 +
                value.W * 1
              ).toFixed(1);
            }

            var Hd1 = (value.D1 * 1).toFixed(1);
            var Hd2 = (value.D2 * 1).toFixed(1);
            var holesDatas =
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd1 +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy2 +
              " " +
              "D=" +
              Hd2 +
              " " +
              "P=0\n" +
              "X=" +
              Hx3 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd1 +
              " " +
              "P=0\n";

            /**
             * 15、锁孔
             *
             *
             */
          } else if (value.shape == "ThreeHoles") {
            /**
             * 16、三孔
             *
             */
            if (value.area * 1 == 1) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 2) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 3) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 4) {
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 5) {
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 6) {
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 7) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 8) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 9) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            }

            var Hd = (value.D * 1).toFixed(1);
            var holesDatas =
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy2 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n";

            /**
             * 16、三孔
             *
             *
             */
          } else if (value.shape == "ThreeHoles2") {
            /**
             * 17、三孔2
             *
             */
            if (value.area * 1 == 1) {
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 2) {
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 3) {
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 4) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 5) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 6) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 7) {
              var Hy2 = (value.Y * 1).toFixed(1);
              var Hy1 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (value.X * 1).toFixed(1);
              var Hx2 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 8) {
              var Hy2 = (value.Y * 1).toFixed(1);
              var Hy1 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 9) {
              var Hy2 = (value.Y * 1).toFixed(1);
              var Hy1 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            }
            var Hd = (value.D * 1).toFixed(1);
            var holesDatas =
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy2 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n";

            /**
             * 17、三孔2
             *
             *
             */
          } else if (value.shape == "ThreeHoles3") {
            /**
             * 18、三孔3
             *
             */
            if (value.area * 1 == 1) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = (value.X * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 2) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 3) {
              var Hy1 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy2 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 4) {
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx2 = (value.X * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 5) {
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 6) {
              var Hy2 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy1 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 7) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (value.X * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 8) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 9) {
              var Hy1 = (value.Y * 1).toFixed(1);
              var Hy2 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            }

            var Hd = (value.D * 1).toFixed(1);
            var holesDatas =
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy2 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n";

            /**
             * 18、三孔3
             *
             *
             */
          } else if (value.shape == "ThreeHoles4") {
            /**
             * 19、三孔4
             *
             */
            if (value.area * 1 == 1) {
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = (value.X * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 2) {
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 3) {
              var Hy2 = (
                rectH * 1 -
                value.Y * 1
              ).toFixed(1);
              var Hy1 = (
                rectH * 1 -
                value.Y * 1 -
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 4) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx2 = (value.X * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 5) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 6) {
              var Hy1 = (
                (rectH * 1) / 2 +
                (value.H * 1) / 2
              ).toFixed(1);
              var Hy2 = (
                (rectH * 1) / 2 -
                (value.H * 1) / 2
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 7) {
              var Hy2 = (value.Y * 1).toFixed(1);
              var Hy1 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (value.X * 1).toFixed(1);
              var Hx1 = (
                value.X * 1 +
                value.W * 1
              ).toFixed(1);
            } else if (value.area * 1 == 8) {
              var Hy2 = (value.Y * 1).toFixed(1);
              var Hy1 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                (rectW * 1) / 2 -
                (value.W * 1) / 2
              ).toFixed(1);
              var Hx1 = (
                (rectW * 1) / 2 +
                (value.W * 1) / 2
              ).toFixed(1);
            } else if (value.area * 1 == 9) {
              var Hy2 = (value.Y * 1).toFixed(1);
              var Hy1 = (
                value.Y * 1 +
                value.H * 1
              ).toFixed(1);
              var Hx2 = (
                rectW * 1 -
                value.X * 1
              ).toFixed(1);
              var Hx1 = (
                rectW * 1 -
                value.X * 1 -
                value.W * 1
              ).toFixed(1);
            }

            var Hd = (value.D * 1).toFixed(1);
            var holesDatas =
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx1 +
              " " +
              "Y=" +
              Hy2 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n" +
              "X=" +
              Hx2 +
              " " +
              "Y=" +
              Hy1 +
              " " +
              "D=" +
              Hd +
              " " +
              "P=0\n";

            /**
             * 19、三孔4
             *
             *
             */
          }
          //console.log("i=", i);
          arrH.push(holesDatas);
                  }
                  
                }
                
              }
    
      this.holeUserMdD = arrH.join("");
      console.log("arrH===", arrH);
      console.log("arrH.length", arrH.length);
      console.log("this.holeUserMdD.length", this.holeUserMdD.length);
      if (arrH.length == 0) {
        this.myOpenMsg("您画的图中无孔，导出无效，请确认！", "提示：");
      } else {
        this.thicknessUserMdSetVisible = true;
      }
      }
     
    },
    // 自用模板库使用函数生成.holes文件
    changSetUserMdThickness() {
      // var rectW=this.activeBigSize.X,rectH=this.activeBigSize.Y;
      console.log("this.inputLoadParam typeof=",typeof(this.inputLoadParam));
      console.log("this.inputLoadParam.objHasOwnProperty=",this.objHasOwnProperty(this.inputLoadParam));
      var count=this.objHasOwnProperty(this.inputLoadParam);
      if (count == 1) {
        this.thicknessUserMdSetVisible = false;
      } else if (count > 1) {
        this.thicknessUserMdSetVisible = false;
        if (this.userMdHolesBrand == "boliang") {
          var headData = "[Glassspec]\n";
          var headID = "GlasslD=" + this.GlasslD + "\n";
          var headNThick =
            "GlassThickness=" +
            this.GlassUserMdThickness +
            "\n" +
            "Pieces=" +
            this.Pieces +
            "\n";
          //var rectL=`WID=${this.form.glassW}\nLEN=${this.form.glassH}\n[Holes]\n`;
          //var rectL="WID="+(this.form.glassW*1).toFixed(1)+"\nLEN="+(this.form.glassH*1).toFixed(1)+"\n[Holes]\n";
          var rectL =
            "WID=" +
            (this.activeBigSize.Y * 1).toFixed(1) +
            "\nLEN=" +
            (this.activeBigSize.X * 1).toFixed(1) +
            "\n[Holes]\n";
          //var holesData=`X=100.0 Y=200.0 D=10.0 P=0\n`; // 这里应该是你的.holes文件的内容
          var holesData = `${headData}${headID}${headNThick}${rectL}${this.holeUserMdD}`;
          console.log("holesData=", holesData);
          this.downloadHoles(
            this.GlasslD +
              "_" +
              this.GlassUserMdThickness.padStart(2, "0") +
              ".holes",
            holesData
          );
        } else if (this.userMdHolesBrand == "jingrui") {
          var headData = "[PicName]\n";
          var headID = "Name=001\n\n";
          var rectL =
            "[GlassWide]\nValue=" +
            (this.activeBigSize.X * 1).toFixed(1) +
            "\n\n[GlassHeight]\nValue=" +
            (this.activeBigSize.Y * 1).toFixed(1) +
            "\n\n[Holes]\n";
          var Depth = "[Depth]\nValue=\n";
          //var holesData=`X=100.0 Y=200.0 D=10.0 P=0\n`; // 这里应该是你的.holes文件的内容
          var holesData = `${headData}${headID}${rectL}${this.holeUserMdD}${Depth}`;
          console.log("holesData=", holesData);
          this.downloadHoles(
            this.GlasslD + "_" + this.GlassUserMdThickness.padStart(2, "0") + ".ini",
            holesData
          );
        }
        this.loadMdPng(this.activeBigImgUrl);
        if (this.toERPParams == true) {
          this.sendDataToERP();
        }
      }
    },
    handleHolesUserMdCommand(command) {
      //this.$message('click on item ' + command);
      this.userMdHolesBrand = command;
      if (command == "boliang") {
        this.saveHolesUserMdBoliang();
      } else if (command == "jingrui") {
        this.saveHolesUserMdJingrui();
      }
    },
    loadMdPng(base64srcFile) {
      var fileNames = this.creatDate();
      //     var svg = document.getElementById("myDrawing");
      //     console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      // export2Base64ImgUserMd(path,rect,MIMEType, option)
      var img = _this.export2Base64ImgUserMd(
        base64srcFile,
        this.activeBigSize,
        null,
        {
          //watermark: 'copyright reserved 2020 版权所有',
          background: "#fff",
        }
      );
      img.then(function (base64src) {
        // console.log("路径:::", base64src);
        // console.log("this1=", _this);
        _this.downLoad(base64src, fileNames + ".png");
        // console.log("this2=", _this);
      });
    },
    changeMdRect() {
      this.changeMdRectWinVisible = false;
      var fromDatas = JSON.parse(JSON.stringify(this.inputLoadParam));
      this.userMdPlate = {};
      var model = {
        plate: this.userMdPlate,
        markers: this.userMdmarkers,
        cutPlate: this.userMdcutPlate,
      };
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };
      var inputParam = { X: this.activeBigSize.X, Y: this.activeBigSize.Y };
      var myRectObj = this.$myutils["Rectangle"](
        inputParam,
        model,
        fixedParam,
        this.zoomM
      );
      var arr = [];
      var data = this.activeImg;
      var arr = JSON.parse(JSON.stringify(this.getDrawOp[data]));
      console.log("arr20241117999999999==", arr);
      console.log(
        "88888888888888**********this.inputLoadParam====",
        this.inputLoadParam
      );
      for (let key in arr) {
        if (arr.hasOwnProperty(key)) {
          // 确保key是对象自身的属性
          let value = arr[key];
          fromDatas[key]["type"] = value.type;
          fromDatas[key]["area"] = value.area;
          fromDatas[key]["shape"] = value.shape;
        }
      }
      var creatlengths = this.objHasOwnProperty(fromDatas);
      var arrB = { models: {} };
      for (var i = 1; i <= creatlengths; i++) {
        var obj = this.creatMdDraw(fromDatas[i].shape, fromDatas[i]);
        for (let key in obj.plate.models) {
          if (obj.plate.models.hasOwnProperty(key)) {
            // 确保key是对象自身的属性
            let value = obj.plate.models[key];
            arrB.models[key] = value;
          }
        }
        // arrB.models=obj.plate.models
      }
      // console.log("obj.myRectObj!!!!!!!!!!!=",obj.myRectObj);
      arrB.models["Rectangle"] = myRectObj.plate.models.Rectangle;
      console.log("arrB==", arrB);
      this.activeBigImgUrl = this.creatUserMDImg(
        arrB,
        this.activeBigSize.X,
        this.activeBigSize.Y
      );
      console.log("this.activeBigImgUrl==", this.activeBigImgUrl);
    },
    creatUserMDImg(plate, w, h) {
      var x = document.getElementById("svgToImg");
      x.innerHTML = "";
      var svg = makerjs.exporter.toSVG(plate, this.renderOptionsImg);
      document.getElementById("svgToImg").innerHTML = svg;
      //重写SVG
      var myImgs = document.getElementById("myImgs").innerHTML;
      // console.log("myImgs===", myImgs);
      // var w=this.form.glassW,h=this.form.glassH
      var html =
        '<svg width="' +
        (w * 1 + 100) +
        '" height="' +
        (h * 1 + 100) +
        '" viewBox="-50 -50 ' +
        (w * 1 + 100) +
        " " +
        (h * 1 + 100) +
        '" xmlns="http://www.w3.org/2000/svg" id="myImgs">';
      var mySVGHtml = html + myImgs + "</svg>";
      // console.log("html==",html);
      // console.log("svg==",svg);
      // console.log("mySVGHtml==",mySVGHtml);
      document.getElementById("svgToImg").innerHTML = "";
      document.getElementById("svgToImg").innerHTML = mySVGHtml;
      var svgs = document.getElementById("myImgs");
      // var svgs = document.getElementById("myDrawing");
      var data = new XMLSerializer().serializeToString(svgs);
      // "data:image/svg+xml;base64," + window.btoa(this.reEncode(source));
      // console.log("data",data);
      var showBigImgURL =
        "data:image/svg+xml;base64," + window.btoa(this.reEncode(data));
      // console.log("upLoadImgURL",this.upLoadImgURL);
      return showBigImgURL;
    },
    creatMdDraw(frame,shape, operation) {
      console.log("creatMdDraw==shape",shape);
      console.log("operation==shape",operation);
      console.log("frame==shape",frame);
      console.log("frame.shapeName==shape",frame.shapeName);
      this.userMdPlate = {};
      this.userMdHolesNum = 0;
      if(frame.shapeName=="Rectangle"){
        var form = { glassW: "", glassH: "" };
      form.glassW = frame.X;
      form.glassH = frame.Y;
      }else{
        var form=JSON.parse(JSON.stringify(frame));
      }
    console.log("form",form);
      var model = {
        plate: this.userMdPlate,
        markers: this.userMdmarkers,
        cutPlate: this.userMdcutPlate,
      };
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };
  
        var myobj = this.$myutils[shape](
        operation,
        model,
        this.userMdHolesNum,
        form,
        fixedParam,
        this.zoomM,
        this.θCut,
        this.rCut
      );
     
     
      console.log("shape=111111=",shape);
      
      return myobj;
    },
    mdImgClick(data) {
      console.log("****123123", data);
      this.activeParam = [];
      this.inputLoadParam = {};
      this.activeImg = data;
      console.log("this.getDrawOp点击==", this.getDrawOp);
      console.log("this.getMdDataDraw==", this.getMdDataDraw);
      this.activeBigImgUrl = this.getMdDataDraw[data].userMdUrl;
      this.activeBigSize.Y = this.getMdDataDraw[data].userHeight;
      this.activeBigSize.X = this.getMdDataDraw[data].userWide;
      // this.activeParam=this.getMdDataDraw[data].operation;
      this.activeParam = JSON.parse(JSON.stringify(this.getDrawOp[data]));
      // console.log("this.getMdDataDraw[data].operation**==length==",this.getMdDataDraw[data].operation);
      console.log("tthis.activeParam**==length==", this.activeParam);
      for (let key in this.activeParam) {
        if (this.activeParam.hasOwnProperty(key)) {
          // 确保key是对象自身的属性
          let value = this.activeParam[key];
          // var propName=this.activeParam[key].shape+"-"+key;
          this.inputLoadParam[key] = value;
        }
        delete this.activeParam[key].type;
        delete this.activeParam[key].area;
        delete this.activeParam[key].shape;
      }
      this.inputLoadParam["Rectangle"] = this.activeBigSize;
      console.log("this.inputLoadParam**==length==", this.inputLoadParam);
    },
    changeMdUpdataMsg() {
      this.$forceUpdate();
      this.submitUserMdForm(this.inputLoadParam);
    },
    //修改参数
    submitUserMdForm(fromData) {
      var fromDatas = JSON.parse(JSON.stringify(fromData));
      console.log("fromDatas",fromDatas)
      
      this.userMdPlate = {};
      var model = {
        plate: this.userMdPlate,
        markers: this.userMdmarkers,
        cutPlate: this.userMdcutPlate,
      };
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };
     
     
      //var inputParam = { X: this.activeBigSize.X, Y: this.activeBigSize.Y };20241220
      console.log("this.getDrawOp.frame=",this.glassMDFrameParam);
      console.log("this.getDrawOp.frame.shapeName=",this.glassMDFrameParam.shapeName);
      console.log("this.fromDatas.Rectangle=",typeof(fromDatas));
     // console.log("this.fromDatas[this.getDrawOp.frame.shapeName]=",fromDatas[this.glassMDFrameParam.shapeName]);
      var inputParam = fromDatas[this.glassMDFrameParam.shapeName];
      console.log("inputParam=",inputParam);
      var frame=JSON.parse(JSON.stringify(this.glassMDFrameParam));
      frame={...frame,...inputParam};
      console.log("+++++++++++++frame",frame);
      //console.log("this.glassMDFrameParam.shapeName=",this.glassMDFrameParam.shapeName);
      var myRectObj = this.$myutils[this.glassMDFrameParam.shapeName](
        inputParam,
        model,
        fixedParam,
        this.zoomM
      );
      var arr = [];
      var data = this.activeImg;
      var arr = JSON.parse(JSON.stringify(this.getDrawOp[data]));
      console.log("///////////submitUserMdForm修改参数///////////=", arr);
      console.log("///////////submitUserMdForm修改参数this.getDrawOp///////////=", this.getDrawOp);
      console.log("///////////submitUserMdForm修改参数this.getDrawOp.frame///////////=", this.getDrawOp.frame);
      console.log("fromDatas******************、、、、、、、///////",fromDatas);
      for (let key in arr) {
        console.log("key===", key);
        if (arr.hasOwnProperty(key)) {
          // 确保key是对象自身的属性
          let value = arr[key];
          fromDatas[key]["type"] = value.type;
          fromDatas[key]["area"] = value.area;
          fromDatas[key]["shape"] = value.shape;
        }
      }
      console.log("fromDatas===+++",fromDatas)
      // var creatlengths=this.objHasOwnProperty(fromDatas);
      var arrB = { models: {} };
      // console.log("creatlengths===",creatlengths);
      for (let key in fromDatas) {
        if (fromDatas.hasOwnProperty(key)) {
          console.log("测试fromDatas=", fromDatas);
          console.log("测试key=", key);
          console.log("测试fromDatas[key].ttpe=", fromDatas[key].type);
          console.log("测试fromDatas[key].shape=", fromDatas[key].shape);
          console.log("测试fromDatas[key]=", fromDatas[key]);
          //if (fromDatas[key].shape == undefined)
          //if (key !== "Rectangle")
          if (fromDatas[key].shape !== undefined) {
            if(fromDatas[key].type=="Corner"){
              var obj = this.creatMdDraw(frame,fromDatas[key].shape, fromDatas);
              console.log("返回角画图数据*****objobjobj=", obj);              
              arrB.models=obj.plate.models;
            }else{
             
          console.log("测试key else  ***********///////=", key);  
          console.log("测试key else  frame  ***********///////=", frame);
          console.log("测试key elsethis.getDrawOp.frame  ***********///////=", this.getDrawOp.frame);
              var obj = this.creatMdDraw(frame,fromDatas[key].shape, fromDatas[key]);            
            for (let key in obj.plate.models) {
              if (obj.plate.models.hasOwnProperty(key)) {
                // 确保key是对象自身的属性
                let value = obj.plate.models[key];
                arrB.models[key] = value;
              }
            }
            }          
            console.log("测试修改角角角角测试*****objobjobj=", obj);
          }
          console.log("测试修改角画出图20241130*****obj=", obj);
        }
      }

      // for(var i=1;i<=creatlengths;i++){
      //   var obj=this.creatMdDraw(fromDatas[i].shape,fromDatas[i]);
      //   for(let key in obj.plate.models){
      //     if (obj.plate.models.hasOwnProperty(key)) { // 确保key是对象自身的属性
      //     let value = obj.plate.models[key];
      //     arrB.models[key]=value;
      //   }
      //   }
      //  // arrB.models=obj.plate.models
      // }
      // console.log("obj.myRectObj!!!!!!!!!!!=",obj.myRectObj);
      console.log("fromDatas角角*******",fromDatas);
      console.log("getMdDataDraw角角角角角角角角角角*******",this.getMdDataDraw);
      console.log("arrB=角角角角=", arrB);
      console.log("this.mdParma.mdTpye==3*********+&&&&&&&",this.mdParma.mdTpye);
      console.log("myRectObj*********%%%%%%%%%%%%%%%%%+&&&&&&&",myRectObj);
      


      if(this.mdParma.mdTpye==3){

      }else{
        arrB.models["Rectangle"] = myRectObj.plate.models.Rectangle;
      }
     
      console.log("arrB==", arrB);
      this.activeBigImgUrl = this.creatUserMDImg(
        arrB,
        this.activeBigSize.X,
        this.activeBigSize.Y
      );
      console.log("this.activeBigImgUrl==", this.activeBigImgUrl);
    },
    //重置
    resetUserMdForm(fromData) {
      this.activeParam = [];
      this.inputLoadParam = {};
      var data = this.activeImg;
      console.log("this.getMdDataDraw重置==", this.getMdDataDraw);
      console.log("this.getDrawOp重置==", this.getDrawOp);      
      this.activeBigImgUrl = this.getMdDataDraw[data].userMdUrl;
      this.activeBigSize.Y = this.getMdDataDraw[data].userHeight;
      this.activeBigSize.X = this.getMdDataDraw[data].userWide;
      this.activeParam = JSON.parse(JSON.stringify(this.getDrawOp[data]));
      console.log("tthis.activeParam*重置*==length==", this.activeParam);
      for (let key in this.activeParam) {
        if (this.activeParam.hasOwnProperty(key)) {
          // 确保key是对象自身的属性
          let value = this.activeParam[key];
          this.inputLoadParam[key] = value;
        }        
        delete this.activeParam[key].type;
        delete this.activeParam[key].area;
        delete this.activeParam[key].shape;
      }
      this.inputLoadParam["Rectangle"] = this.activeBigSize;
      console.log("this.inputLoadParam**=重置=length==", this.inputLoadParam);
      this.$forceUpdate();
      this.submitUserMdForm(this.inputLoadParam);
      // console.log("resetUserMdForm---fromData***==",fromData);
    },
   
    //打开模板窗口，初始化下载数据
    downMdData(type){
 //this.activeUserMD="1";
 this.inputLoadParam= {};
      this.getDrawOp = [];
      this.activeParam = [];
          this.getMdDataDraw = [];
          this.mdName = [];
      const base = process.env.NODE_ENV === "development" ? "/api" : "";
      var myURLToJson = base + "/getData/getMdData.php";
      var token = Cookies.get("token");
      var dataName = JSON.parse(token);
      console.log("token.username==", dataName.username);
      this.mdParma.userName = dataName.username;
      this.mdParma.mdTpye = type;
      /**POST */
      console.log("this.mdParma==", this.mdParma);
      this.axios
        .post(myURLToJson, this.mdParma, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {          
          console.log("res==", res);
          if (res.data.flag == "1") {
            var getData = this.deepCopy(
              JSON.parse(JSON.stringify(res.data.datas))
            );
            var getDatalength = getData.length;
            console.log("getDatalength==", getDatalength);
            if (getDatalength == 0) {
              if(this.activeUserMD=="1"){
                this.holesMdShow = false;
              }else if(this.activeUserMD=="2"){
                this.gapsMdShow = false;
              }else if(this.activeUserMD=="3"){
                this.cornersMdShow = false;
              }              
            } else {
              if(this.activeUserMD=="1"){
                this.holesMdShow = true;
              }else if(this.activeUserMD=="2"){
                this.gapsMdShow = true;
              }else if(this.activeUserMD=="3"){
                this.cornersMdShow = true;
              }
              for (var i = 0; i < getData.length; i++) {
                var obj = {
                  plate: "",
                  cutPlate: "",
                  userMdID: "",
                  userMdName: "",
                  userMdType: "",
                  userMdUrl: "",
                  userHeight: "",
                  userWide: "",
                  operation: "",
                  frame:"",
                };
                this.mdName.push(
                  JSON.parse(JSON.stringify(getData[i].MdObjName))
                );
                console.log("***=", JSON.parse(getData[i].MdObjJsons));
                obj.plate = JSON.parse(getData[i].MdObjJsons).plate;
                obj.cutPlate=JSON.parse(getData[i].MdObjJsons).cutPlate;
                obj.operation = JSON.parse(
                  getData[i].MdObjJsons
                ).operationHistory;
                obj.userMdID = getData[i].MdUserID;
                obj.userMdName = getData[i].MdObjName;
                obj.userMdType = getData[i].MdObjType;
                obj.userMdUrl = getData[i].MdObjUrl;
                obj.userHeight = JSON.parse(getData[i].MdObjJsons).Rect.glassH;
                obj.userWide = JSON.parse(getData[i].MdObjJsons).Rect.glassW;
                obj.frame = JSON.parse(getData[i].MdObjJsons).frame;
                console.log("getData[i].MdObjUrl==", getData[i].MdObjUrl);
                //operationHistory
                //  this.activeParam.push(JSON.parse(getData[i].MdObjJsons).operationHistory);
                this.getMdDataDraw.push(JSON.parse(JSON.stringify(obj)));
                this.getDrawOp.push(
                  JSON.parse(getData[i].MdObjJsons).operationHistory
                );
                
              }
              this.activeBigImgUrl = this.getMdDataDraw[0].userMdUrl;
              this.activeImg = 0;
              console.log("this.activeImg==", this.activeImg);
              this.activeBigSize.Y = JSON.parse(
                getData[0].MdObjJsons
              ).Rect.glassH;
              this.activeBigSize.X = JSON.parse(
                getData[0].MdObjJsons
              ).Rect.glassW;
              this.activeParam = JSON.parse(
                getData[0].MdObjJsons
              ).operationHistory;
              console.log("this.activeParam****///////|||||||",JSON.parse(getData[0].MdObjJsons).operationHistory);
              console.log("this.activeParam****///////|||||||",this.activeParam);
              for (let key in this.activeParam) {
                if (this.activeParam.hasOwnProperty(key)) {
                  // 确保key是对象自身的属性
                  let value = this.activeParam[key];
                  console.log(key + "*******: *****" + value);                  
                  //var propName = this.activeParam[key].shape + "-" +key;
                  var propName = this.activeParam[key].shape + "-" +this.changeArea(this.activeParam[key].area);
                 //var propName = this.activeParam[key].shape + this.changeArea(this.activeParam[key].area);
                  console.log("propName*******=", propName);
                  this.inputLoadParam[propName] = value;
                }
                delete this.activeParam[key].type;
                delete this.activeParam[key].area;
                delete this.activeParam[key].shape;
              }
              this.inputLoadParam[JSON.parse(getData[0].MdObjJsons).frame.shapeName] = JSON.parse(getData[0].MdObjJsons).frame;
              delete this.inputLoadParam[JSON.parse(getData[0].MdObjJsons).frame.shapeName].shapeName;
              console.log("this.inputLoadParam20241114==", this.inputLoadParam);
              console.log(
                "this.getMdDataDrawinputLoadParam20241114==",
                this.getMdDataDraw
              );
              console.log(
                "this.getDrawOp20241114==000000000000000000",
                this.getDrawOp
              );
              for (var i = 0; i < this.getDrawOp.length; i++) {
                // 修改属性名称
                for (let key in this.getDrawOp[i]) {
                  if (this.getDrawOp[i].hasOwnProperty(key)) {
                    // 确保key是对象自身的属性
                    let value = this.getDrawOp[i][key];
                    console.log(key + "*******: *****" + value);
                    console.log(key + "*******: **下***" + value.shape);
                    //var propName = value.shape + "-" + key;
                    var propName = value.shape + "-" + this.changeArea(value.area);
                   // var propName = value.shape + this.changeArea(value.area);
                    console.log("propName******下*=", propName);
                    this.getDrawOp[i][propName] = this.getDrawOp[i][key];
                    delete this.getDrawOp[i][key];
                  }
                }
              }
              this.glassMDFrameParam = JSON.parse(getData[0].MdObjJsons).frame;
              console.log(
                "this.getDrawOp20241114==11111111111111111",
                this.getDrawOp
              );
            }
          } else if (res.data.flag == "2") {
            this.$message.error(res.data.info);
          } else if (res.data.flag == "3") {
            this.$message.error(res.data.info);
          }
          // console.log("res.data22==",JSON.parse(datas));
        })
        .catch((error) => {
          // 处理错误
          console.error("error=", error);
        });
      /**POST */
    },
    openMdWin(type) {
     this.downMdData(type);
      this.MdWin = true;
    },

    holesLoad() {
      this.loading = true;
      setTimeout(() => {
        this.count += 2;
        this.loading = false;
      }, 2000);
    },
    /**模板窗口 */
    handleClick(command) {
      if (command === "logOut") {
        console.log("logout");

        //清除token
        Cookies.remove("token");
        // Cookies.remove("menu");
        this.$router.push({ path: "login" }).catch((e) => {});
      } else if (command === "sysSet") {
        this.systemSetVisible = true;
      }
    },
    fetchData() {
      const base = process.env.NODE_ENV === "development" ? "/api" : "";
      this.axios({
        method: "get",
        url: base + "/test.php",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
        credentials: "include",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          this.success = true;
          console.log(res);

          console.log("test==", res.data);
        })
        .catch((error) => {
          this.error = true;
          console.log("error:" + error);
        });
    },
    sendData() {
      const base = process.env.NODE_ENV === "development" ? "/api" : "";
      console.log("base=", base);
      this.axios({
        method: "post",
        url: base + "/test.php",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: {
          autor: "autor",
          name: "name",
          data: "20241014",
        },
        //credentials:'include'
      })
        .then((res) => {
          this.success = true;
          console.log("post==", res.data);
        })
        .catch((error) => {
          this.error = true;
          console.log("error:" + error);
        });
    },
    /**上传模板Json信息 */

    sendDataToPHP() {
      if (this.jsonForm.MdObjName == "") {
        this.myOpenMsg("模板名称不能为空。", "贵友提示您：");
      } else {
        const base = process.env.NODE_ENV === "development" ? "/api" : "";
        var myURLToJson = base + "/getData/upJson.php";
        console.log(
          "this.operationHistory.length=",
          this.operationHistory.length
        );
        console.log("this.this.glassFrameParam|||||||||||||||||||||||||+++++++++++++++++++++",this.glassFrameParam);
        this.MyJSON.MdObjJsons.plate = this.plate;
        this.MyJSON.MdObjJsons.cutPlate = this.cutPlate;
        this.MyJSON.MdObjJsons.markers = this.markers;
        this.MyJSON.MdObjJsons.Rect = this.form;
        this.MyJSON.MdObjJsons.frame = this.glassFrameParam;
        this.MyJSON.MdObjJsons.operationHistory = this.operationHistory;
        this.MyJSON.MdObjUrl = this.upLoadImgURL;

        //var datass= localStorage.getItem('token');
        //console.log("localStorage.length==",localStorage.length);
        //console.log("datass==",localStorage.getItem('token'));
        var token = Cookies.get("token");
        console.log("token==", typeof token);
        var dataName = JSON.parse(token);
        console.log("token.username==", dataName.username);
        this.MyJSON.MdUserName = dataName.username;
        this.MyJSON.MdObjName = this.jsonForm.MdObjName;
        this.MyJSON.MdObjType = this.jsonForm.MdObjType;
        console.log("this.plate==", this.plate);
        console.log("this.MyJSON.MdObjJsons==", this.MyJSON.MdObjJsons);
        console.log("this.MyJSON.MdObjUrl==", this.MyJSON.MdObjUrl);
        /**POST */
        this.axios
          .post(myURLToJson, this.MyJSON, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log("res==", res);
            console.log("res.data.info==", res.data.info);
            console.log("res.flag==", res.data.flag);
            console.log("res.data.mdobj==", res.data.mdobj);
            if (res.data.flag == "1") {
              console.log(res.data.info);
              this.$message({
                message: res.data.info,
                type: "success",
              });
              this.saveJsonDataVisible = false;
            } else if (res.data.flag == "2") {
              this.$message.error(res.data.info);
            } else if (res.data.flag == "3") {
              this.$message.error(res.data.info);
            }
            // console.log("res.data22==",JSON.parse(datas));
          })
          .catch((error) => {
            // 处理错误
            console.error("error=", error);
          });
        /**POST */
      }
    },
    /**svg 生成 图片文件 并显示在界面当 */
    creatbase64Img(plate, w, h) {
      if (plate == "") {
        this.myOpenMsg("图形为空", "贵友提示您：");
      } else {
        var x = document.getElementById("svgToImg");
        x.innerHTML = "";
        var svg = makerjs.exporter.toSVG(plate, this.renderOptionsImg);
        // console.log("svg00==",svg);
        console.log("plate==", plate);
        document.getElementById("svgToImg").innerHTML = svg;
        //重写SVG
        var myImgs = document.getElementById("myImgs").innerHTML;
        // console.log("myImgs===", myImgs);
        // var w=this.form.glassW,h=this.form.glassH
        var html =
          '<svg width="' +
          (w * 1 + 100) +
          '" height="' +
          (h * 1 + 100) +
          '" viewBox="-50 -50 ' +
          (w * 1 + 100) +
          " " +
          (h * 1 + 100) +
          '" xmlns="http://www.w3.org/2000/svg" id="myImgs">';
        var mySVGHtml = html + myImgs + "</svg>";
        // console.log("html==",html);
        // console.log("svg==",svg);
        // console.log("mySVGHtml==",mySVGHtml);
        document.getElementById("svgToImg").innerHTML = "";
        document.getElementById("svgToImg").innerHTML = mySVGHtml;
        var svgs = document.getElementById("myImgs");
        // var svgs = document.getElementById("myDrawing");
        var data = new XMLSerializer().serializeToString(svgs);
        // "data:image/svg+xml;base64," + window.btoa(this.reEncode(source));
        // console.log("data",data);
        var base64ImgURL =
          "data:image/svg+xml;base64," + window.btoa(this.reEncode(data));
        // console.log("upLoadImgURL",this.upLoadImgURL);
        return base64ImgURL;
      }
    },
    creatImg() {
      if (this.plate == "") {
        this.myOpenMsg("图形为空，请先画图。", "贵友提示您：");
      } else {
        this.saveJsonDataVisible = true;
        // this.showImg=true;
        var x = document.getElementById("svgToImg");
        x.innerHTML = "";
        var svg = makerjs.exporter.toSVG(this.plate, this.renderOptionsImg);
        // console.log("svg00==",svg);
        console.log("this.plate==", this.plate);
        document.getElementById("svgToImg").innerHTML = svg;
        //重写SVG
        var myImgs = document.getElementById("myImgs").innerHTML;
        // console.log("myImgs===", myImgs);
        var w = this.form.glassW,
          h = this.form.glassH;
        var html =
          '<svg width="' +
          (w * 1 + 100) +
          '" height="' +
          (h * 1 + 100) +
          '" viewBox="-50 -50 ' +
          (w * 1 + 100) +
          " " +
          (h * 1 + 100) +
          '" xmlns="http://www.w3.org/2000/svg" id="myImgs">';
        var mySVGHtml = html + myImgs + "</svg>";
        // console.log("html==",html);
        // console.log("svg==",svg);
        // console.log("mySVGHtml==",mySVGHtml);
        document.getElementById("svgToImg").innerHTML = "";
        document.getElementById("svgToImg").innerHTML = mySVGHtml;
        var svgs = document.getElementById("myImgs");
        // var svgs = document.getElementById("myDrawing");
        var data = new XMLSerializer().serializeToString(svgs);
        // "data:image/svg+xml;base64," + window.btoa(this.reEncode(source));
        // console.log("data",data);
        this.upLoadImgURL =
          "data:image/svg+xml;base64," + window.btoa(this.reEncode(data));
        // console.log("upLoadImgURL",this.upLoadImgURL);
      }
    },

    /** */

    /**上传模板Json信息 */
    //创建外框形状 Create border shape
    creatShapeBorder(type, frame) {
      this.myPost = true; //开启向父组件向子组件传数据。
      setTimeout(() => {
        /**延时操作 */

        this.creatBorder("special-shaped", frame);
        /**延时操作 */
      }, 500);
    },

    //外框形状
    creatBorder(type, frame) {
      this.dialogFormVisible = false;
      this.setCanvas();
      this.plate = { models: {} };
      this.cutPlate = { models: {} };
      this.markers = { models: {} };
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录
      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;

      var model = {
        plate: { models: {} },
        markers: { models: {} },
      };
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };
      console.log("20240830shape=", frame);
      console.log("20240914shape=", this.myPost);
      console.log("20240830this.glassFrameParam=", this.glassFrameParam);
      var myobj = this.$myutils[frame](
        this.glassFrameParam,
        model,
        fixedParam,
        this.zoomM
      );

      console.log("myobj******=", JSON.parse(JSON.stringify(myobj)));
      console.log(
        "myobj.plate******=",
        JSON.parse(JSON.stringify(myobj.plate))
      );
      this.plate = myobj.plate;
      this.markers = myobj.markers;
      var myMarkers = myobj.markers;
      var svg = makerjs.exporter.toSVG(myMarkers, this.renderOptions);
      document.getElementById("drawCanvas").innerHTML = svg;
      console.log("this.form.glassW=", this.form.glassW);
      console.log("this.form.glassH=", this.form.glassH);
      if (frame == "Rectangle") {
        var glassW = this.form.glassW;
        var glassH = this.form.glassH;
      } else {
        var glassW = myobj.glassW;
        var glassH = myobj.glassH;
        this.form.glassW = glassW;
        this.form.glassH = glassH;
      }

      this.creatSvg(glassW, glassH, myMarkers);
      this.frame=frame;

      /*


      var svg = makerjs.exporter.toSVG(myMarkers, this.renderOptions);
      document.getElementById("drawCanvas").innerHTML = svg;
      //重写SVG
      var myDrawing = document.getElementById("myDrawing").innerHTML;
     console.log("myDrawing===",myDrawing);
      var html =
        '<svg width="' +
        (this.glassW * 1 + 100) +
        '" height="' +
        (this.glassH * 1 + 100) +
        '" viewBox="-50 -50 ' +
        (this.glassW * 1 + 100) +
        " " +
        (this.glassH * 1 + 100) +
        '" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      var mySVGHtml = html + myDrawing + "</svg>";
      // console.log("mySVGHtml===",mySVGHtml) ;
      document.getElementById("drawCanvas").innerHTML = "";
      document.getElementById("drawCanvas").innerHTML = mySVGHtml;
      // this.myHolesNum=JSON.parse(JSON.stringify(myobj.myHolesNum));
  */
    },

    //接收子组件数据
    recieveMsg(rel) {
      console.log("rel=||||||******", rel);
      if (rel.shapeName == "Rectangle") {
        //this.X=rel.X;
        //this.Y=rel.Y;
        this.form.glassW = rel.X;
        this.form.glassH = rel.Y;
        this.glassFrameOptions[0].inputParam.W = rel.X;
        this.glassFrameOptions[0].inputParam.H = rel.Y;
        console.log("this.form.glassW=", this.form.glassW);
        console.log("this.form.glassH=", this.form.glassH);
        this.glassFrameParam = rel;
      } else if (rel.shapeName == "IsoscelesTrapezoid") {
        this.H = rel.H;
        this.W = rel.W;
        this.W1 = rel.W1;
        this.glassFrameOptions[1].children[0].inputParam.H = rel.H;
        this.glassFrameOptions[1].children[0].inputParam.W = rel.W;
        this.glassFrameOptions[1].children[0].inputParam.W1 = rel.W1;
        this.glassFrameParam = rel;
      } else if (rel.shapeName == "RightAngledTrapezoid") {
        this.H = rel.H;
        this.H1 = rel.H1;
        this.W = rel.W;
        this.W1 = rel.W1;
        this.glassFrameOptions[1].children[1].inputParam.H = rel.H;
        this.glassFrameOptions[1].children[1].inputParam.H1 = rel.H1;
        this.glassFrameOptions[1].children[1].inputParam.W = rel.W;
        this.glassFrameOptions[1].children[1].inputParam.W1 = rel.W1;
        this.glassFrameParam = rel;
        console.log("rel|||||||||=", rel);
      } else if (rel.shapeName == "OtherTrapezoidsA") {
        this.H1 = rel.H1;
        this.H2 = rel.H2;
        this.W = rel.W;
        this.W1 = rel.W1;
        this.W2 = rel.W2;
        this.glassFrameOptions[1].children[2].inputParam.H1 = rel.H1;
        this.glassFrameOptions[1].children[2].inputParam.H2 = rel.H2;
        this.glassFrameOptions[1].children[2].inputParam.W = rel.W;
        this.glassFrameOptions[1].children[2].inputParam.W1 = rel.W1;
        this.glassFrameOptions[1].children[2].inputParam.W2 = rel.W2;
        this.glassFrameParam = rel;
      } else if (rel.shapeName == "OtherTrapezoidsB") {
        this.H = rel.H;
        this.W = rel.W;
        this.W1 = rel.W1;
        this.W2 = rel.W2;
        this.glassFrameOptions[1].children[3].inputParam.H = rel.H;
        this.glassFrameOptions[1].children[3].inputParam.W = rel.W;
        this.glassFrameOptions[1].children[3].inputParam.W1 = rel.W1;
        this.glassFrameOptions[1].children[3].inputParam.W2 = rel.W2;
        this.glassFrameParam = rel;
      } else if (rel.shapeName == "ParallelogramA") {
        this.H = rel.H;
        this.W = rel.W;
        this.W1 = rel.W1;
        this.glassFrameOptions[2].children[0].inputParam.H = rel.H;
        this.glassFrameOptions[2].children[0].inputParam.W = rel.W;
        this.glassFrameOptions[2].children[0].inputParam.W1 = rel.W1;
        this.glassFrameParam = rel;
        console.log("ParallelogramA=", rel);
      } else if (rel.shapeName == "ParallelogramB") {
        this.H = rel.H;
        this.W = rel.W;
        this.W1 = rel.W1;
        this.W2 = rel.W2;
        this.glassFrameOptions[2].children[1].inputParam.H = rel.H;
        this.glassFrameOptions[2].children[1].inputParam.W = rel.W;
        this.glassFrameOptions[2].children[1].inputParam.W1 = rel.W1;
        this.glassFrameOptions[2].children[1].inputParam.W2 = rel.W2;
        this.glassFrameParam = rel;

        console.log(
          "this.glassFrameOptions|||||||||||=",
          this.glassFrameOptions
        );
        console.log("ParallelogramB=rel||||", rel);
      }
    },
    //切割机格式DXF文件
    glassCut() { 
      console.log("this.plate==",this.plate); 
        // console.log("this.plate.models==",this.plate.models);  
      var count= this.objHasOwnProperty(this.plate);
       console.log("count==",count);
      if (count==0) {
        this.myOpenMsg("请先画图。", "提示：");       
      } else {
        console.log("this.plate.models==",this.plate.models); 
        var num= this.objHasOwnProperty(this.plate.models);
        console.log("num==",num);
        if(num<=1){
          this.myOpenMsg("请添加图形。", "提示：");
        }else{
      //     var objData = this.plate.models;
      // console.log("objData==",objData);    
      // console.log("this.cutPlate.models==",this.plate);    
      // var arrs = [];
      // for (let key in objData) {
      //   if (objData.hasOwnProperty(key)) {
      //     console.log(key); // 输出每个属性的名称
      //     var result = key.split("Area");
      //     console.log("result=", result);
      //     arrs.push(result[1]);
      //   }
      // }
        //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
        console.log("******************");
        this.dxf = makerjs.exporter.toDXF(this.plate);
        console.log("******************");
        console.log("**********this.dxf==********", this.dxf);

        //var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
        var strings =
          "0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n" +
          this.form.glassW +
          "\n20\n" +
          this.form.glassH +
          "\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

        var splitStr = "0\nSECTION\n2\nENTITIES\n";
        if (this.benchMark == "G") {
          var splitStrP = "0\nPOINT\n8\n0\n10\n0\n20\n0\n"; //加入基点坐标
        } else if (this.benchMark == "I") {
          var splitStrP =
            "0\nPOINT\n8\n0\n10\n" + this.form.glassW + "\n20\n0\n"; //加入基点坐标
        }
        var arrs = this.dxf.split(splitStr);
        var arrStr = splitStr + splitStrP;
        console.log("arrStr=", arrStr);
        console.log("arrs[0]=", arrs[0], "arrs[1]=", arrs[1]);
        this.dxf = strings + arrStr + arrs[1];
        console.log("this.dxf******=", this.dxf);
        this.saveMyDXF();
        }
        
      }
    },

    /**抽屉组件 用到的事件*/

    /**玻璃外框类型 */
    selGlassFrameChanged(value) {
      console.log("glassFrameChanged  value=", value);
      console.log("this.glassFrameOptionsValue=", this.glassFrameOptionsValue);
      //this.spacelTrapezoidOptionsValue
      if (value.length == 1) {
        console.log(
          "this.glassFrameOptions[value[0]]=",
          this.glassFrameOptions[value[0]].inputParam
        );
        this.glassFrameParamLeg =
          this.glassFrameOptions[value[0]].inputParam.length;
        this.selectObjectParam = this.glassFrameOptions[value[0]].inputParam; //参数对象

        let arr = Object.keys(this.selectObjectParam);

        console.log("arr==", arr);

        this.imgGlassFrameName = this.glassFrameOptions[value[0]].Name;
        this.currenGlassFrameComponent = this.glassFrameOptions[value[0]].Name;
        console.log(
          "this.currenGlassFrameComponent===",
          this.currenGlassFrameComponent
        );
      } else if (value.length == 2) {
        this.imgGlassFrameName =
          this.glassFrameOptions[value[0]].children[value[1]].Name;
        this.selectObjectParam =
          this.glassFrameOptions[value[0]].children[value[1]].inputParam; //参数对象
        this.currenGlassFrameComponent =
          this.glassFrameOptions[value[0]].children[value[1]].Name;

        console.log(
          "this.currenGlassFrameComponent===",
          this.currenGlassFrameComponent
        );
      }
    },
    /**梯形类型选择 */
    selTrapezoidChanged(value) {
      console.log("selTrapezoidChanged  value=", value);
      console.log(
        "this.spacelTrapezoidOptionsValue=",
        this.spacelTrapezoidOptionsValue
      );
      //this.spacelTrapezoidOptionsValue
      this.imgTrapezoidName = this.spacelTrapezoidOptions[value].Name;
      this.currenTrapezoidComponent = this.spacelTrapezoidOptions[value].Name;
    },
    /**梯形类型选择 */
    /**平形四边形类型选择 */
    selParallelogramChanged(value) {
      console.log("selParallelogramChanged  value=", value);
      console.log(
        "this.spacelParallelogramOptionsValue=",
        this.spacelParallelogramOptionsValue
      );
      //this.spacelParallelogramOptionsValue
      this.imgParallelogramName = this.spacelParallelogramOptions[value].Name;
      this.currenParallelogramComponent =
        this.spacelParallelogramOptions[value].Name;
    },

    /**平形四边形类型选择 */
    //不足两位用0补足两位
    //如果没有孔弹出消息提示框
    myOpenMsg(cont, title) {
      this.$alert(cont, title, {
        confirmButtonText: "确定",
        type: 'warning',
        }).catch(() => {
                  
        });
    },

    /**上传数据到ERP当中 */
    sendDataToERP() {
      var _this = this;
      //const data = this.gyErpParam;// 要传递给后端的数据
      //http://localhost:8082/?id=e0fcdd1afc0e4b5786140b2fa800d91f&gyLen=900&gyWid=800&gyThick=6&gyName=%E6%B5%8B%E8%AF%95&gySer=9_y
      _this.gyErpParam.OrderChildId = _this.GlasslD;
      _this.gyErpParam.Name = _this.gyName;
      _this.gyErpParam.Thick = _this.GlassThickness;
      //const strOk = _this.gyErpParam;// 要传递给后端的数据 ;// 要传递给后端的数据
      const mystrOk = JSON.stringify(_this.gyErpParam); // 要传递给后端的数据 ;// 要传递给后端的数据
      console.log("strOk==", mystrOk);
      console.log("upUrl==0621.000", _this.upUrl);

      _this
        .axios({
          url: _this.upUrl,
          method: "POST",
          data: { strOk: mystrOk },
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          var mystringArr = res.data.split(">");
          console.log("test_mystringArr0" + mystringArr[0]);
          console.log("test_mystringArr1" + mystringArr[1]);
          var mystringArrs = mystringArr[2].split("<");
          var mydata = mystringArrs[0];
          console.log("mydata" + mydata);
        })
        .catch((err) => {
          console.log("*****0621.1345=", err);
        });
    },

    /*
和打孔机对接
*/
    /**
     * 保存模板内容到数据库
     * 1、图片保存路径
     * 2、参数以json数据保存
     * 3、保存时间
     */
    downloadHoles(filename, text) {
      // 创建一个临时的<a>标签
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      // 触发下载
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    // 使用函数生成.holes文件
    changSetThickness() {
      //   isType(null);//'[object null]'
      // const isType =(value)=>0bject,prototype,toString.call(value).toLowerCase();
      // isType(undefined);//'[object null]'isType([]);//'[object array]'
      // isType({});//'[object object]
      // isType(1);//'[object number]'
      // isType("1");//'[object string]'isType(true);//[object boolean]'

      if (this.operationHistory.length == 1) {
        this.thicknessSetVisible = false;
      } else if (this.operationHistory.length > 1) {
        this.thicknessSetVisible = false;
        if (this.HolesBrand == "boliang") {
          var headData = "[Glassspec]\n";
          var headID = "GlasslD=" + this.GlasslD + "\n";
          var headNThick =
            "GlassThickness=" +
            this.GlassThickness +
            "\n" +
            "Pieces=" +
            this.Pieces +
            "\n";
          //var rectL=`WID=${this.form.glassW}\nLEN=${this.form.glassH}\n[Holes]\n`;
          //var rectL="WID="+(this.form.glassW*1).toFixed(1)+"\nLEN="+(this.form.glassH*1).toFixed(1)+"\n[Holes]\n";
          var rectL =
            "WID=" +
            (this.form.glassH * 1).toFixed(1) +
            "\nLEN=" +
            (this.form.glassW * 1).toFixed(1) +
            "\n[Holes]\n";
          //var holesData=`X=100.0 Y=200.0 D=10.0 P=0\n`; // 这里应该是你的.holes文件的内容
          var holesData = `${headData}${headID}${headNThick}${rectL}${this.holeD}`;
          console.log("holesData=", holesData);
          this.downloadHoles(
            this.GlasslD +
              "_" +
              this.GlassThickness.padStart(2, "0") +
              ".holes",
            holesData
          );
        } else if (this.HolesBrand == "jingrui") {
          var headData = "[PicName]\n";
          var headID = "Name=001\n\n";
          var rectL =
            "[GlassWide]\nValue=" +
            (this.form.glassW * 1).toFixed(1) +
            "\n\n[GlassHeight]\nValue=" +
            (this.form.glassH * 1).toFixed(1) +
            "\n\n[Holes]\n";
          var Depth = "[Depth]\nValue=\n";
          //var holesData=`X=100.0 Y=200.0 D=10.0 P=0\n`; // 这里应该是你的.holes文件的内容
          var holesData = `${headData}${headID}${rectL}${this.holeD}${Depth}`;
          console.log("holesData=", holesData);
          this.downloadHoles(
            this.GlasslD + "_" + this.GlassThickness.padStart(2, "0") + ".ini",
            holesData
          );
        }

        this.saveHolesPng();
        if (this.toERPParams == true) {
          this.sendDataToERP();
        }
      }
    },
    //系统设置
    saveSystemSet() {
      // renderOptions.fontSize
      // renderOptions.strokeWidth
      // zoomM
      /*
  setSysStorage:{
        fontSize:'16px',
        strokeWidth:'0.3mm',
        zoomM:1
      },//需要缓存数据
      */
      this.setSysStorage.fontSize = this.renderOptions.fontSize;
      this.setSysStorage.strokeWidth = this.renderOptions.strokeWidth;
      this.setSysStorage.zoomM = this.zoomM;

      global.localStorage.setItem(
        "sysStorage",
        JSON.stringify(this.setSysStorage)
      );
      const syStorData = JSON.parse(global.localStorage.getItem("sysStorage"));
      console.log("storage=", global.localStorage.getItem("sysStorage"));
      this.renderOptions.fontSize = syStorData.fontSize;
      this.renderOptions.strokeWidth = syStorData.strokeWidth;
      this.zoomM = syStorData.zoomM;
      this.systemSetVisible = false;
      console.log("this.zoomM=", this.zoomM);
    },
    handleHolesCommand(command) {
      //this.$message('click on item ' + command);
      this.HolesBrand = command;
      if (command == "boliang") {
        this.saveHolesBoliang();
      } else if (command == "jingrui") {
        this.saveHolesJingrui();
      }
    },
    //精锐打孔机
    saveHolesJingrui() {
      console.log(
        "this.operationHistory.length=",
        this.operationHistory.length
      );
      if (this.operationHistory.length <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else {
        var arrH = [];
        console.log("this.operationHistory=", this.operationHistory);
        //console.log("typeOfthis.operationHistory=",typeof(this.operationHistory));
        console.log(
          "this.operationHistory.length=",
          this.operationHistory.length
        );
        for (let i = 1; i < this.operationHistory.length; i++) {
          if (this.operationHistory[i].type == "Hole") {
            /** 孔*/
            if (this.operationHistory[i].shape == "SingleHole") {
              /**
               * 单孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 单孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwo") {
              console.log("吊轮孔2");
              /**
               * 吊轮孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                console.log("吊轮孔22222");
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                console.log("吊轮孔22222");
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 吊轮孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleThree") {
              /**
               * 吊轮孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 吊轮孔3
               *
               *
               */
            } else if (
              this.operationHistory[i].shape == "HangWheelHoleTwoCol"
            ) {
              /**
               * 4、双吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);

                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);

                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 4、双吊轮孔列
               *
               *
               */
            } else if (
              this.operationHistory[i].shape == "HangWheelHoleTwoRow"
            ) {
              /**
               * 5、双吊轮孔排
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  +(this.operationHistory[i].W * 1) +
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy4 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 5、双吊轮孔排
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangHole") {
              /**
               * 6、晾物孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 6、晾物孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHoleTShaped") {
              /**
               * 7、拉手孔T字
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 7、拉手孔T字
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHoles") {
              /**
               * 8、双固定孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHolesCol") {
              /**
               * 8、双固定孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RelSuWheelHole") {
              /**
               * 9、相对吊轮孔
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy3 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy5 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy6 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy3 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy4 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy5 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy6 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 9、相对吊轮孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole") {
              /**
               * 10、拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(0);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 10、拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RotatLockHole") {
              /**
               * 11、旋转锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy1 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy2 +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 11、旋转锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole3") {
              /**
               * 12、拉手孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx3 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 12、拉手孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleCol") {
              /**
               * 13、吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy = ((this.form.glassH * 1) / 2).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hd = (this.operationHistory[i].D * 1).toFixed(0);
                var holesDatas =
                  "HoleX=" +
                  Hx1 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n" +
                  "HoleX=" +
                  Hx2 +
                  "mm\n" +
                  "HoleY=" +
                  Hy +
                  "mm\n" +
                  "HoleD=" +
                  Hd +
                  "mm\n" +
                  "P=0\n";
              }

              /**
               * 13、吊轮孔列
               *
               *
               */
            } else if (this.operationHistory[i].shape == "Shaped7HandleHole") {
              /**
               * 14、7字拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(0);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(0);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(0);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 14、7字拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "KeyHole") {
              /**
               * 15、锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(0);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(0);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(0);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(0);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(0);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(0);
              }

              var Hd1 = (this.operationHistory[i].D1 * 1).toFixed(0);
              var Hd2 = (this.operationHistory[i].D2 * 1).toFixed(0);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd1 +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd2 +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx3 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd1 +
                "mm\n" +
                "P=0\n";

              /**
               * 15、锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles") {
              /**
               * 16、三孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 16、三孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles2") {
              /**
               * 17、三孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 17、三孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles3") {
              /**
               * 18、三孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 18、三孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles4") {
              /**
               * 19、三孔4
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx1 +
                "mm\n" +
                "HoleY=" +
                Hy2 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n" +
                "HoleX=" +
                Hx2 +
                "mm\n" +
                "HoleY=" +
                Hy1 +
                "mm\n" +
                "HoleD=" +
                Hd +
                "mm\n" +
                "P=0\n";

              /**
               * 19、三孔4
               *
               *
               */
            }
            console.log("i=", i);
            arrH.push(holesDatas);
            /** */
          }
        }
        this.holeD = arrH.join("");
        console.log("arrH===", arrH);
        console.log("arrH.length", arrH.length);
        console.log("this.holeD.length", this.holeD.length);
        console.log("this.holeD", this.holeD);
        var arr = this.holeD.split("P=0\n");
        arr = arr.slice(0, arr.length - 1);
        console.log("arr", arr);
        console.log("arr.length", arr.length);
        var arrHole = [];
        for (let i = 0; i < arr.length; i++) {
          var j = i * 1 + 1;
          const targetString = "Hole";
          const replacementString = "Hole" + j;
          const regex = new RegExp(targetString, "g");
          const arrs = arr[i];
          var str = arrs.replace(regex, replacementString);
          console.log("j=", j);
          arrHole.push(str);
        }
        //this.holeD=
        // console.log("arrDate", arr);
        console.log("arrHole=", arrHole);
        this.holeD = arrHole.join("");
        if (arrHole.length == 0) {
          this.myOpenMsg("您画的图中无孔，导出无效，请确认！", "提示：");
        } else {
          this.thicknessSetVisible = true;
        }
      }
    },
    //博亮打孔机
    saveHolesBoliang() {
      console.log(
        "this.operationHistory.length=",
        this.operationHistory.length
      );
      if (this.operationHistory.length <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else {
        var arrH = [];
        console.log("this.operationHistory=", this.operationHistory);
        //console.log("typeOfthis.operationHistory=",typeof(this.operationHistory));
        console.log(
          "this.operationHistory.length=",
          this.operationHistory.length
        );
        for (let i = 1; i < this.operationHistory.length; i++) {
          if (this.operationHistory[i].type == "Hole") {
            /** 孔*/

            if (this.operationHistory[i].shape == "SingleHole") {
              /**
               * 单孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" + Hx + " " + "Y=" + Hy + " " + "D=" + Hd + " " + "P=0\n";
              }

              /**
               * 单孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleTwo") {
              console.log("吊轮孔2");
              /**
               * 吊轮孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                console.log("吊轮孔22222");
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                console.log("吊轮孔22222");
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 吊轮孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleThree") {
              /**
               * 吊轮孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy3 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 吊轮孔3
               *
               *
               */
            } else if (
              this.operationHistory[i].shape == "HangWheelHoleTwoCol"
            ) {
              /**
               * 4、双吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);

                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 4、双吊轮孔列
               *
               *
               */
            } else if (
              this.operationHistory[i].shape == "HangWheelHoleTwoRow"
            ) {
              /**
               * 5、双吊轮孔排
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  +(this.operationHistory[i].W * 1) +
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy3 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy3 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy4 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 5、双吊轮孔排
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangHole") {
              /**
               * 6、晾物孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 6、晾物孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHoleTShaped") {
              /**
               * 7、拉手孔T字
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H1 * 1 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H1 * 1 -
                  this.operationHistory[i].H2 * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 7、拉手孔T字
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHoles") {
              /**
               * 8、双固定孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "DoubleFixHolesCol") {
              /**
               * 8、双固定孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 8、双固定孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RelSuWheelHole") {
              /**
               * 9、相对吊轮孔
               *
               */
              if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy3 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].W * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy4 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy5 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy6 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy3 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy4 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy5 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy6 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 9、相对吊轮孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole") {
              /**
               * 10、拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hx = (this.operationHistory[i].X * 1).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hx = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 10、拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "RotatLockHole") {
              /**
               * 11、旋转锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy1 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy2 +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 11、旋转锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HandleHole3") {
              /**
               * 12、拉手孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx3 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].H * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx3 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 12、拉手孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "HangWheelHoleCol") {
              /**
               * 13、吊轮孔列
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy = ((this.form.glassH * 1) / 2).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hd = (this.operationHistory[i].D * 1).toFixed(1);
                var holesDatas =
                  "X=" +
                  Hx1 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n" +
                  "X=" +
                  Hx2 +
                  " " +
                  "Y=" +
                  Hy +
                  " " +
                  "D=" +
                  Hd +
                  " " +
                  "P=0\n";
              }

              /**
               * 13、吊轮孔列
               *
               *
               */
            } else if (this.operationHistory[i].shape == "Shaped7HandleHole") {
              /**
               * 14、7字拉手孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 14、7字拉手孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "KeyHole") {
              /**
               * 15、锁孔
               *
               */
              if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
                var Hx2 = ((this.form.glassW * 1) / 2).toFixed(1);
                var Hx3 = (
                  (this.form.glassW * 1) / 2 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd1 = (this.operationHistory[i].D1 * 1).toFixed(1);
              var Hd2 = (this.operationHistory[i].D2 * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd1 +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd2 +
                " " +
                "P=0\n" +
                "X=" +
                Hx3 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd1 +
                " " +
                "P=0\n";

              /**
               * 15、锁孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles") {
              /**
               * 16、三孔
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 16、三孔
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles2") {
              /**
               * 17、三孔2
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx2 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }
              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 17、三孔2
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles3") {
              /**
               * 18、三孔3
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy2 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy1 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy1 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy2 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 18、三孔3
               *
               *
               */
            } else if (this.operationHistory[i].shape == "ThreeHoles4") {
              /**
               * 19、三孔4
               *
               */
              if (this.operationHistory[i].area * 1 == 1) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 2) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 3) {
                var Hy2 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1
                ).toFixed(1);
                var Hy1 = (
                  this.form.glassH * 1 -
                  this.operationHistory[i].Y * 1 -
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 4) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 5) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 6) {
                var Hy1 = (
                  (this.form.glassH * 1) / 2 +
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hy2 = (
                  (this.form.glassH * 1) / 2 -
                  (this.operationHistory[i].H * 1) / 2
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 7) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (this.operationHistory[i].X * 1).toFixed(1);
                var Hx1 = (
                  this.operationHistory[i].X * 1 +
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 8) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  (this.form.glassW * 1) / 2 -
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
                var Hx1 = (
                  (this.form.glassW * 1) / 2 +
                  (this.operationHistory[i].W * 1) / 2
                ).toFixed(1);
              } else if (this.operationHistory[i].area * 1 == 9) {
                var Hy2 = (this.operationHistory[i].Y * 1).toFixed(1);
                var Hy1 = (
                  this.operationHistory[i].Y * 1 +
                  this.operationHistory[i].H * 1
                ).toFixed(1);
                var Hx2 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1
                ).toFixed(1);
                var Hx1 = (
                  this.form.glassW * 1 -
                  this.operationHistory[i].X * 1 -
                  this.operationHistory[i].W * 1
                ).toFixed(1);
              }

              var Hd = (this.operationHistory[i].D * 1).toFixed(1);
              var holesDatas =
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx1 +
                " " +
                "Y=" +
                Hy2 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n" +
                "X=" +
                Hx2 +
                " " +
                "Y=" +
                Hy1 +
                " " +
                "D=" +
                Hd +
                " " +
                "P=0\n";

              /**
               * 19、三孔4
               *
               *
               */
            }
            console.log("i=", i);
            arrH.push(holesDatas);
            /** */
          }
        }
        this.holeD = arrH.join("");
        console.log("arrH===", arrH);
        console.log("arrH.length", arrH.length);
        console.log("this.holeD.length", this.holeD.length);
        if (arrH.length == 0) {
          this.myOpenMsg("您画的图中无孔，导出无效，请确认！", "提示：");
        } else {
          this.thicknessSetVisible = true;
        }
      }
    },
    saveHolesPng() {
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      img.then(function (base64src) {
        //console.log('路径:::', base64src)
        // console.log("this1=",_this)
        _this.downLoad(
          base64src,
          _this.GlasslD + "_" + _this.GlassThickness.padStart(2, "0") + ".png"
        );
        //console.log("this2=",_this)
      });
    },
    /*和打孔机对接*/
    // maker/js.exporter.toDXF
    //根据矩形的大小设置画面的大小及缩放比例
    setCanvas() {
      var w = this.form.glassW * 1, //1200  68vw
        h = this.form.glassH * 1; //600   75vh
      var myScaleW = (1200 / w).toFixed(1);
      var myScaleH = (600 / h).toFixed(1);
      console.log("wwwwwww=", w, "HHHH====", h);
      var drawCanvas = document.getElementById("drawCanvas");
      drawCanvas.style.display = "flex";
      drawCanvas.style.justifyContent = "center";
      drawCanvas.style.alignItems = "center";
      drawCanvas.style.height = 75 + "vh";
      drawCanvas.style.width = 68 + "vw";
      if (myScaleW < 1 || myScaleH < 1) {
        console.log("myScaleW<1||myScaleH<1");
        if (myScaleW <= myScaleH) {
          console.log("myScaleW<=myScaleH");
          drawCanvas.style.transform = "scale(" + myScaleW + ")"; // 设置缩小比例
        } else if (myScaleW > myScaleH) {
          console.log("myScaleW>myScaleH");
          drawCanvas.style.transform = "scale(" + myScaleH + ")"; // 设置缩小比例
        }
      } else {
        console.log("myScaleW>=1||myScaleH>=1");
        drawCanvas.style.transform = "scale(1)"; // 设置缩小比例
      }
      console.log("myScaleW==", myScaleW);
      console.log("myScaleH==", myScaleH);
    },
    //画出入刀线 出三（3mm）进五(5mm)  角度θ计算  S  出入刀线弧长 r0(弧度代表的圆坐标)
    /**
     * 孔坐标:(x0,y0) 半径 r
     * 1、和孔的交点:交点坐标(x1,y1); x1:孔的圆心X坐标值x0-r;y1:孔的圆心X坐标值y0;
     * 2、进刀线:s=Math.sqrt(2*r*r)  起点X坐标(x0-r+r0)起点Y坐标(y0-r0); 终点坐标  X坐标(x0-r)Y坐标(y0)
     * 3、出刀线:s=Math.sqrt(2*r*r)  起点X坐标(x0-r)起点Y坐标(y0); 终点坐标  X坐标(x0-r+r0)Y坐标(y0+r0)
     * 4、new Arc(pointA: IPoint, pointB: IPoint, radius: number, largeArc: boolean, clockwise: boolean): Arc
     * 5、计算三个点的坐标
     */
    //判断对象属性是否存在
    objIsEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    //判断对象属性值是否为0
    objIsZero(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          console.log("obj[key]=", obj[key]);
          if (obj[key] == 0) {
            return true;
          } else {
            return false;
          }
          // 对属性值进行操作
        }
      }
    },
    /**
     * 只修改玻璃外框
     */
    changeRect() {
      this.changeGlassVisible = true;
    },
    //只修改玻璃尺寸，重新画图
    changeSetRect() {
      this.changeGlassVisible = false;
      console.log("this.markers=", this.markers);
      console.log(
        "this.markers宽=",
        this.markers.models.myWidthMark.caption.text
      );
      console.log(
        "this.markers高=",
        this.markers.models.myHeightMark.caption.text
      );
      this.markers.models.myWidthMark.caption.text = "宽=" + this.form.glassW;
      this.markers.models.myHeightMark.caption.text = "高=" + this.form.glassH;
      //console.log("this.operationHistory=", this.operationHistory);
      this.creatSvg(this.form.glassW, this.form.glassH, this.markers);
    },
    /**
     * 只修改玻璃外框
     */
    myTest0724() {
      console.log("this.plate=", this.plate);
      console.log("this.plate.models=", this.plate.models);
      console.log("this.cutPlate.models=", this.cutPlate.models);
      console.log("this.plate.models=", this.objIsEmpty(this.plate.models)); //如果返回true则表明为空，如果为false则不空。
      console.log("this.dxf=", this.dxf);
      /**
       * 20240724 展示图的位置，手动调整图的切割顺序
       *
       */
      //导出DXF之前把画图的示意图显示出来

      console.log("this.plate.models=", this.cutPlate.models);
      var objData = this.cutPlate.models;
      //var objData = this.plate.models;
      var arrs = [];
      for (let key in objData) {
        if (objData.hasOwnProperty(key)) {
          console.log(key); // 输出每个属性的名称
          var result = key.split("Area");
          console.log("result=", result);
          arrs.push(result[1]);
        }
      }
      console.log("arrs=", arrs);

      //this.dxf=makerjs.exporter.toDXF(this.model);
      // this.dxf=makerjs.exporter.toDXF(model);

      if (this.objIsEmpty(this.cutPlate.models)) {
        this.myOpenMsg("图形为空，请先画图。", "提示：");
      } else {
        //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
        console.log("******************");
        //var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
        var strings =
          "0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n" +
          this.form.glassW +
          "\n20\n" +
          this.form.glassW +
          "\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

        var splitStr = "0\nSECTION\n2\nENTITIES\n";
        if (this.benchMark == "G") {
          var splitStrP = "0\nPOINT\n8\n0\n10\n0\n20\n0\n"; //加入基点坐标
        } else if (this.benchMark == "I") {
          var splitStrP =
            "0\nPOINT\n8\n0\n10\n" + this.form.glassW + "\n20\n0\n"; //加入基点坐标
        }
        var arrs = this.dxf.split(splitStr);
        var arrStr = splitStr + splitStrP;
        console.log("arrStr=", arrStr);
        console.log("arrs[0]=", arrs[0], "arrs[1]=", arrs[1]);
        this.dxf = strings + arrStr + arrs[1];
        console.log("this.dxf******=", this.dxf);
        this.saveMyDXF();
      }
    },
    // 导出水刀格式
    exWaterJet() {
      console.log("this.plate=", this.plate);
      console.log("this.plate.models=", this.plate.models);
      console.log("this.cutPlate.models=", this.cutPlate.models);
      console.log("this.plate.models=", this.objIsEmpty(this.plate.models)); //如果返回true则表明为空，如果为false则不空。
      console.log("this.dxf=", this.dxf);
      /**
       * 20240724 展示图的位置，手动调整图的切割顺序
       *
       */
      //导出DXF之前把画图的示意图显示出来
      var objData = this.cutPlate.models;
      //var objData = this.plate.models;
      var arrs = [];
      for (let key in objData) {
        if (objData.hasOwnProperty(key)) {
          console.log(key); // 输出每个属性的名称
          var result = key.split("Area");
          console.log("result=", result);
          arrs.push(result[1]);
        }
      }
      console.log("arrs=", arrs);

      //this.dxf=makerjs.exporter.toDXF(this.model);
      // this.dxf=makerjs.exporter.toDXF(model);

      if (this.objIsEmpty(this.cutPlate.models)) {
        this.myOpenMsg("图形为空，请先画图。", "提示：");
      } else {
        //基准点（Benchmark）位置 四个角 A、C、G、I （可以和区域位置进行对照）默认在G位置
        console.log("******************");
        //var strings="0\nSECTION\n2\nHEADER\n9\n$ACADVER\n1\nAC1018\n9\n$ACADMAINTVER\n70\n104\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n"+this.form.glassW+"\n20\n"+this.form.glassW+"\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";
        var strings =
          "0\nSECTION\n2\nHEADER\n9\n$LUNITS\n70\n4\n9\n$LIMMIN\n10\n0\n20\n0\n9\n$LIMMAX\n10\n" +
          this.form.glassW +
          "\n20\n" +
          this.form.glassH +
          "\n9\n$INSUNITS\n70\n4\n0\nENDSEC\n";

        var splitStr = "0\nSECTION\n2\nENTITIES\n";
        if (this.benchMark == "G") {
          var splitStrP = "0\nPOINT\n8\n0\n10\n0\n20\n0\n"; //加入基点坐标
        } else if (this.benchMark == "I") {
          var splitStrP =
            "0\nPOINT\n8\n0\n10\n" + this.form.glassW + "\n20\n0\n"; //加入基点坐标
        }
        var arrs = this.dxf.split(splitStr);
        var arrStr = splitStr + splitStrP;
        console.log("arrStr=", arrStr);
        console.log("arrs[0]=", arrs[0], "arrs[1]=", arrs[1]);
        this.dxf = strings + arrStr + arrs[1];
        console.log("this.dxf******=", this.dxf);
        this.saveMyDXF();
      }
    },
    creatDraw(myMarkers, myPlate, cutPlate, glassW, glassH) {
      console.log("测试添加是否调用0" + JSON.stringify(this.plate));
      console.log("测试添加是否调用0+this.stateIndex" + this.stateIndex);
      console.log("测试添加是否调用0+this.myStep" + this.myStep);
      document.getElementById("drawCanvas").innerHTML = "";
      console.log("测试添加是否调用*************" + JSON.stringify(myMarkers));
      console.log("测试添加是否调用*************myMarkers" + myMarkers);
      console.log("测试添加是否调用*************this.renderOptions" + this.renderOptions);
      var svg = makerjs.exporter.toSVG(myMarkers, this.renderOptions);
      console.log("测试添加是否调用*************");
      console.log("creatDraw*************glassW", glassW);
      console.log("creatDraw*************glassH", glassH);
      console.log("creatDraw*************cutPlate", cutPlate);
      console.log("creatDraw*************myPlate", myPlate);
      console.log("creatDraw*************myMarkers", myMarkers);
      this.dxf = makerjs.exporter.toDXF(cutPlate);  
      document.getElementById("drawCanvas").innerHTML = svg;
      //重写SVG
      var myDrawing = document.getElementById("myDrawing").innerHTML;
     // console.log("myDrawing===", myDrawing);
      var html =
        '<svg width="' +
        (glassW * 1 + 100) +
        '" height="' +
        (glassH * 1 + 100) +
        '" viewBox="-50 -50 ' +
        (glassW * 1 + 100) +
        " " +
        (glassH * 1 + 100) +
        '" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      var mySVGHtml = html + myDrawing + "</svg>";
      // console.log("mySVGHtml===",mySVGHtml) ;
      document.getElementById("drawCanvas").innerHTML = "";
      document.getElementById("drawCanvas").innerHTML = mySVGHtml;
    },
    //删除画布中的所有内容，
    clearSvg() {
      document.getElementById("drawCanvas").innerHTML = "";
      this.plate = {}; //当前状态
      this.markers = {}; //当前状态
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录
      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;
    },
    //单选位置事件
    pationChange(e) {
      console.log("e=", e);
      this.showX = false;
      this.showY = false;
      switch (e) {
        case "1":
          this.showX = false;
          this.showY = false;
          break;
        case "2":
          console.log("this.tabLabel======", this.tabLabel);
          if (this.tabLabel == "拉手孔") {
            this.showX = true;
            this.showY = true;
            this.X = 0;
            this.Y = 0;
          } else {
            console.log("this.tabLabel******", this.tabLabel);
            this.showX = true;
            this.showY = false;
            this.X = 0;
          }
          break;
        case "3":
          this.showX = false;
          this.showY = false;
          break;
        case "4":
          console.log("this.tabLabel======", this.tabLabel);
          if (this.tabLabel == "吊轮孔2" || this.tabLabel == "双吊轮孔列") {
            this.showX = false;
            this.showY = false;
          } else {
            console.log("this.tabLabel******", this.tabLabel);
            this.showX = false;
            this.showY = true;
            this.Y = 0;
          }
          break;
        case "5":
          this.showX = true;
          this.showY = true;
          this.X = 0;
          this.Y = 0;
          break;
        case "6":
          console.log("this.tabLabel======", this.tabLabel);
          if (this.tabLabel == "吊轮孔2" || this.tabLabel == "双吊轮孔列") {
            this.showX = false;
            this.showY = false;
          } else {
            console.log("this.tabLabel******", this.tabLabel);
            this.showX = false;
            this.showY = true;
            this.Y = 0;
          }
          break;
        case "7":
          this.showX = false;
          this.showY = false;
          break;
        case "8":
          if (this.tabLabel == "拉手孔") {
            this.showX = true;
            this.showY = true;
            this.X = 0;
            this.Y = 0;
          } else {
            console.log("case8this.tabLabel******", this.tabLabel);
            this.showX = true;
            this.showY = false;
            this.X = 0;
          }
          break;
        case "9":
          this.showX = false;
          this.showY = false;
          break;
        default:
        // code block
      }
    },
    //初始状态
    initState() {
      this.dialogFormVisible = false;
      this.setCanvas();
      this.plate = {}; //当前状态
      this.markers = {}; //当前状态
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录
      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;
      this.dialogFormVisible = true;
    },
    //深层拷贝
    deepCopy(obj) {
      if (obj === null || typeof obj !== "object") {
        return obj; // 基本类型，直接返回
      }

      if (obj instanceof Date) {
        return new Date(obj.getTime());
      }

      if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
          arr[i] = this.deepCopy(item);
          return arr;
        }, []);
      }

      if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj, key) => {
          newObj[key] = this.deepCopy(obj[key]);
          return newObj;
        }, {});
      }
    },
    // 用于更新状态并添加到历史记录中
    updateState() {
      this.currentState = {
        showX: this.showX,
        showY: this.showY,
        activeName: this.activeName,
        form: {
          glassW: this.form.glassW,
          glassH: this.form.glassH,
        },
        //区域位置选择单选
        radio: this.radio,
        //三孔不同类型选择单选
        threeHoleType: this.threeHoleType,
        //三孔示意图参数
        threeHolePic: this.threeHolePic,
        //三孔调用函数名称变化参数
        threeHoleFn: this.threeHoleFn,
        //水刀是否选择
        checked: this.checked,
        //X、Y偏移值 初始值
        X: this.X,
        Y: this.Y,
        D: this.D,
        R: this.R,
        Rx: this.Rx,
        Ry: this.Ry,
        R1: this.R1,
        R2: this.R2,
        D1: this.D1,
        D2: this.D2,
        H: this.H,
        H1: this.H1,
        H2: this.H2,
        W: this.W,
        W1: this.W1,
        W2: this.W2,
        //画图相关参数
        myHolesNum: this.myHolesNum, //孔的数量
        plate: this.plate, //只有路径数据 供转换DXF格式使用
        markers: this.markers, //包含标记数据 给到SVG
        dxf: this.dxf,
      };
      const cur = JSON.parse(JSON.stringify(this.currentState));
      const curOpt = JSON.parse(JSON.stringify(this.operationCurrent));
      this.history.push(cur); //每次操作记录保存
      this.operationHistory.push(curOpt); //每次操作参数记录保存
      this.future = []; // 更新状态后，清空未来操作记录
      this.operationFuture = []; // 更新状态后，清空未来参数记录
      this.stateIndex = this.history.length;
      this.myStep++;
      console.log("this.stateIndex=", this.stateIndex);
    },
    //撤销操作 用于从历史记录中弹出状态，并将当前状态放入未来记录中。
    /*
      isDrawUndo:true,
      isDrawRedo:true,
      isClearSvg:true,
*/
    //撤销操作
    drawUndo(index) {
      console.log("drawUndo");
      this.stateIndex = index;
      const cur = JSON.parse(JSON.stringify(this.currentState));
      const curOpt = JSON.parse(JSON.stringify(this.operationCurrent));
      this.future.push(cur); // 当前状态放入未来记录
      this.operationFuture.push(curOpt); //每次操作参数记录保存
      this.history.pop(); // 从历史记录中移除最后一项
      const operationHistory = this.operationHistory.pop(); // 从历史记录中移除最后一项参数
      if (this.history.length === 0) {
        document.getElementById("drawCanvas").innerHTML = "";
      } else {
        this.currentState = JSON.parse(
          JSON.stringify(this.history[this.history.length - 1])
        );
        console.log("this.currentState=", this.currentState);
        this.operationCurrent = JSON.parse(JSON.stringify(operationHistory));
        var _this = JSON.parse(JSON.stringify(this.currentState));
        this.creatDraw(
          _this.markers,
          _this.plate,
          _this.form.glassW,
          _this.form.glassH
        );
      }
      console.log("drawUndothis.history=", this.history);
    },
    //恢复操作 将未来记录中的状态弹出，并放入历史记录中。
    drawRedo(index) {
      console.log("drawRedo");
      this.stateIndex = index;
      const cur = JSON.parse(JSON.stringify(this.currentState));
      const curOpt = JSON.parse(JSON.stringify(this.operationCurrent));
      console.log("cur==", cur);
      this.history.push(cur); // 当前状态放入历史记录
      this.operationHistory.push(curOpt); // 当前参数放入历史记录
      const fut = this.future.pop();
      const oper = this.operationFuture.pop();
      this.currentState = JSON.parse(JSON.stringify(fut)); // 从未来记录中弹出状态
      this.operationCurrent = JSON.parse(JSON.stringify(oper)); // 从未来记录中弹出参数
      var _this = JSON.parse(JSON.stringify(this.currentState));
      this.creatDraw(
        _this.markers,
        _this.plate,
        _this.form.glassW,
        _this.form.glassH
      );
      console.log("drawRedothis.history=", this.history);
    },
    //单孔不同选项切换
    handleClickHole(tab, event) {
      console.log("tab, event");
      console.log("tab==", tab._props.name);
      console.log("tab.label=", tab.label);
      console.log(tab);
      console.log(event);
      this.tabLabel = tab.label;
      this.showX = false;
      this.showY = false;
      if (
        tab.label == "吊轮孔2" ||
        tab.label == "吊轮孔3" ||
        tab.label == "双吊轮孔列" ||
        tab.label == "拉手孔" ||
        tab.label == "双吊轮孔排" ||
        tab.label == "相对吊轮孔"
      ) {
        this.radio = "4";
        this.showX = false;
        this.showY = true;
        this.Y = 0;
        this.pationChange(2);
      } else if (
        tab.label == "锁孔"
        //   ||
        //  tab.label == "三孔" ||
        //   tab.label == "三孔2" ||
        //   tab.label == "三孔3" ||
        //  tab.label == "三孔4"
      ) {
        this.radio = "8";
        this.X = 0;
        this.pationChange(2);
      } else {
        this.radio = "7";
        this.pationChange(7);
      }
    },
    //缺不同选项切换
    handleClickGap(tab, event) {
      console.log("tab, event");
      this.tabLabel = tab.label;
      this.showX = false;
      this.showY = true;
      this.Y = 0;
      if (tab.label == "矩形缺" || tab.label == "椭圆") {
        this.radio = "7";
        this.showY = false;
        this.showX = false;
        this.pationChange(7);
      } else if (tab.label == "蝴蝶") {
        this.R = "7";
        this.H = "58";
        this.W = "37.5";
      } else if (tab.label == "蝴蝶2") {
        this.R = "8";
        this.W = "58";
        this.W1 = "61";
        this.H = "37.5";
        this.H1 = "40";
      } else if (tab.label == "蝴蝶3") {
        this.R1 = "3";
        this.R2 = "7";
        this.W = "58";
        this.H = "28";
        this.H1 = "34";
        this.H2 = "37";
      } else if (tab.label == "蝴蝶4") {
        this.R1 = "5";
        this.R2 = "6.5";
        this.W = "55";
        this.W1 = "58";
        this.H = "37.5";
      } else {
        this.R1 = "0";
        this.R2 = "0";
        this.H = "0";
        this.H1 = "0";
        this.H2 = "0";
        this.W = "0";
        this.W1 = "0";
        this.W2 = "0";
      }
    },
    //角不同选项切换
    handleClickCorner(tab, event) {
      this.tabLabel = tab.label;
      this.radio = "7";
      this.pationChange(1);
      if (tab.label == "地弹簧3") {
        this.D = "20";
        this.H = "36";
        this.H1 = "35";
        this.R = "75";
        this.W1 = "146";
        this.W2 = "125";
      } else if (tab.label == "地弹簧2") {
        this.D = "20";
        this.H = "36";
        this.W = "68";
        this.H1 = "36";
        this.R = "80";
        this.W1 = "146";
        this.W2 = "161";
      } else if (tab.label == "地弹簧") {
        this.D = "20";
        this.H = "37";
        this.W = "68";
        this.H1 = "36";
        this.R1 = "80";
        this.R2 = "55";
        this.W1 = "146";
        this.W2 = "161";
      } else {
        this.D = "0";
        this.H = "0";
        this.W = "0";
        this.H1 = "0";
        this.R1 = "0";
        this.R = "0";
        this.R2 = "0";
        this.W1 = "0";
        this.W2 = "0";
      }
      console.log("tab, event");
      console.log("tab==", tab);
      console.log("tab.label==", tab.label);
      console.log("tab._props.label==", tab._props.label);
      console.log("event==", event);
    },
    handleClickCard(tab, event) {
      this.tabLabel = tab.label;
      this.activeName = "first";
      this.radio = "7";
      if (tab.label == "缺") {
        this.R = "7";
        this.H = "58";
        this.W = "37.5";
        this.Y = 0;
        this.showX = false;
        this.showY = true;
      } else if (tab.label == "孔") {
        this.R = "0";
        this.H = "0";
        this.W = "0";
        this.showX = false;
        this.showY = false;
      } else {
        this.R = "0";
        this.H = "0";
        this.W = "0";
        this.showX = true;
        this.showY = true;
        this.X = 0;
        this.Y = 0;
      }
    },
    /*****画图*****/
    // 矩形玻璃
    creatRect() {
      this.dialogFormVisible = false;
      this.setCanvas();
      this.plate = {};
      this.cutPlate = {};
      this.markers = {};
      this.history = []; // 存储历史记录
      this.future = []; // 存储未来的操作记录

      this.currentState = {}; // 当前状态  Initial state 初始状态
      this.operationCurrent = {}; // 当前状态  Initial state 初始参数状态
      this.operationHistory = []; // 存储历史记录的参数记录
      this.operationFuture = []; // 存储未来的参数记录
      this.myHolesNum = 0;
      var w = this.form.glassW * 1,
        TxtW = "宽=" + w.toString(),
        h = this.form.glassH * 1,
        TxtH = "高=" + h.toString();
      console.log("w=", w, "h=", h);
      if (w == h) {
        var myRect = new makerjs.models.Square(w);
      } else {
        var myRect = new makerjs.models.Rectangle(w, h);
      }
      this.plate = {
        models: {
          //myRect:myRect
        },
      };
      this.cutPlate = {
        models: {
          //myRect:myRect
        },
      };
      var myWidthMark = new makerjs.models.Square(0);
      var myHeightMark = new makerjs.models.Square(0);

      makerjs.model.addPath(
        myWidthMark,
        new makerjs.paths.Line(
          [0, -this.markShift + 5],
          [0, -this.markShift - 5]
        )
      ); //长度左竖短标线
      makerjs.model.addPath(
        myWidthMark,
        new makerjs.paths.Line(
          [w, -this.markShift + 5],
          [w, -this.markShift - 5]
        )
      ); //长度右竖短标线
      makerjs.model.addPath(
        myWidthMark,
        new makerjs.paths.Line([0, -this.markShift], [w, -this.markShift])
      ); //长度标线
      makerjs.model.addCaption(
        myWidthMark,
        TxtW,
        [0, -this.markShift - 8],
        [w, -this.markShift - 8]
      ); //长度文字

      makerjs.model.addPath(
        myHeightMark,
        new makerjs.paths.Line(
          [-this.markShift + 5, 0],
          [-this.markShift - 5, 0]
        )
      ); //宽度标线
      makerjs.model.addPath(
        myHeightMark,
        new makerjs.paths.Line(
          [-this.markShift + 5, h],
          [-this.markShift - 5, h]
        )
      ); //宽度标线
      makerjs.model.addPath(
        myHeightMark,
        new makerjs.paths.Line([-this.markShift, 0], [-this.markShift, h])
      ); //宽度标线
      makerjs.model.addCaption(
        myHeightMark,
        TxtH,
        [-this.markShift - 8, 0],
        [-this.markShift - 8, h]
      ); //宽度文字
      this.markers = {
        models: {
          myWidthMark: myWidthMark,
          myHeightMark: myHeightMark,
          myRect: myRect,
        },
      };
      var svg = makerjs.exporter.toSVG(this.markers, this.renderOptions);
      //20240722 this.dxf = makerjs.exporter.toDXF(this.plate);
      console.log("this.cutPlate =typeof",typeof(this.cutPlate))
      this.dxf = makerjs.exporter.toDXF(this.cutPlate);
      document.getElementById("drawCanvas").innerHTML = svg;
      this.creatSvg(this.form.glassW, this.form.glassH, this.markers);
    },
    //重新画图
    creatSvg(w, h, maker) {
      var x = document.getElementById("drawCanvas");
      x.innerHTML = "";
      var svg = makerjs.exporter.toSVG(maker, this.renderOptions);
      document.getElementById("drawCanvas").innerHTML = svg;
      //重写SVG
      var myDrawing = document.getElementById("myDrawing").innerHTML;
      console.log("myDrawing===", myDrawing);
      var html =
        '<svg width="' +
        (w * 1 + 100) +
        '" height="' +
        (h * 1 + 100) +
        '" viewBox="-50 -50 ' +
        (w * 1 + 100) +
        " " +
        (h * 1 + 100) +
        '" xmlns="http://www.w3.org/2000/svg" id="myDrawing">';
      var mySVGHtml = html + myDrawing + "</svg>";
      document.getElementById("drawCanvas").innerHTML = "";
      document.getElementById("drawCanvas").innerHTML = mySVGHtml;
      this.updateState();
    },
    /***孔***/
    //一级调用，在页面中用
    drawLevelOne(type, shape) {
      /**
       *  shape:'SingleHole',
       *  type:'Hole'  'Gap'  'corner'
       *
       *
       *
       *
       */

      //调用判断参数是否为0的函数  不能为0的参数进行判断，参数除了XY和输入参数数量相同
      //var arr=this.isParamZero(type,shape);
      //console.log("arr[0]=",arr[0]);
      //console.log("arr[1]=",arr[1]);

      console.log(
        "this.isParamZero(type, shape)====",
        this.isParamZero(type, shape)
      );
      this.isParamZero(type, shape);
      console.log("this.operationParam=", this.operationParam);
      if (this.history.length === 0) {
        console.log("this.history.length === 0");
        this.initState();
      } else {
        if (this.plate) {
          console.log("this.plate*****测试是否返回内容");
          if (this.isParamZero(type, shape)) {
            this.$message({
              showClose: false,
              message: "贵友提示您：请输入孔的直径D！",
              type: "warning",
              offset: "200",
            });
            return;
          }
          this.operationCurrent = this.deepCopy(this.operationParam);
          if (
            JSON.stringify(this.operationHistory).includes(
              JSON.stringify(this.operationParam)
            ) === false
          ) {
            this.callDrawFun(type, shape);
          } else {
            console.log("已存在");
            this.$message({
              showClose: false,
              message: "贵友提示您：不能重复添加相同图形，请检查参数！",
              type: "warning",
              offset: "200",
            });
          }
        } else {
          this.dialogFormVisible = true;
        }
      }
    },
    //判断参数是否为0
    isParamZero(type, shape) {
      /**
       *  shape:'SingleHole',
       *  type:'Hole'  'Gap'  'corner'
       */

      console.log("isParamZero");
      console.log("type=QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ=", type);
      console.log("shape=", shape);
      var ParamObj = {
        //1、 单孔
        SingleHole: {
          D: this.D,
        },
        //2、吊轮孔2
        HangWheelHoleTwo: {
          D: this.D,
        },
        //3、吊轮孔3
        HangWheelHoleThree: {
          D: this.D,
        },
        //4、双吊轮孔列
        HangWheelHoleTwoCol: {
          D: this.D,
          H: this.H,
        },
        //5、双吊轮孔排
        HangWheelHoleTwoRow: {
          D: this.D,
          W: this.W,
        },
        //6、晾物孔
        HangHole: {
          D: this.D,
          W: this.W,
          H1: this.H1,
          H2: this.H2,
        },
        //7、拉手孔T字
        HandleHoleTShaped: {
          D: this.D,
          W: this.W,
          H1: this.H1,
          H2: this.H2,
        },
        //8、双固定孔
        DoubleFixHoles: {
          D: this.D,
          W: this.W,
        },
        //8、双固定孔列
        DoubleFixHolesCol: {
          D: this.D,
          H: this.H,
        },
        //9、相对吊轮孔
        RelSuWheelHole: {
          D: this.D,
          W: this.W,
        },
        //10、拉手孔
        HandleHole: {
          D: this.D,
          W: this.W,
        },
        //11、旋转锁孔
        RotatLockHole: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //12、拉手孔3
        HandleHole3: {
          D: this.D,
          H: this.H,
        },
        //13、吊轮孔列
        HangWheelHoleCol: {
          D: this.D,
          H: this.H,
        },
        //14、7字拉手孔
        Shaped7HandleHole: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //15、锁孔
        KeyHole: {
          D1: this.D1,
          D2: this.D2,
          W: this.W,
          H: this.H,
        },
        //16、三孔
        ThreeHoles: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //17、三孔2
        ThreeHoles2: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //18、三孔3
        ThreeHoles3: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //19、三孔4
        ThreeHoles4: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        // *****缺*****
        //1、蝴蝶
        ButterflyGap: {
          R: this.R,
          W: this.W,
          H: this.H,
        },
        //1.2、蝴蝶2
        ButterflyGap2: {
          R: this.R,
          W: this.W,
          H: this.H,
          W1: this.W1,
          H1: this.H1,
        },
        //1.3、蝴蝶3
        ButterflyGap3: {
          R1: this.R1,
          R2: this.R2,
          W: this.W,
          H: this.H,
          H1: this.H1,
          H2: this.H2,
        },
        //1.4、蝴蝶4
        ButterflyGap4: {
          R1: this.R1,
          R2: this.R2,
          W: this.W,
          W1: this.W1,
          H: this.H,
        },
        //2、U型
        UShapeGap: {
          D: this.D,
          H: this.H,
        },
        //3、应力孔
        StressHoleGap: {
          D: this.D,
          W: this.W,
          H: this.H,
        },
        //4、跑道缺
        RunwayGap: {
          W: this.W,
          W1: this.W1,
          H: this.H,
          H1: this.H1,
        },
        //5、1方缺
        SquareGap: {
          W: this.W,
          H: this.H,
          R: this.R,
        },
        //5、2矩形缺
        RectAngleGap: {
          W: this.W,
          H: this.H,
          R: this.R,
        },
        //6、铜夹
        CopperClipGap: {
          D: this.D,
          W: this.W,
          H1: this.H1,
          H: this.H,
          R: this.R,
        },
        //7、长条夹
        LongClipGap: {
          D: this.D,
          D1: this.D1,
          H: this.H,
          H1: this.H1,
        },
        //8、椭圆
        EllipseGap: {
          Rx: this.Rx,
          Ry: this.Ry,
        },

        //********角*******
        //1、斜角 corner
        ObliqueAngle: {
          W: this.W,
          H: this.H,
        },
        //2、圆角
        Fillet: {
          R: this.R,
        },
        //3、方角
        Corner: {
          R: this.R,
          W: this.W,
          H: this.H,
        },
        //4、圆角2
        Fillet2: {
          R: this.R,
          W: this.W,
          H: this.H,
        },
        //5、地弹簧
        FloorSpring: {
          D: this.D,
          H: this.H,
          H1: this.H1,
          R1: this.R1,
          R2: this.R2,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
        //6、地弹簧2
        FloorSpring2: {
          D: this.D,
          H: this.H,
          H1: this.H1,
          R: this.R,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
        //7、地弹簧3
        FloorSpring3: {
          D: this.D,
          H: this.H,
          H1: this.H1,
          R: this.R,
          W1: this.W1,
          W2: this.W2,
        },
        //********异形*******
        //1、梯形  LadderShaped
        //1.1 等腰梯形  IsoscelesTrapezoid
        IsoscelesTrapezoid: {
          H: this.H,
          W: this.W,
          W1: this.W1,
        },
        //1.2 等腰梯形  RightAngledTrapezoid
        RightAngledTrapezoid: {
          H: this.H,
          H1: this.H1,
          W: this.W,
          W1: this.W1,
        },
        //1.3 其它梯形A  OtherTrapezoidsA
        OtherTrapezoidsA: {
          H1: this.H1,
          H2: this.H2,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
        //1.4 其它梯形B  OtherTrapezoidsB
        OtherTrapezoidsB: {
          H: this.H,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
        //2、平形四边形  Parallelogram
        //2.1 平形四边形A
        ParallelogramA: {
          H: this.H,
          W: this.W,
          W1: this.W1,
        },
        //2.1 平形四边形B
        ParallelogramB: {
          H: this.H,
          W: this.W,
          W1: this.W1,
          W2: this.W2,
        },
      };
      var SameObj = {
        X: this.X,
        Y: this.Y,
        area: this.radio,
        shape: shape,
        type: type,
      };
      //this.objIsEmpty();
      console.log("ParamObj[shape]=", ParamObj[shape]);
      var obj = ParamObj[shape];
      //var arr=[];
      this.operationParam = { ...ParamObj[shape], ...SameObj };
      //arr[1]=this.objIsZero(obj);
      //console.log("this.objIsZero=",this.objIsZero(obj));
      console.log("obj=", obj);
      if(shape=="Corner"){
        delete obj.R
        this.objIsZero(obj);
      }else {
        return this.objIsZero(obj);
      }
      
      /*
   if(this.history.length===0){
  this.initState();
    }else{
      if(this.plate){
        if(this.D==0) {
        this.$message({
          showClose: false,
          message: '贵友提示您：请输入孔的直径D！',
          type: 'warning',
          offset:'200'
        });
        return
      }
      */
    },
    //根据实际情况调用基本图形函数
    callDrawFun(type, shape) {      
    this.updateState();
      console.log("this.operationHistory==",this.operationHistory);
      var obj={}
  console.log(
          "this.operationHistory 0 ############setTimeout#######@@@@@@@@@@@@=",
          this.operationHistory[0]
        );
        console.log(
          "this.operationHistory  1############setTimeout#######@@@@@@@@@@@@=",
          this.operationHistory[1]
        );
        console.log(
          "this.operationHistory.length  1############setTimeout#######@@@@@@@@@@@@=",
          this.operationHistory.length
        );
       
        for(var i=1;i<this.operationHistory.length;i++){ 
          console.log("i====",i);  
          console.log("this.operationHistory[i]====",this.operationHistory[i]); 
          var shape= this.operationHistory[i].shape   
          var area= this.changeArea(this.operationHistory[i].area) 
         obj[shape+area]=this.operationHistory[i];
        }
        console.log(
          "this.operationHistory  all  ############setTimeout#######@@@@@@@@@@@@=",
          obj
        );
     
      var model = {
        plate: this.plate,
        markers: this.markers,
        cutPlate: this.cutPlate,
      };
      var fixedParam = {
        markShift: this.markShift,
        markShiftTwo: this.markShiftTwo,
        markShiftThree: this.markShiftThree,
        markShiftFour: this.markShiftFour,
        markHolesOneD: this.markHolesOneD,
      };
      console.log(
        "callDrawFun() myobj this.operationParam=",
        this.operationParam
      );      
//this.glassFrameParam.shapeName == "Rectangle"
      if (this.glassFrameParam.shapeName == "Rectangle") {
        console.log("type&&&&&&&&&&&&&&***********############",type);
        if(type=="Corner"){
          var myobj = this.$myutils[shape](
            obj,
          model,
          this.myHolesNum,
          this.form,
          fixedParam,
          this.zoomM,
          this.θCut,
          this.rCut
        );
        }else{
          console.log("this.glassFrameParam.shapeName&&&&&&&&&&&&&&***********############",this.glassFrameParam.shapeName);
          var myobj = this.$myutils[shape](
          this.operationParam,
          model,
          this.myHolesNum,
          this.form,
          fixedParam,
          this.zoomM,
          this.θCut,
          this.rCut
        );
        }
       
      } else {
        console.log("this.glassFrameParam.shapeName=",this.glassFrameParam.shapeName);
        if(shape=="ObliqueAngle"){
          var myobj = this.$myutils[shape](
          //this.operationParam,
          obj,
          model,
          this.myHolesNum,
          this.glassFrameParam,
          fixedParam,
          this.zoomM,
          this.θCut,
          this.rCut
        );
        }else{
          this.myOpenMsg("此外框下不能进行此图形操作，请修改为矩形框，如有其它疑问请联系贵友客服。","贵友提示您：");
        }        
      }
      this.creatDraw(
        myobj.markers,
        myobj.plate,
        myobj.cutPlate,
        this.form.glassW,
        this.form.glassH
      );
      this.plate = this.deepCopy(myobj.plate);
      this.markers = this.deepCopy(myobj.markers);
      this.cutPlate = this.deepCopy(myobj.cutPlate);
      this.myHolesNum = this.deepCopy(myobj.myHolesNum);
    },
    //三孔类型单选事件
    threeHolesChange(e) {
      console.log("e=", e);
      switch (e) {
        case "A":
          //三孔不同类型选择单选
          this.threeHoleType = "A";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles')";
          break;
        case "B":
          //三孔不同类型选择单选
          this.threeHoleType = "B";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles2.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles2')";
          break;
        case "C":
          //三孔不同类型选择单选
          this.threeHoleType = "C";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles3.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles3')";
          break;
        case "D":
          //三孔不同类型选择单选
          this.threeHoleType = "D";
          //三孔示意图参数
          this.threeHolePic = require("../assets/image/ThreeHoles4.jpg");
          //三孔调用函数名称变化参数
          this.threeHoleFn = "drawLevelOne('Hole','ThreeHoles4')";
          break;
        default:
        // code block
      }
    },
    threeHoleFnClick() {
      if (this.threeHoleType == "A") {
        this.drawLevelOne("Hole", "ThreeHoles");
      }
      if (this.threeHoleType == "B") {
        this.drawLevelOne("Hole", "ThreeHoles2");
      }
      if (this.threeHoleType == "C") {
        this.drawLevelOne("Hole", "ThreeHoles3");
      }
      if (this.threeHoleType == "D") {
        this.drawLevelOne("Hole", "ThreeHoles4");
      }
    },
    //***************异形*************/
    handleClickSpecialShaped() {},
    saveMySvg() {
      var fileNames = this.creatDate();
      var x = document.getElementById("drawCanvas").innerHTML;
      // 创建一个 Blob 对象并设置 MIME 类型为 image/svg+xml
      var blob = new Blob([x]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);
      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames + ".svg"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);
    },
    saveMyDXF() {
      //var square = new makerjs.models.Square(100);
      //var svg = makerjs.exporter.toSVG(square);
      //this.dxf = makerjs.exporter.toDXF(square);
      // 创建一个 Blob 对象并设置 MIME 类型为 image/svg+xml

      var fileNames = this.creatDate();      
      console.log("this.cutPlate =typeof",typeof(this.cutPlate));    
      console.log("saveMyDXFthis.cutPlate =typeof",typeof(this.cutPlate));  
      console.log("saveMyDXFthis.dxf =typeof",typeof(this.dxf));
      var blob = new Blob([this.dxf]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);

      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames + ".dxf"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);
    },
    exMould() {
      console.log(
        "this.operationHistory.length=",
        this.operationHistory.length
      );
      if (this.operationHistory.length <= 1) {
        this.myOpenMsg("没有图形，请先画图。", "提示：");
      } else {
        //console.log("this.plate=", this.plate);
        //console.log("this.plate.models=", this.plate.models);
        this.MyJSON.plate = this.plate;
        this.MyJSON.cutPlate = this.cutPlate;
        this.MyJSON.markers = this.markers;
        this.MyJSON.Rect = this.form;
        this.MyJSON.operationHistory = this.operationHistory;
        this.MyJSON = JSON.stringify(this.MyJSON);
        console.log("this.MyJSON=", this.MyJSON);
        this.saveMyJSON();
        this.saveJsonPng();
      }
    },
    saveMyJSON() {
      //var square = new makerjs.models.Square(100);
      //var svg = makerjs.exporter.toSVG(square);
      //this.dxf = makerjs.exporter.toDXF(square);
      // 创建一个 Blob 对象并设置 MIME 类型为 image/svg+xml
      var fileNames = this.creatDate();
      var blob = new Blob([this.MyJSON]);
      console.log("blob====", blob);
      // 创建一个 URL 对象来引用 Blob 数据
      var url = window.URL || window.webkitURL;
      var downloadUrl = url.createObjectURL(blob);

      // 创建一个链接元素并设置其属性
      var link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileNames + ".json"; // 指定下载时的文件名
      // 模拟点击链接进行下载
      link.click();
      // 清除 URL 对象
      url.revokeObjectURL(downloadUrl);
    },
    saveJsonPng() {
      var fileNames = this.creatDate();
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      img.then(function (base64src) {
        //console.log('路径:::', base64src)
        // console.log("this1=",_this)
        _this.downLoad(base64src, fileNames + ".png");
        //console.log("this2=",_this)
      });
    },
    _saveMyPng() {
      /*
      var svg=document.getElementById("myDrawing");
      console.log("svg=",svg.setAttribute.viewBox)
      const _this=this;
      var img = _this.export2Base64Img(svg, null, {
                //watermark: 'copyright reserved 2020 版权所有',
                background: '#fff'
            });
            img.then(function (base64src) {
             console.log('路径:::', base64src)
                console.log("this1=",_this)
                _this.downLoad(base64src, 'c:/guiyou/00.png')
                console.log("this2=",_this)
            })
            */

      //var fileNames=creatDate();
      var svg = document.getElementById("myDrawing");
      console.log("svg=", svg.setAttribute.viewBox);
      const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        //watermark: 'copyright reserved 2020 版权所有',
        background: "#fff",
      });
      var data = img;
      var blob = new Blob([data], { type: "application/octet-stream" });
      var url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = url;
      link.download = "filename.png ";
      // 创建一个<input type="file”>元萧，并置其vebkitdirectory属性为下载路径
      var fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.style.display = "none";
      fileInput.webkitdirectory = "E:/vueObject/Draw/vue2draw/pic";
      document.body.appendChild(fileInput);
      link.click();
      document.body.removeChild(fileInput);
      document.body.removeChild(link);
      window.URL.revokeobjectURL(ur1);
    },
    saveMyPng() {
      var fileNames = this.creatDate();
      var svg = document.getElementById("myDrawing");      
      console.log("svg=", svg);     
      if(svg==null){
        this.myOpenMsg("图形为空，请先画图。","贵友提示您：")
      }else{
        console.log("svg=", svg.setAttribute.viewBox);
        const _this = this;
      var img = _this.export2Base64Img(svg, null, {
        background: "#fff",
      });
      img.then(function (base64src) {
        console.log("路径:::", base64src);
        console.log("this1=", _this);
        _this.downLoad(base64src, fileNames + ".png");
        console.log("this2=", _this);
      });
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0]; // 获取第一个选定的文件对象
      if (file) {
        this.readFileContent(file); // 调用自定义的读取文件内容的方法
      } else {
        console.log("未选择任何文件");
      }
    },
    readFileContent(file) {
      const reader = new FileReader();

      reader.onload = () => {
        const content = JSON.parse(reader.result); // 读取到的文件内容

        // 进行后续操作，比如打印或者保存文件内容等
        console.log("测试读取的内容" + content);
        //获取节点 插入读取的内容
        //innerHTML

        this.form.glassW = content.Rect.glassW;
        this.form.glassH = content.Rect.glassH;
        this.plate = content.plate;
        this.cutPlate = content.cutPlate;
        this.markers = content.markers;
        this.operationHistory = content.operationHistory;
        this.creatSvg(
          content.Rect.glassW,
          content.Rect.glassH,
          content.markers
        );
      };

      reader.readAsText(file); // 以文本形式读取文件内容
    },
    //导出png图片格式相关
    reEncode(data) {
      return decodeURIComponent(
        encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, p1) => {
          const c = String.fromCharCode(`0x${p1}`);
          return c === "%" ? "%25" : c;
        })
      );
    },
    //模板导出图片
    export2Base64ImgUserMd(path, rect, MIMEType, option) {
      // var serializer = new XMLSerializer();
      // console.log("**3=", typeof svgDom);
      // var source = serializer.serializeToString(svgDom);
      // // 方式一: unescape(encodeURIComponent(txt))
      // // var path = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(source)));
      // // 方式二: decodeURIComponent(encodeURIComponent(txt))
      // var path =
      //   "data:image/svg+xml;base64," + window.btoa(this.reEncode(source));
      var canvas = document.createElement("canvas"),
        context = canvas.getContext("2d"),
        img = new Image(),
        pixelRatio = window.devicePixelRatio || 1,
        _exportPath,
        handler;
      option = option || {};
      // activeBigSize:{
      //   userHeight:"",
      //   userWide:"",
      // },
      canvas.width = parseFloat(rect.X * 1 + 100); //  * pixelRatio
      canvas.height = parseFloat(rect.Y * 1 + 100); //  * pixelRatio
      img.src = path;
      img.onload = function () {
        // 增加底色
        if (option.background) {
          context.beginPath();
          context.rect(0, 0, canvas.width, canvas.height);
          context.fillStyle = option.background;
          context.fill();
          context.closePath();
        }
        //
        context.drawImage(img, 0, 0);
        var marker = option.watermark || "";
        if (marker) {
          context.font = "18px 微软雅黑";
          context.fillStyle = "rgba(12, 0, 70, 0.5)";
          var textWidth = context.measureText(marker).width,
            textHegith = 50,
            pk = 1.2,
            rotate = ((option.rotation || -45) * Math.PI) / 180,
            sinReg = Math.sin(rotate),
            cosReg = Math.cos(rotate),
            width =
              Math.abs(canvas.width * cosReg) +
              Math.abs(canvas.height * sinReg),
            height =
              Math.abs(canvas.height * cosReg) +
              Math.abs(canvas.width * sinReg);
          var xf = Math.ceil((width / textWidth) * pk);
          var yf = Math.ceil(height / textHegith);
          context.rotate(rotate);
          for (var i = 0; i < yf; i++) {
            for (var k = 0; k < xf; k++) {
              context.fillText(
                marker,
                textWidth * k * pk - canvas.height * cosReg,
                textHegith * i
              );
            }
          }
        }

        document.body.appendChild(canvas);
        _exportPath = canvas.toDataURL(MIMEType || "image/png", 1);
        typeof handler === "function" && handler(_exportPath);
        document.body.removeChild(canvas);
      };

      return new Promise(function (resolve, reject) {
        handler = resolve;
      });
    },
    export2Base64Img(svgDom, MIMEType, option) {
      console.log("**");
      var serializer = new XMLSerializer();
      console.log("**3=", typeof svgDom);
      var source = serializer.serializeToString(svgDom);
      // 方式一: unescape(encodeURIComponent(txt))
      // var path = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(source)));
      // 方式二: decodeURIComponent(encodeURIComponent(txt))
      var path =
        "data:image/svg+xml;base64," + window.btoa(this.reEncode(source));
      var canvas = document.createElement("canvas"),
        context = canvas.getContext("2d"),
        img = new Image(),
        pixelRatio = window.devicePixelRatio || 1,
        _exportPath,
        handler;
      option = option || {};
      canvas.width = parseFloat(svgDom.getAttribute("width")); //  * pixelRatio
      canvas.height = parseFloat(svgDom.getAttribute("height")); //  * pixelRatio
      img.src = path;
      img.onload = function () {
        // 增加底色
        if (option.background) {
          context.beginPath();
          context.rect(0, 0, canvas.width, canvas.height);
          context.fillStyle = option.background;
          context.fill();
          context.closePath();
        }
        //
        context.drawImage(img, 0, 0);
        var marker = option.watermark || "";
        if (marker) {
          context.font = "18px 微软雅黑";
          context.fillStyle = "rgba(12, 0, 70, 0.5)";
          var textWidth = context.measureText(marker).width,
            textHegith = 50,
            pk = 1.2,
            rotate = ((option.rotation || -45) * Math.PI) / 180,
            sinReg = Math.sin(rotate),
            cosReg = Math.cos(rotate),
            width =
              Math.abs(canvas.width * cosReg) +
              Math.abs(canvas.height * sinReg),
            height =
              Math.abs(canvas.height * cosReg) +
              Math.abs(canvas.width * sinReg);
          var xf = Math.ceil((width / textWidth) * pk);
          var yf = Math.ceil(height / textHegith);
          context.rotate(rotate);
          for (var i = 0; i < yf; i++) {
            for (var k = 0; k < xf; k++) {
              context.fillText(
                marker,
                textWidth * k * pk - canvas.height * cosReg,
                textHegith * i
              );
            }
          }
        }

        document.body.appendChild(canvas);
        _exportPath = canvas.toDataURL(MIMEType || "image/png", 1);
        typeof handler === "function" && handler(_exportPath);
        document.body.removeChild(canvas);
      };

      return new Promise(function (resolve, reject) {
        handler = resolve;
      });
    },
    downLoad(url, fileName) {
      var oA = document.createElement("a");
      oA.download = fileName || "";
      oA.style.display = "none";
      oA.href = url;
      document.body.appendChild(oA);
      oA.click();
      oA.remove();
    },
    //导出png图片格式相关
    //获取当前时间 用来生成默认文件名称
    creatDate() {
      var currentDate = new Date();
      var year = currentDate.getFullYear(); // 获取年份
      var month = currentDate.getMonth() + 1; // 获取月份，月份从0开始，需加1
      var day = currentDate.getDate(); // 获取日期
      var hours = currentDate.getHours(); // 获取小时
      var minutes = currentDate.getMinutes(); // 获取分钟
      var seconds = currentDate.getSeconds(); // 获取秒数
      var currentDateTime =
        year + "" + month + "" + day + "" + hours + "" + minutes + "" + seconds;
      //var currentDateTime = year + month + day + hours + minutes + seconds;
      console.log("currentDateTime=", currentDateTime);
      return currentDateTime;
    },
    //创建本地文件夹
    creatFile() {
      var fso = new ActiveXObject("Scripting.FileSystemObject");
      var f1 = fso.createtextfile("D:\\myjstest.txt", true);
    },
  },
};
</script>
<style lang="less">
.bg-purple-dark {
    background: #fff;
  }
  .bgParma{
    background-color: #fff;
  }
  .parmaRect{    
    margin-top: 25px;    
    margin-bottom: 100px;
  }
  .imgRect{
    margin-top: 25px;
    height:600px;
  }
.el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
    min-height:1000px;
    width:100px;
  }
  .imgWidth{
    width: 50px;
  }
.asideCard{}
.asideCard:hover{  
  background-color: #e9f5fd;  
  cursor: pointer;
}
.btnTop{
  margin-top:5px;
}
.mdashape-img-container{
  margin: 10px auto auto 50px;  
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.mdashape-img-item{
  display:flex; 
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align:center;
  width: 100px;
  border-radius: 4px;
}
.span-title{
  height:30px;
  line-height:30px;
  text-align:left;
}
.mdashape-img-bottom{
  margin-bottom: 0px;
}

.mdashape-img-item:nth-of-type(15n) {
  margin-right: 0px;
}
  
.el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;    
    min-height:100%;
  }
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height:100%;
  }
.showimgstyle{
  width: 100px; 
  height: 100px;
}
.mask {
  
  width: 100px; 
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明遮罩 */
  color:#fff;
  opacity:0;
  display:flex;
  justify-content: center;
}
.inputPadding{
  padding-left:1px;
}
.foot_lf{
  margin-top:8px;
  font-size:11px;
  a:link {
  color: #303133;  /* 文字颜色 */
  text-decoration: none; /* 无下划线 */
}
}
.btnUserMdReSet{
  margin-top:15px;
}
.imgUserMdMenu {
  color: #303133;
}
.imgUserMdDrop {
  padding-top: 8px;
}
.exportMenu {
  margin-top: 25px;
}
.borderbulue {
  border: 1px solid #409eff;
}
.myCursor {
  cursor: pointer;
}
.holes-img-container {
  margin: 10px auto auto 50px;
  max-height: 420px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.holes-img-item {
  width: 100px;
  height: 160px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}
.holesActiveImg {
  border: 1px solid #409eff;
  background-color: #e9f5fd;
}
.showimgstyle:hover {
  border: 1px solid #409eff;
  background-color: #e9f5fd;  
  cursor: pointer;
}
.holes-img-bottom {
  margin-bottom: 15px;
}
.holes-img-item:nth-of-type(15n) {
  margin-right: 0px;
}
.holes-img-parmSet {
  margin-top: 25px;
  color: #909399;
}
.holes-img-parmSet-imgs {
  width: 85%;
  height: 360px;
  text-align: center;  
}
.setParamH{
  height:95vh;
}
.showimgsmd{
  width:auto;
  height:auto;
}
.showImgBig{
  text-align: left;
  margin-left:10px;
  padding:8px;
  background-color:#f3f5f9;  
  border: 1px solid #EBEEF5;
}
.div-cursor {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}
.logo-img-contener {
  text-align: center;
}
.logo-img-item {
  display: flex;
  flex-direction: column;
}
.el-dropdown-link {
  cursor: pointer;
}
.display-clo {
  display: flex;
  flex-direction: column;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.txtHeight {
  line-height: 45px;
  text-align: center;
}
.iconfont {
  font-size: 42px;
}
.contanerBox {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0 auto;
  padding: 8px;
  .conHeader {
    padding-top: 8px;
    padding-left: 20px;
    background-color: #b3c0d1;
    color: #333;
    height: 8vh;
    line-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MenuBtns {
      margin-left: 0px;
      align-self: center;
      justify-items: center;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      flex: 7;
      .openMenu {
        .open-svg {
          display: inline-block;
          cursor: pointer;
          padding: 10px;
        }
        #openSVG {
          display: none;
        }
      }
    }
    .MenuBtns-right {
      justify-content: flex-end;
      display: flex;
      text-align: right;
      flex: 9;
      padding-right: 15px;
      .div-right {
        padding: 8px;
      }
    }
  }
  .conMain {
    display: flex;
    background-color: #e9eef3;
    .conMainCon {
      position: relative;
      #view {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        #drawCanvas {
          margin: 2em;
        }
      }
      flex: 13;
      background-color: #e9eef3;
      color: #333;
      .drawBack {
        background-color: #fff;
        margin: 20px 20px;
        .draw {
          box-sizing: border-box;
          svg {
            overflow: visible;
          }
        }
      }
    }
    .conMainRight {
      margin-top: 15px;
      flex: 3;
      background-color: #e9eef3;
      .hole-position {
        border: 1px solid #909399;
        padding-top: 5px;
        padding-bottom: 5px;
        .hole-lineH {
          height: 8px;
          line-height: 80px;
        }
      }
    }
  }
  .r_content {
    .el-dropdown-link {
      cursor: pointer;
      color: #0e1012;
      .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
/**上传头像图片 */
.disabled .el-upload--picture-card {
  display: none;
}
/**上传头像图片 */
</style>